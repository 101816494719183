import React from "react";

import formatDistanceStrict from "date-fns/formatDistanceStrict";
import fromUnixTime from "date-fns/fromUnixTime";

import { Card } from "src/component-lib/src/components/card";
import { Icon } from "src/component-lib/src/components/icon";
import { Text } from "src/component-lib/src/components/text";
import {
  NotificationDTO,
  NotificationTypeEnum
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

interface NotificationCardProps {
  isNew?: boolean;
  handleClick?: () => void;
  notification: NotificationDTO;
}

/**
 * NotificationCard
 */
const NotificationCard: React.FC<NotificationCardProps> = ({ isNew, notification, handleClick }) => (
  <CardWrapper>
    <Card
      dropShadow={true}
      borderType={isNew ? "new" : undefined}
      onPress={handleClick}
    >
      <NotificationContentWrapper>
        <Text size="large">
          {notification.data?.text}

          {notification.data?.matchID && (
            <>
              <Text>&nbsp;</Text>
              <TextExcerpt size="large" weight="bold">
                View details
              </TextExcerpt>
              <Text>.</Text>
            </>
          )}
        </Text>

        <FooterWrapper>
          {notification.data?.type === NotificationTypeEnum.MATCH && (
            <Icon icon="person" size={22} color="green" />
          )}

          {notification.data?.type === NotificationTypeEnum.INFO && (
            <Icon icon="map-marker" size={22} color="red" />
          )}

          {notification.date && (
            <FooterLeftDurationText>
              {formatDistanceStrict(
                new Date(),
                fromUnixTime(Math.floor(notification.date / 1000))
              )}
            </FooterLeftDurationText>
          )}
        </FooterWrapper>
      </NotificationContentWrapper>
    </Card>
  </CardWrapper>
);

export default NotificationCard;

const CardWrapper = styled.View`
  margin-top: 10px;
`;

const NotificationContentWrapper = styled.View`
  flex: 1;
  width: 100%;
`;

const TextExcerpt = styled(Text)`
  margin-left: 40px;
  text-decoration: underline;
`;

const FooterWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`;

const FooterLeftDurationText = styled(Text)`
  padding-left: 6px;
`;
