import {
  DeliveryDTO,
  DeliveryRequest,
  PickParams,
  QuoteRequest,
  QuoteResponse
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const DeliveryRoutes = {
  /* Get existing delivery */
  "DELIVERY:getDelivery": {
    path: "/deliveries/delivery/:deliveryUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<DeliveryDTO, null, PickParams<"deliveryUuid">>,

  /* Request delivery after having obtained a quote and made a payment */
  "DELIVERY:confirmDelivery": {
    path: "/deliveries/delivery",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<DeliveryDTO, DeliveryRequest>,

  /* Request a quote for delivering an item */
  "DELIVERY:requestQuote": {
    path: "/deliveries/quote",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<QuoteResponse, QuoteRequest>
};

export default DeliveryRoutes;
