import React from "react";

import { WebView } from "react-native-webview";

import { IconButton } from "src/component-lib/src/components/button";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { BackgroundView } from "src/component-lib/src/components/view";

import {
  AuthorizedScreenProps
} from "src/navigation/authorized/AuthorizedNavigator";

const WebViewModal: React.FC<AuthorizedScreenProps<"WebViewModal">> = props => (
  <FullScreenLayout
    HeaderRight={() => (
      <IconButton
        color="white"
        icon="ios-close"
        onPress={() => props.navigation.goBack()}
        size={42}
      />
    )}
    BackgroundComponent={p => <BackgroundView {...p} />}
    noPadding
  >
    <WebView source={{ uri: props.route.params.uri }} />
  </FullScreenLayout>
);

export default WebViewModal;
