import React from "react";

import { useColorScheme } from "react-native-appearance";

import { ThemeProvider } from "../../utils/styled-components";
import { DynamicTheme } from "../../utils/theme";

interface ThemeProps {
  theme: DynamicTheme;
}

export const DynamicThemeProvider: React.FC<ThemeProps> = ({
  theme,
  children
}) => {
  const colorScheme = useColorScheme() ?? "light";

  const selectedColorScheme = colorScheme === "no-preference" ? "light" : colorScheme;

  return (
    <ThemeProvider theme={theme[ selectedColorScheme ]}>
      {children}
    </ThemeProvider>
  );
};
