import React from "react";

import Constants from "expo-constants";
import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import {
  Platform,
  View
} from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  Switch,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  RegularUserRequestAppTypeEnum,
  RegularUserRequestDeviceTypeEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

import {
  userDetailsFormConfig,
  UserDetailsFormData
} from "./userDetailsForm";

type UserDetailsScreenProps = UnauthorizedScreenProps<"UserDetails">;

const UserDetailsScreen: React.FC<UserDetailsScreenProps> = ({ navigation }) => {
  const { handleSubmit, getValues, setError, control, ...formControls } = useForm<UserDetailsFormData>();

  const snackbar = useSnackbarContext();

  const [ checkForInviteResponse, checkForInviteRequest ] = useApiRequest("USERS:checkEmailInBusiness");

  const [ signupResponse, signupRequest ] = useApiRequest("USERS:signupRegular");

  /**
   * Check user invite status on submit
   */
  const submitForm: SubmitHandler<UserDetailsFormData> = React.useCallback(data => {
    if (data.password !== data.confirmPassword) {
      setError("password", { type: "notMatch", message: "Passwords do not match!"});
      setError("confirmPassword", { type: "notMatch", message: "Passwords do not match!"});
    } else {
      // check to see whether user has been invited to join a business
      checkForInviteRequest({
        params: {
          email: data.email
        },
        // workaround for "Content type 'null' not supported" error
        /// @ts-ignore: axios ignores content type header unless data !== undefined
        data: null,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
  }, [ setError, checkForInviteRequest ]);

  /**
   * Handle user invite status response
   */
  React.useEffect(() => {
    if (checkForInviteResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: checkForInviteResponse.errorMessage,
        duration: 4000
      });
    } else if (checkForInviteResponse.data) {
      const { confirmPassword, ...userDetails } = getValues();

      // complete sign up process if invited: business details already present
      if (checkForInviteResponse.data.invited) {
        signupRequest({
          data: {
            ...userDetails,
            organization: true,
            appType: RegularUserRequestAppTypeEnum.BUSINESS,
            deviceToken: Constants.deviceId || "",
            deviceType:
              Platform.OS === "ios"
                ? RegularUserRequestDeviceTypeEnum.IOS
                : Platform.OS === "android"
                  ? RegularUserRequestDeviceTypeEnum.ANDROID
                  : RegularUserRequestDeviceTypeEnum.DEFAULT
          }
        });
      // if not invited, prompt to set up a business
      } else {
        navigation.navigate("BusinessDetails", { userDetails });
      }
    }
  }, [ checkForInviteResponse, navigation, snackbar, getValues, signupRequest ]);

  /**
   * Handle user sign up response
   */
  React.useEffect(() => {
    if (signupResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: signupResponse.errorMessage,
        duration: 4000
      });
    // On success, show email verification prompt
    } else if (signupResponse.data) {
      navigation.navigate("VerificationEmailSent");
    }
  }, [ navigation, signupResponse, snackbar ]);

  const scrollViewRef = React.useRef<any>(null);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      title="Create account"
      height={240}
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          color="black"
          icon="chevron-left"
          onPress={() => navigation.goBack()}
          size={35}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Create account"
          description="We will send you a verification email, just to check it's actually you"
        />
      )}
    >
      <Form
        config={userDetailsFormConfig}
        validateOnBlur
        keyboardOffset={320}
        scrollViewRef={scrollViewRef}
        {...formControls}
      >
        <TextInput
          name="name"
          label="Name"
          textContentType="name"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="email"
          label="Email"
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="password"
          label="Password"
          textContentType="password"
          secureTextEntry
          returnKeyType="next"
        />
        <TextInput
          name="confirmPassword"
          label="Confirm Password"
          textContentType="password"
          secureTextEntry
        />
        <Controller
          // react-hook-form props
          render={({ field: { onChange, value: fieldValue } }) => (<Switch
            label="Sign up to our newsletter!"
            onValueChange={onChange}
            value={fieldValue}
            type="success"
          />)}
          control={control}
          name="acceptMarketing"
          defaultValue={false}
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Save Details"
          style={{ marginTop: 12, marginBottom: 24 }}
        />
      </Form>
    </CollapsibleHeaderScrollLayout>
  );
};

export default UserDetailsScreen;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View style={{ marginTop: 130, paddingHorizontal: 26 }}>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);
