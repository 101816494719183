import React from "react";

import * as SplashScreen from "expo-splash-screen";

import {
  useAuthenticationContext,
  useCheckUserIsAuthenticated
} from "src/component-lib/src/hoc/authentication";
import styled, { css } from "src/component-lib/src/utils/styled-components";

import useAssets from "src/hooks/useAssets";

import { SplashStackParamList } from "src/navigation/shared/SplashNavigator";
import { StackNavigationScreen } from "src/types/navigation";

import SplashImg from "assets/images/splash.png";

type FakeSplashStackType = StackNavigationScreen<
  SplashStackParamList,
  "Splash"
>;

/**
 * Fake Splash screen
 */
const FakeSplash: React.FC<FakeSplashStackType> = ({ navigation }) => {
  const assetsReady = useAssets();
  const { authTokenState } = useAuthenticationContext();
  const userIsAuthenticated = useCheckUserIsAuthenticated();
  const [ isLoggedIn, setIsLoggedIn ] = React.useState(false);
  const [ hasLoaded, setHasLoaded ] = React.useState(false);

  const transitionToApp = React.useCallback(async () => {
    // hide static splash
    SplashScreen.hideAsync();
    await userIsAuthenticated();
    setHasLoaded(true);
  }, [ userIsAuthenticated ]);

  React.useEffect(() => {
    if (hasLoaded) {
      if (authTokenState) {
        if (isLoggedIn === false) {
          navigation.navigate("MainStack");
          setIsLoggedIn(true);
        }
      } else {
        navigation.navigate("UnauthorizedStack");
        if (isLoggedIn === true) {
          setIsLoggedIn(false);
        }
      }
    }
  }, [ authTokenState, isLoggedIn, navigation, hasLoaded ]);

  // wait til assets are preloaded before trying to render anything
  return assetsReady ? (
    <SplashWrapper>
      <ImageStyled
        source={SplashImg}
        resizeMode="contain"
        // Android: Wait until image is fully rendered before triggering transition
        onLoadEnd={transitionToApp}
        fadeDuration={0}
      />
    </SplashWrapper>
  ) : null;
};

export default FakeSplash;

const SplashWrapper = styled.View`
  ${({ theme }) => css`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.purple};
  `}
`;

const ImageStyled = styled.Image`
  flex: 1;
  width: 100%;
  height: 100%;
`;
