import React from "react";
import Axios from "axios";
import { Platform } from "react-native";
import { AuthenticationResponse, useAuthenticationContext } from "../../hoc/authentication";
import Constants from "expo-constants";

const isString = (obj: any): obj is string => {
  return typeof obj === "string";
}

const useItemImages = (authTokenState: AuthenticationResponse | null, imageIds: (string | undefined)[]) => {
  const [ imageSrcs, setImageSrcs ] = React.useState<string[]>([]);
  const baseUrl = Constants?.manifest?.extra?.BASE_URL;

  React.useEffect(() => {

    const fetchImage = async (imageIds: string[]) => {
      const blobs = await Promise.all(imageIds.map(id => id ? Axios.get(`${baseUrl}/images/${id}`, {
        responseType: "blob",
        headers: { Authorization: authTokenState?.token.value },
      }).then(r => r.status === 200 ? URL.createObjectURL(r.data) : "") : ""));

      setImageSrcs(blobs);
    }

    if (imageIds && authTokenState) {
      fetchImage(imageIds.filter(isString));
    }

  }, [imageIds.join(" "), authTokenState]);

  return imageSrcs;
}

export default useItemImages;