import React from "react";

import {
  SafeAreaView,
  View
} from "react-native";

import { Image } from "src/component-lib/src/components/image";
import { ProgressCircle } from "src/component-lib/src/components/loading";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import styled from "src/component-lib/src/utils/styled-components";

import SirFoundAlotCamera from "assets/images/sf-camera.png";

interface ImageProcessingViewProps {
  title?: string;
  step: number;
  progress: number;
}

/**
 * Image processing full screen view
 */
const ImageProcessingView: React.FC<ImageProcessingViewProps> = ({
  title = "Analysing image...",
  step,
  progress
}) => (
  <ImageProcessingStyled gradient="business" gradientDirection="vertical">
    <SafeAreaViewStyled>
      <Text type="h1" style={{ color: "white" }}>
        {title}
      </Text>

      <SirFoundAlotImage
        style={{ aspectRatio: 261 / 366 }}
        source={SirFoundAlotCamera}
        defaultSource={SirFoundAlotCamera}
      />
      <View>
        <ProgressCircle
          widthHeight={140}
          stokeWidth={6}
          strokeColor="white"
          progress={progress}
          animationDurationMs={step}
          textPosition="center"
        />

        <Text type="h2" style={{ color: "white", marginTop: 24 }}>
          Analysing your item...
        </Text>
      </View>
    </SafeAreaViewStyled>
  </ImageProcessingStyled>
);

export default ImageProcessingView;

const ImageProcessingStyled = styled(BackgroundView)`
  flex: 1;
  align-items: center;
`;

const SafeAreaViewStyled = styled(SafeAreaView)`
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
`;

const SirFoundAlotImage = styled(Image)`
  width: 75%;
`;
