import React from "react";

import Constants from "expo-constants";
import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import {
  Platform,
  View
} from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  LocationDTO,
  RegularUserRequestAppTypeEnum,
  RegularUserRequestDeviceTypeEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import PostcodeSearch from "src/components/PostcodeSearch";

import { toTitleCase } from "src/utils/strings/title-case";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

import {
  businessAddressFormConfig,
  BusinessAddressFormData
} from "./businessAddressForm";

type BusinessAddressScreenProps = UnauthorizedScreenProps<"BusinessAddress">;

const BusinessAddressScreen: React.FC<BusinessAddressScreenProps> = ({ navigation, route }) => {
  const { userDetails, businessDetails } = route.params;

  const snackbar = useSnackbarContext();

  const [ postcodeSearchResults, setPostcodeSearchResults ] = React.useState<LocationDTO[]>([]);

  const [ postcodeSearchResultsVisible, setPostcodeSearchResultsVisible ] = React.useState(false);

  const [ location, setLocation ] = React.useState<LocationDTO>();

  const onPostcodeResultSelect = (selectedLocation: LocationDTO) => {
    setLocation(selectedLocation);
    setPostcodeSearchResults([]);
    setPostcodeSearchResultsVisible(false);
  };

  const { handleSubmit, setValue, ...formControls } = useForm<BusinessAddressFormData>();

  const [ defaultValues, setDefaultValues ] = React.useState<BusinessAddressFormData>();

  React.useEffect(() => {
    if (location) {
      const address1 = toTitleCase(location.address1 || "");
      const address2 = toTitleCase(location.address2 || "");
      const city = toTitleCase(location.city || "");
      const postCode = location.postCode || "";

      setDefaultValues({
        address1,
        address2,
        city,
        postCode
      });

      setValue("address1", address1),
      setValue("address2", address2),
      setValue("city", city),
      setValue("postCode", postCode)
    }
  }, [ location, setValue ]);

  const [ signupResponse, signupRequest ] = useApiRequest("USERS:signupRegular");

  const submitForm: SubmitHandler<BusinessAddressFormData> = React.useCallback(businessAddress => {
    signupRequest({
      data: {
        ...userDetails,
        ...businessDetails,
        locationDTO: {
          ...businessAddress,
          latitude: location?.latitude,
          longitude: location?.longitude
        },
        zipcode: businessAddress.postCode,
        workingHours: [],
        appType: RegularUserRequestAppTypeEnum.BUSINESS,
        deviceToken: Constants.deviceId || "",
        organization: true,
        deviceType:
          Platform.OS === "ios"
            ? RegularUserRequestDeviceTypeEnum.IOS
            : Platform.OS === "android"
              ? RegularUserRequestDeviceTypeEnum.ANDROID
              : RegularUserRequestDeviceTypeEnum.DEFAULT
      }
    });
  }, [ signupRequest, userDetails, businessDetails, location ]);

  React.useEffect(() => {
    if (signupResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: signupResponse.errorMessage,
        duration: 4000
      });
    } else if (signupResponse.data) {
      navigation.navigate("VerificationEmailSent");
    }
  }, [ navigation, signupResponse, snackbar ]);

  const scrollViewRef = React.useRef<any>(null);

  return (
    <>
      <CollapsibleHeaderScrollLayout
        gradientType="business"
        title="Business Address"
        height={320}
        containerRef={scrollViewRef}
        HeaderLeft={() => (
          <IconButton
            color="white"
            icon="chevron-left"
            onPress={() => navigation.goBack()}
            size={35}
          />
        )}
        HeaderForegroundComponent={React.useCallback(() => (
          <HeaderContent
            title="Business Address"
            description="Please enter the street address of your business. This will be used for pick up and delivery of found items."
            setSearchResults={setPostcodeSearchResults}
            setSearchResultsVisible={setPostcodeSearchResultsVisible}
          />
        ), [])}
      >
        <Form
          config={businessAddressFormConfig}
          validateOnBlur
          keyboardOffset={320}
          scrollViewRef={scrollViewRef}
          setValue={setValue}
          {...formControls}
        >
          <TextInput
            name="address1"
            defaultValue={defaultValues?.address1}
            label="Address Line 1"
            textContentType="streetAddressLine1"
            returnKeyType="next"
            blurOnSubmit={false}
          />
          <TextInput
            name="address2"
            defaultValue={defaultValues?.address2}
            label="Address Line 2"
            textContentType="streetAddressLine2"
            returnKeyType="next"
            blurOnSubmit={false}
          />
          <TextInput
            name="city"
            defaultValue={defaultValues?.city}
            label="City"
            textContentType="addressCity"
            returnKeyType="next"
            blurOnSubmit={false}
          />
          <TextInput
            name="postCode"
            defaultValue={defaultValues?.postCode}
            label="Postcode"
            textContentType="postalCode"
            returnKeyType="next"
            blurOnSubmit={false}
          />
          <Button
            type="tertiary"
            onPress={handleSubmit(submitForm)}
            label="Register"
            style={{ marginTop: 6, marginBottom: 24 }}
          />
        </Form>
        {postcodeSearchResultsVisible && postcodeSearchResults.length > 0 && (
          <ResultsWrapper>
            <Results nestedScrollEnabled>
              {postcodeSearchResults.map((location, i) => (
                <LocationSearchResult
                  key={i}
                  location={location}
                  onPress={() => onPostcodeResultSelect(location)}
                />
              ))}
            </Results>
          </ResultsWrapper>
        )}
      </CollapsibleHeaderScrollLayout>
    </>
  );
};

export default BusinessAddressScreen;

interface HeaderContentProps {
  title: string;
  description: string;
  setSearchResults: React.Dispatch<React.SetStateAction<LocationDTO[]>>;
  setSearchResultsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderContent: React.FC<HeaderContentProps> = ({
  title,
  description,
  setSearchResults,
  setSearchResultsVisible
}) => (
  <View style={{ marginTop: 130, paddingHorizontal: 26 }}>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
    <PostcodeSearch setSearchResults={setSearchResults} setSearchResultsVisible={setSearchResultsVisible} />
  </View>
);

const ResultsWrapper = styled.View`
  position: absolute;
  top: -8px;
  width: 100%;
  align-self: center;
`;

const Results = styled.ScrollView`
  height: 250px;
  background-color: white;
  border-radius: 2px;
`;

const ResultWrapper = styled.View`
  height: 50px;
  padding: 0 12px;
  justify-content: center;
  border-bottom-width: 0.5px;
  border-color: rgba(0,0,0,0.05);
`;

interface LocationSearchResultProps {
  location: LocationDTO;
  onPress: () => void;
}

const LocationSearchResult: React.FC<LocationSearchResultProps> = ({ location, onPress }) => (
  location.address1 && location.city ? (
    <TouchableHighlight onPress={onPress}>
      <ResultWrapper>
        <Text type="h3" ellipsizeMode="tail" numberOfLines={1}>
          {[
            toTitleCase(location.address1),
            toTitleCase(location.city),
            location.postCode
          ].join(", ")}
        </Text>
      </ResultWrapper>
    </TouchableHighlight>
  ) : null
);
