import { Icon, IconProps } from "../icon";
import { Text } from "../text";
import styled from "../../utils/styled-components";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Collapsible from "react-native-collapsible";

interface CollapsibleViewProps {
  style?: StyleProp<ViewStyle>;
  label: string;
  LabelIcon?: React.ReactElement<IconProps>;
}

export const CollapsibleView: React.FC<CollapsibleViewProps> = ({ style, label, LabelIcon, children }) => {
  const [ collapsed, setCollapsed ] = React.useState(true);

  return (
    <CollapsibleWrapper style={style}>
      <CollapseToggle onPress={() => setCollapsed(isCollapsed => !isCollapsed)}>
        <InsuranceSectionHeader>
          <Text weight="bold">
            {label}
          </Text>
          {LabelIcon}
        </InsuranceSectionHeader>
        <Icon icon={collapsed ? "chevron-down" : "chevron-up"} style={{ marginLeft: 12 }} />
      </CollapseToggle>
      <Collapsible collapsed={collapsed}>
        {children}
      </Collapsible>
    </CollapsibleWrapper>
  );
};

const CollapsibleWrapper = styled.View`
  margin-top: 20px;
`;

const CollapseToggle = styled.Pressable`
  /* background-color: white; */
  flex-direction: row;
  height: 62px;
  padding: 0 20px;
  align-items: center;
`;

const InsuranceSectionHeader = styled.View`
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

