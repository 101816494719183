import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import { UserDTO } from "src/component-lib/src/utils/api";

import AccountMembersScreen
  from "src/screens/authorized/settings/AccountMembers";
import BusinessAddressScreen
  from "src/screens/authorized/settings/BusinessAddress";
import BusinessDetailsScreen
  from "src/screens/authorized/settings/BusinessDetails";
import ChangePasswordScreen
  from "src/screens/authorized/settings/ChangePassword";
import InviteMemberScreen from "src/screens/authorized/settings/InviteMember";
import MemberDetailsScreen from "src/screens/authorized/settings/MemberDetails";
import SettingsScreen from "src/screens/authorized/settings/Settings";

import { CompositeNavigation } from "src/types/navigation";

import {
  AuthorizedStackCompositeNavigationProps,
  AuthorizedStackParamList
} from "./AuthorizedNavigator";

export type SettingsStackParamList = {
  SettingsScreen: {
    user: UserDTO;
  };
  EditBusinessDetailsScreen: {
    user: UserDTO;
  };
  EditBusinessAddressScreen: {
    user: UserDTO;
  };
  EditBusinessHoursScreen: {
    user: UserDTO;
  }
  AccountMembersScreen: undefined;
  MemberDetailsScreen: {
    user: UserDTO
  };
  InviteMemberScreen: undefined;
  ChangePasswordScreen: undefined;
};

export type SettingsStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<SettingsStackParamList>,
  AuthorizedStackCompositeNavigationProps
>;

export type SettingsStackScreenProps<
  T extends keyof SettingsStackParamList
> = CompositeNavigation<
  RouteProp<SettingsStackParamList, T>,
  SettingsStackCompositeNavigationProps
>;

type SettingsStackNavigationProps = CompositeNavigation<
  RouteProp<AuthorizedStackParamList, "SettingsStack">,
  SettingsStackCompositeNavigationProps
>;

const ItemStack = createStackNavigator<SettingsStackParamList>();

const ItemStackNavigator: React.FC<SettingsStackNavigationProps> = () => (
  <ItemStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>
    <ItemStack.Screen name="SettingsScreen" component={SettingsScreen} />
    <ItemStack.Screen name="EditBusinessDetailsScreen" component={BusinessDetailsScreen} />
    <ItemStack.Screen name="EditBusinessAddressScreen" component={BusinessAddressScreen} />
    <ItemStack.Screen name="AccountMembersScreen" component={AccountMembersScreen} />
    <ItemStack.Screen name="MemberDetailsScreen" component={MemberDetailsScreen} />
    <ItemStack.Screen name="InviteMemberScreen" component={InviteMemberScreen} />
    <ItemStack.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} />
  </ItemStack.Navigator>
);

export default ItemStackNavigator;
