import React from "react";

import { useSnackbarContext } from "../../hoc/snackbar";
import NetInfo, {
  NetInfoCellularState,
  NetInfoStateType
} from "@react-native-community/netinfo";

const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<T>();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [ value ]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const useNetworkStatusAlerts = () => {
  const snackbar = useSnackbarContext();

  const [ initialised, setInitialised ] = React.useState<boolean>(false);

  const [ connectionStatus, setConnectionStatus ] = React.useState<"offline" | "poor" | "online">("online");

  const prevConnectionStatus = usePrevious(connectionStatus);

  React.useEffect(() => {
    if (prevConnectionStatus !== connectionStatus) {
      if (connectionStatus === "offline") {
        snackbar.show({
          text: "Internet connection lost!",
          type: "error",
          duration: 0
        });
      } else if (connectionStatus === "poor") {
        snackbar.show({
          text: "Poor connection detected! The app may be slow to respond.",
          type: "warning",
          duration: 4000
        });
      // initialised check prevents this from triggering on initial load
      } else if (initialised && connectionStatus === "online") {
        snackbar.show({
          text: "Back online! The joy!",
          type: "success",
          duration: 4000
        });
      }
    }
  }, [ connectionStatus, initialised, prevConnectionStatus, snackbar ]);

  React.useEffect(
    () => NetInfo.addEventListener(({
      type,
      isConnected,
      isInternetReachable,
      details
    }) => {
      if (isInternetReachable !== null) {
        const hasPoorCellularConnection = (
          type === NetInfoStateType.cellular
          && (details as NetInfoCellularState["details"]).cellularGeneration === "2g"
        );

        if (!isConnected || !isInternetReachable) {
          setConnectionStatus("offline");
        } else if (hasPoorCellularConnection) {
          setConnectionStatus("poor");
        } else if (isConnected && isInternetReachable) {
          setConnectionStatus("online");
        }

        if (!initialised) {
          setInitialised(true);
        }
      }
    }),
    [ initialised ]
  );
};

export default useNetworkStatusAlerts;
