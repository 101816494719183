import React from "react";
import {
  TouchableHighlightProps,
  StyleProp,
  ViewStyle,
  StyleSheet,
  Platform,
  TouchableNativeFeedback,
  TouchableHighlight
} from "react-native";
import styled from "../../utils/styled-components";
import _ from "lodash";

const TouchableHighlightCustom: React.FC<TouchableHighlightProps> = ({ children, style, ...rest }) => {

  const sharedStyleElements = [
    "width",
    "minWidth",
    "maxWidth",
    "height",
    "minHeight",
    "maxHeight",
    "borderRadius"
  ];

  const touchableHighlightStyleElements = [
    "flex",
    "flexBasis",
    "flexGrow",
    "flexShrink",
    "justifyContent",
    "alignItems",
    "paddingTop",
    "paddingRight",
    "paddingBottom",
    "paddingLeft"
  ];

  let cloneStyle: StyleProp<ViewStyle> = [];
  let viewStyles: StyleProp<ViewStyle> = [];
  let touchableHighlightStyles: StyleProp<ViewStyle> = [];

  // If the style is an array
  if (Array.isArray(style)) {
    // Clone the style
    cloneStyle = [ ...style ];

    // Take all styled but the ones in touchableHighlightStyleElements
    viewStyles = cloneStyle.map(s => {
      return _.omit(s as ViewStyle, touchableHighlightStyleElements);
    });

    // Pick only the styles that are in the touchableHighlightStyleElements
    touchableHighlightStyles = cloneStyle.map(s => {
      return _.pick(s as ViewStyle, [
        ...touchableHighlightStyleElements,
        ...sharedStyleElements
      ]);
    });
  }

  return (
    <TouchableHighlightWrapper style={style}>
      <TouchableHighlightStyled
        underlayColor={"rgba(0,0,0,0.1)"}
        style={StyleSheet.compose(touchableHighlightStyles, {width: "100%"})}
        {...rest}
      >
        {children}
      </TouchableHighlightStyled>
    </TouchableHighlightWrapper>
  );
};

export default TouchableHighlightCustom;

const TouchableHighlightWrapper = styled.View``;

const TouchableHighlightStyled = styled(
  Platform.OS === "android" ? TouchableNativeFeedback : TouchableHighlight
)``;
