import {
  BusinessLocationDTO,
  BusinessLocationRequest,
  LocationDTO,
  LocationRequest,
  PickParams
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const LocationRoutes = {
  /* Fetch location */
  "LOCATIONS:getLocation": {
    path: "/locations/:locationUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LocationDTO, null, PickParams<"locationUuid">>,

  /* Get address from CraftyClicks services by postcode */
  "LOCATIONS:getAddress": {
    path: "/locations/addressByPostcode",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<BusinessLocationDTO, BusinessLocationRequest>,

  /* Fetch drop-off locations */
  "LOCATIONS:getUserLocations": {
    path: "/users/:userUuid/locations",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LocationDTO[], null, PickParams<"userUuid">>,

  /* New location */
  "LOCATIONS:createLocation": {
    path: "/users/:userUuid/locations",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    LocationDTO,
    LocationRequest,
    PickParams<"userUuid">
  >
};

export default LocationRoutes;
