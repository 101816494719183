import * as React from "react";

import { StyleSheet } from "react-native";

import { Text } from "src/component-lib/src/components/text";
import styled from "src/component-lib/src/utils/styled-components";
import defaultTheme from "src/component-lib/src/utils/theme";

const Divider: React.FC<{ text?: string }> = ({ text = "or" }) => (
  <DividerWrapper>
    <DividerLine />
    <Text type="h3" style={{ marginLeft: 24, marginRight: 24 }}>
      { text }
    </Text>
    <DividerLine />
  </DividerWrapper>
);

export default Divider;

const DividerWrapper = styled.View`
  margin-top: 36px;
  margin-bottom: 18px;
  flex-direction: row;
  align-items: center;
`;

const DividerLine = styled.View`
  flex: 1;
  height: 1px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${({ theme }) => theme?.colors?.fg || defaultTheme.light.colors.fg };
  margin: 12px 0;
`;
