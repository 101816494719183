import React from "react";

import {
  NativeSyntheticEvent,
  TextInput as FormTextInput,
  TextInputFocusEventData,
  TextInputProps as RNTextInputProps
} from "react-native";

import { Text } from "../../text";
import { Tooltip } from "../../tooltip";
import styled, { css } from "../../../utils/styled-components";

export interface TextInputProps extends RNTextInputProps {
  name?: string; // only required when used within <Form /> wrapper
  label?: string; // only required when used within <Form /> wrapper
  inputRef?: React.Ref<FormTextInput>;
  tooltip?: string;
  currencyPrefix?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  inputRef,
  tooltip,
  currencyPrefix = false,
  ...props
}) => {
  const [ isFocused, setIsFocused ] = React.useState<boolean>(false);

  const [ isEmpty, setIsEmpty ] = React.useState<boolean>(!props.defaultValue);

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
    setIsFocused(true);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
    setIsFocused(false);
  };

  return (
    <Wrapper>
      {tooltip && (
        <Tooltip show={isFocused} content={tooltip} />
      )}
      <TextInputWrapper
        {...props}
        ref={inputRef}
        onChangeText={val => {
          setIsEmpty(!val);
          if (props.onChangeText) {
            props.onChangeText(val);
          }
        }}
        withPrefix={currencyPrefix}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {currencyPrefix && (isFocused || !isEmpty) && (
        <PrefixText color="neutral">
          £
        </PrefixText>
      )}
    </Wrapper>
  );
};

export default TextInput;

const Wrapper = styled.View`
  position: relative;
`;

const TextInputWrapper = styled(FormTextInput).attrs<{ placeholderTextColor: string; }>(
  ({ theme }) => ({ placeholderTextColor: theme.colors.neutral })
)<{
  withPrefix: boolean;
}>`
  ${({ theme, withPrefix }) => css`
    font-size: ${theme.text.medium};
    font-family: ${theme.font.regular};
    color: ${theme.colors.fg};
    padding: 10px;
    padding-left: ${withPrefix ? 28 : 10}px;
    background-color: ${theme.colors.bgAccent};
    border-radius: 3px;
    width: 100%;
  `}
`;

const PrefixText = styled(Text)`
  position: absolute;
  align-self: center;
  left: 12px;
  top: 25%;
`;
