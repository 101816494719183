import React from "react";

import {
  useIsFocused,
  useScrollToTop
} from "@react-navigation/native";

import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  NotificationDTO,
  NotificationTypeEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import NotificationCard from "src/components/NotificationCard";

import {
  NotificationsStackScreenProps
} from "src/navigation/authorized/MainTabNavigator/NotificationStack";

/**
 * Notifications
 *
 * @todo Accept real date from the API.
 * @todo Link to the found flow onPress
 */
const Notifications: React.FC<NotificationsStackScreenProps<
  "NotificationScreen"
>> = props => {

  const [ notifications, setNotifications ] = React.useState<NotificationDTO[]>([]);

  const snackbar = React.useRef(useSnackbarContext());

  const { authTokenState } = useAuthenticationContext();
  const [ notificationsResponse, notificationsRequest ] = useApiRequest("NOTIFICATIONS:matches");

  const screenIsFocused = useIsFocused();

  React.useEffect(() => {
    if (screenIsFocused && authTokenState?.userUUID) {
      notificationsRequest({ "pathParams": { userUuid: authTokenState.userUUID } });
    }
  }, [ screenIsFocused, notificationsRequest, authTokenState ]);

  React.useEffect(() => {
    if (notificationsResponse.data) {
      setNotifications(notificationsResponse.data);
    }
    if (notificationsResponse.errorMessage){
      snackbar.current.show({
        type: "error",
        duration: 5000,
        text:
          "Can't get notifications. Please try again later."
      });
    }

  }, [ notificationsResponse ]);

  const handleClick = React.useCallback(
    (notification: NotificationDTO) => {
      console.log(notification.data);
    },
    []
  );

  const containerRef = React.useRef<any>(null);

  // scroll to top on tab bar icon press
  useScrollToTop(containerRef);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      title="Notifications"
      containerRef={containerRef}
    >
      {notifications.length === 0 ? (
        <NoNotificationsWrapper>
          <Text>You have no notifications.</Text>
        </NoNotificationsWrapper>
      ) : (
        <NotificationWrapper>
          {notifications.map(notification => (
            <NotificationCard
              key={notification.id}
              isNew={notification.data?.type === NotificationTypeEnum.MATCH}
              handleClick={notification.data?.matchID ? () => handleClick(notification) : undefined}
              notification={notification}
            />
          ))}
        </NotificationWrapper>
      )}
    </CollapsibleHeaderScrollLayout>
  );
};

export default Notifications;

const NoNotificationsWrapper = styled(Text)`
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NotificationWrapper = styled.View`
  padding-bottom: 10px;
`;
