import * as React from "react";

import { Asset } from "expo-asset";
import * as Font from "expo-font";

import {
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";

import BikeImgSrc from "assets/images/bike.png";
import SFShapes from "assets/images/business-sf-shapes.png";
import CameraGreen from "assets/images/camera-green.png";
import CameraWhite from "assets/images/camera-white.png";
import ComputerImgSrc from "assets/images/computer.png";
import ConveyerBeltImgSrc from "assets/images/conveyer-belt.png";
import GuitarImgSrc from "assets/images/guitar.png";
import SFPlaceholder from "assets/images/icon.png";
import Logo from "assets/images/logo_transparent.png";
import FoundMapMarkerSelected
  from "assets/images/map-marker-found-selected.png";
import FoundMapMarkerUnselected
  from "assets/images/map-marker-found-unselected.png";
import SFCamera from "assets/images/sf-camera.png";
import SFDogShapes from "assets/images/sf-dog-shapes.png";
import SFDog from "assets/images/sf-dog.png";
import SFTorch from "assets/images/sf-torch.png";
import Shapes from "assets/images/shapes.png";
import SF from "assets/images/sir-foundalot.png";
import SplashImg from "assets/images/splash.png";
import PhoneImgSrc from "assets/images/telephone.png";

const useAssets = () => {
  const [ assetsReady, setAssetsReady ] = React.useState<boolean>(false);

  React.useEffect(() => {

    const fontAssets = [
      {
        "nunito-regular": require("assets/fonts/Nunito-Regular.ttf"),
        "nunito-bold": require("assets/fonts/Nunito-Bold.ttf")
      },
      Entypo.font,
      Ionicons.font,
      MaterialIcons.font,
      MaterialCommunityIcons.font
    ];

    const fontAssetsPromise = Promise.all(
      fontAssets.map(font => Font.loadAsync(font))
    );

    const imageAssetsPromise = Asset.loadAsync([
      SplashImg,
      Logo,
      SFPlaceholder,
      FoundMapMarkerUnselected,
      FoundMapMarkerSelected,
      CameraGreen,
      CameraWhite,
      SF,
      SFDog,
      SFTorch,
      SFCamera,
      Shapes,
      SFShapes,
      SFDogShapes,
      BikeImgSrc,
      ComputerImgSrc,
      GuitarImgSrc,
      PhoneImgSrc,
      ConveyerBeltImgSrc
    ]);

    Promise.all([ fontAssetsPromise, imageAssetsPromise ]).then(() => {
      setAssetsReady(true);
    });
  }, []);

  return assetsReady;
};

export default useAssets;
