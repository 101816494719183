import React from "react";

import {
  AppState,
  AppStateStatus
} from "react-native";

import {
  EventArg,
  useNavigation
} from "@react-navigation/native";

import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";

import {
  geolocationErrorToHuman
} from "src/utils/location-settings/geolocationErrorToHuman";

import { NavigationState } from "src/types/navigation";

/**
 * Geolocation hook
 */
const useGeolocation = () => {
  const [ appState, setAppState ] = React.useState<AppStateStatus>("active");
  const [ navState, setNavState ] = React.useState<NavigationState>("focus");
  const [ currentPos, setCurrentPos ] = React.useState<Coordinates | undefined>();
  const navigation = useNavigation();
  const snackbar = React.useRef(useSnackbarContext());

  /**
   * Handles app state change for AppState event listeners
   */
  const handleAppStateChange = React.useCallback((state: AppStateStatus) => {
    // Update the appState
    setAppState(state);
  }, []);

  /**
   * Handles navigation state change for navState event listeners
   */
  const handleNavigationStateChange = React.useCallback(
    (callback: EventArg<"focus" | "blur">) => {
      // Update the navState
      setNavState(callback.type);
    },
    []
  );

  // Check if the app is in focus
  React.useEffect(() => {
    AppState.addEventListener("change", handleAppStateChange);

    return () => {
      AppState.removeEventListener("change", handleAppStateChange);
    };
  }, [ appState, handleAppStateChange ]);

  // Check if the screen is in focus
  React.useEffect(() => {
    navigation.addListener("focus", handleNavigationStateChange);
    navigation.addListener("blur", handleNavigationStateChange);

    return () => {
      navigation.removeListener("focus", handleNavigationStateChange);
      navigation.removeListener("blur", handleNavigationStateChange);
    };
  }, [ navigation, handleNavigationStateChange ]);

  // Get the devices geolocation
  React.useEffect(() => {
    // If the device's location is not already gotten, attempt to get it with
    // the user's permission. Otherwise error.
    if (!currentPos && appState === "active" && navState === "focus") {
      navigator.geolocation.getCurrentPosition(
        pos => {
          setCurrentPos(pos.coords);
        },
        err => {
          const humanErrMsg = geolocationErrorToHuman(err.code);

          // Display the error
          snackbar.current.show({
            text: humanErrMsg,
            duration: 10 * 1000, // X seconds > Milliseconds
            type: "error"
          });
        },
        {
          maximumAge: 0,
          enableHighAccuracy: false
        }
      );
    }
  }, [ currentPos, appState, navState ]);

  return currentPos;
};

export default useGeolocation;
