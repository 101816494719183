import React from "react";

import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";
import { Text, TextProps } from "../text";

export type TextSize = keyof Theme["text"] | number;

interface ProgressTextProps extends Omit<TextProps, "size"> {
  progress?: number;
  size?: TextSize;
}

interface ProgressTextStyledProps {
  textSize: TextSize;
}

/**
 * Process text component
 */
const ProgressText: React.FC<ProgressTextProps> = ({
  progress = 0,
  size = "large",
  color = "white",
  ...rest
}) => (
  <ProgressTextStyled textSize={size} color={color} {...rest}>
    {Math.round(progress)}%
  </ProgressTextStyled>
);

export default ProgressText;

const ProgressTextStyled = styled(Text)<ProgressTextStyledProps>`
  ${({ theme, textSize }) => css`
    text-align: center;
    font-family: ${theme.font.bold};
    font-size: ${typeof textSize === "number"
    ? `${textSize}px`
    : theme.text[ textSize ]};
  `}
`;
