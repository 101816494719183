import React from "react";

import styled from "../../../utils/styled-components";
import { Text } from "../../text";

interface FormRowErrorProps {
  text: string;
}

const FormRowError: React.SFC<FormRowErrorProps> = props => {
  return (
    <FormRowErrorWrapper>
      <Text type="small" color="error">
        {props.text}
      </Text>
    </FormRowErrorWrapper>
  );
};

export default FormRowError;

const FormRowErrorWrapper = styled.View`
  margin-bottom: 5px;
`;
