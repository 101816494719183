import React from "react";

import {
  Switch as RNSwitch,
  SwitchProps as RNSwitchProps,
  View
} from "react-native";

import { Text } from "../../text";
import styled, { css } from "../../../utils/styled-components";
import { Theme } from "../../../utils/theme";

interface SwitchProps extends RNSwitchProps {
  label: string;
  type?: keyof Theme["actions"];
}

interface StyledSwitchProps extends RNSwitchProps {
  type: keyof Theme["actions"];
}

const Switch: React.FC<SwitchProps> = ({ label, type, ...props }) => (
  <SwitchWrapper>
    <Text>{label}</Text>
    <StyledSwitch
      type={type || "success"}
      {...props}
    />
  </SwitchWrapper>
);

const StyledSwitch = styled(RNSwitch).attrs<StyledSwitchProps>(
  ({ theme, type }) => ({
    trackColor: { true: theme.actions[ type ] }
  })
)<StyledSwitchProps>``;

const SwitchWrapper = styled.View`
  ${({ theme }) => css`
    font-size: ${theme.text.medium};
    color: ${theme.colors.fg};
    padding: 10px;
    background-color: ${theme.colors.bgAccent};
    border-radius: 3px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export default Switch;
