import * as React from "react";

import { ImagePickerResult } from "expo-image-picker";
import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import {
  Keyboard,
  View
} from "react-native";

import {
  useIsFocused,
  useScrollToTop
} from "@react-navigation/native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  TextInput
} from "src/component-lib/src/components/form";
import { ImageCircle } from "src/component-lib/src/components/image-circle";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  useApiRequest,
  UserDTO
} from "src/component-lib/src/utils/api";
import styled, { css } from "src/component-lib/src/utils/styled-components";

import ImagePickerModal from "src/components/ImagePickerModal";

import {
  MainTabScreenProps
} from "src/navigation/authorized/MainTabNavigator/MainTabNavigator";

import {
  profileFormConfig,
  ProfileFormData
} from "./profileFormConfig";

const ProfileScreen: React.FC<MainTabScreenProps<"ProfileScreen">> = props => {
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ getUserInfoResponse, getUserInfoRequest ] = useApiRequest("USERS:getUserInfo");

  const screenIsFocused = useIsFocused();

  // get user info on screen focus
  React.useEffect(() => {
    if (screenIsFocused) {
      if (authTokenState?.userUUID) {
        getUserInfoRequest({
          pathParams: { userUuid: authTokenState.userUUID }
        });
      }
    }
  }, [ screenIsFocused, authTokenState, getUserInfoRequest ]);

  const [ userInfo, setUserInfo ] = React.useState<UserDTO>();

  const { handleSubmit, setValue, ...formControls } = useForm<ProfileFormData>();

  const [ defaultValues, setDefaultValues ] = React.useState<Partial<ProfileFormData>>();

  const [ profilePictureUrl, setProfilePictureUrl ] = React.useState<string>();

  const [ userRole, setUserRole ] = React.useState<"Admin" | "Staff">();

  // pre-fill user profile form values
  // N.B. this does not visually update the value of the TextInputs as they are uncontrolled
  // these can instead be set by setting the defaultValues object
  // the relevant field values can then be passed into the defaultValue prop of each input
  React.useEffect(() => {
    let didCancel = false;
    // on failure, show error snack
    if (getUserInfoResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: getUserInfoResponse.errorMessage,
        duration: 5000
      });
      // on success, fill form fields with user data
    } else if (getUserInfoResponse.data) {
      setUserInfo(getUserInfoResponse.data);

      const {
        firstName,
        lastName,
        userProfilePicture,
        emailAddress: email,
        phoneNumber: phone,
        roles
      } = getUserInfoResponse.data;

      // set user role
      if (roles && roles.length > 0) {
        setUserRole(roles[ 0 ] === "COMPANY_ADMIN" ? "Admin" : "Staff");
      }

      if (!didCancel) {
        setProfilePictureUrl(userProfilePicture);

        const name = `${firstName ?? ""}${lastName ? " " + lastName : ""}`;

        setDefaultValues({
          name,
          email,
          phone
        });

        setValue("name", name);
        setValue("email", email || "");
        setValue("phone", phone || "");
      }

      return () => {
        didCancel = true;
      };
    }
  }, [ getUserInfoResponse, setValue, snackbar ]);

  const [ updateUserInfoResponse, updateUserInfoRequest ] = useApiRequest("USERS:updateUserInfo");

  /**
   * @param data Submit the form
   */
  const submitForm: SubmitHandler<ProfileFormData> = data => {
    if (authTokenState?.userUUID) {
      Keyboard.dismiss();

      const [ firstName, lastName ] = data.name ? data.name.split(" ") : [];

      // provide existing data explicitly to stop backend overwriting with undefined
      const {
        birthdate,
        businessName,
        businessType,
        website,
        locationDTO,
        workingHours,
        acceptMarketing
      } = userInfo || {};

      const convertedBirthdate = (birthdate && new Date(birthdate)) || undefined;

      updateUserInfoRequest({
        pathParams: { userUuid: authTokenState.userUUID },
        data: {
          // form data
          firstName,
          lastName,
          emailAddress: data.email,
          phoneNumber: data.phone,
          // existing data
          birthdate: convertedBirthdate,
          businessName,
          businessType,
          website,
          address: `${locationDTO?.address1}, ${locationDTO?.city}`,
          workingHours,
          locationDTO,
          acceptMarketing
        }
      });
    }
  };

  React.useEffect(() => {
    if (updateUserInfoResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: updateUserInfoResponse.errorMessage,
        duration: 5000
      });
    } else if (updateUserInfoResponse.data) {
      snackbar.show({
        text: "Profile saved!",
        duration: 4000,
        type: "success"
      });
    }
  }, [ snackbar, updateUserInfoResponse ]);

  const [ updateProfilePicResponse, updateProfilePicRequest ] = useApiRequest("USERS:updateUserImage");

  const [ pickerModalVisible, setPickerModalVisible ] = React.useState(false);

  const onProfileImagePick = async (result: ImagePickerResult) => {
    if (authTokenState && !result.cancelled) {
      try {
        // hide picker modal
        setPickerModalVisible(false);
        // use Fetch API to get file blob from local uri
        const localFile = await fetch(result.uri);
        const blob = await localFile.blob();

        const formData = new FormData();

        formData.append("file", {
          uri: result.uri,
          type: blob.type,
          name: result.uri.split("/").pop() || "image.png"
        });
        // update profile pic
        updateProfilePicRequest({
          pathParams: { userUuid: authTokenState?.userUUID },
          data: formData
        });
      } catch (e) {
        snackbar.show({
          type: "error",
          text: "Image processing failed",
          duration: 5000
        });
      }
    }
  };

  React.useEffect(() => {
    if (authTokenState?.userUUID) {
      if (updateProfilePicResponse.errorMessage) {
        snackbar.show({
          type: "error",
          text: updateProfilePicResponse.errorMessage,
          duration: 5000
        });
      } else if (updateProfilePicResponse.data) {
        snackbar.show({
          text: "Profile picture updated!",
          duration: 4000,
          type: "success"
        });
        // get user info again
        getUserInfoRequest({
          pathParams: { userUuid: authTokenState.userUUID }
        });
      }
    }
  }, [ authTokenState, getUserInfoRequest, snackbar, updateProfilePicResponse ]);

  const scrollViewRef = React.useRef<any>(null);

  // scroll to top on tab bar icon press
  useScrollToTop(scrollViewRef);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      height={260}
      title="Profile"
      containerRef={scrollViewRef}
      HeaderRight={() => (
        <IconButton
          onPress={() => props.navigation.navigate("SettingsStack", {
            screen: "SettingsScreen",
            params: { user: userInfo }
          })}
          icon="md-settings"
          color="white"
          style={{ marginRight: 14 }}
        />
      )}
      HeaderForegroundComponent={React.useCallback(() => (
        <ImageHeaderWrapper>
          <View>
            <ImageCircle
              source={{
                uri: profilePictureUrl,
                headers: {
                  Authorization: authTokenState?.token.value || ""
                }
              }}
              defaultSource={require("assets/images/icon.png")}
              onPress={() => setPickerModalVisible(true)}
            />
            <IconWrapper>
              <IconButton
                onPress={() => setPickerModalVisible(true)}
                icon="ios-add"
                color="white"
              />
            </IconWrapper>
          </View>
          <TagWrapper>
            {userInfo?.businessName && (
              <CompanyTag>
                <Label>{userInfo?.businessName}</Label>
              </CompanyTag>
            )}
            {userRole && (
              <UserRoleTag>
                <Label>{userRole}</Label>
              </UserRoleTag>
            )}
          </TagWrapper>
        </ImageHeaderWrapper>
      ), [ profilePictureUrl, authTokenState?.token.value, userInfo?.businessName, userRole ])}
    >
      <Form
        config={profileFormConfig}
        scrollViewRef={scrollViewRef}
        setValue={setValue}
        validateOnBlur
        keyboardOffset={280}
        {...formControls}
      >
        <TextInput
          name="name"
          label="Name"
          defaultValue={defaultValues?.name}
          autoCapitalize="words"
          textContentType="name"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="email"
          label="Email"
          defaultValue={defaultValues?.email}
          autoCapitalize="none"
          textContentType="emailAddress"
          keyboardType="email-address"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="phone"
          label="Phone Number"
          defaultValue={defaultValues?.phone}
          textContentType="telephoneNumber"
          keyboardType="phone-pad"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Save Details"
        />
      </Form>
      <ImagePickerModal
        isVisible={pickerModalVisible}
        onClose={() => setPickerModalVisible(false)}
        onImagePickSuccess={onProfileImagePick}
      />
    </CollapsibleHeaderScrollLayout>
  );
};

export default ProfileScreen;

const ImageHeaderWrapper = styled.View`
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-top: 100px;
  padding-top: 25px;
  position: relative;
  align-self: center;
`;

const IconWrapper = styled.View`
  ${({ theme }) => css`
    background: ${theme.colors.green};
    width: 30px;
    height: 30px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
  `}
`;

const TagWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 18px;
`;

const CompanyTag = styled.View`
  padding: 6px 12px;
  border-radius: 24px;
  background-color: ${ ({ theme }) => theme.colors.purple};
`;

const UserRoleTag = styled.View`
  margin-left: 6px;
  padding: 6px 12px;
  border-radius: 24px;
  background-color: ${ ({ theme }) => theme.colors.green};
`;

const Label = styled(Text).attrs({
  color: "white",
  type: "small-uppercase"
})``;
