// styled-components.ts
import * as styledComponents from "styled-components/native";

import { Theme } from "../../utils/theme";

const {
  default: styled,
  css,
  ThemeProvider,
  useTheme
} = styledComponents as unknown as styledComponents.ReactNativeThemedStyledComponentsModule<Theme>;

export { css, ThemeProvider, useTheme };
export default styled;
