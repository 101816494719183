import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import {
  FoundItemResponse,
  ItemDTO,
  LocationDTO
} from "src/component-lib/src/utils/api";

import AdditionalDetailsScreen
  from "src/screens/authorized/found-item/AdditionalDetails";
import FoundItemScreen from "src/screens/authorized/found-item/FoundItem";
import FoundItemConfirmationScreen
  from "src/screens/authorized/found-item/FoundItemConfirmation";
import FoundItemDetailsScreen
  from "src/screens/authorized/found-item/FoundItemDetails";
import FoundItemLocationScreen
  from "src/screens/authorized/found-item/FoundItemLocation";
import ImageProcessingScreen
  from "src/screens/authorized/found-item/ImageProcessing";

import { CompositeNavigation } from "src/types/navigation";

import {
  AuthorizedStackCompositeNavigationProps,
  AuthorizedStackParamList
} from "./AuthorizedNavigator";

export type FoundItemStackParamList = {
  FoundItemScreen: undefined;
  FoundItemProcessingScreen: {
    imageSrc: string;
  };
  FoundItemDetailsScreen: {
    item: ItemDTO | FoundItemResponse;
  };
  FoundItemLocationScreen: {
    item: ItemDTO | FoundItemResponse;
  };
  AdditionalDetailsScreen: {
    item: ItemDTO | FoundItemResponse;
    pinLocation: LocationDTO | undefined;
  };
  // only shows on new item creation
  FoundItemConfirmationScreen: {
    item: FoundItemResponse;
  };
};

export type FoundItemStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<FoundItemStackParamList>,
  AuthorizedStackCompositeNavigationProps
>;

export type FoundItemStackScreenProps<
  T extends keyof FoundItemStackParamList
> = CompositeNavigation<
  RouteProp<FoundItemStackParamList, T>,
  FoundItemStackCompositeNavigationProps
>;

type FoundItemStackNavigationProps = CompositeNavigation<
  RouteProp<AuthorizedStackParamList, "FoundItemStack">,
  FoundItemStackCompositeNavigationProps
>;

const ItemStack = createStackNavigator<FoundItemStackParamList>();

const ItemStackNavigator: React.FC<FoundItemStackNavigationProps> = () => (
  <ItemStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>
    <ItemStack.Screen name="FoundItemScreen" component={FoundItemScreen} />
    <ItemStack.Screen name="FoundItemProcessingScreen" component={ImageProcessingScreen} />
    <ItemStack.Screen name="FoundItemDetailsScreen" component={FoundItemDetailsScreen} />
    <ItemStack.Screen name="AdditionalDetailsScreen" component={AdditionalDetailsScreen} />
    <ItemStack.Screen name="FoundItemLocationScreen" component={FoundItemLocationScreen} />
    <ItemStack.Screen name="FoundItemConfirmationScreen" component={FoundItemConfirmationScreen} />
  </ItemStack.Navigator>
);

export default ItemStackNavigator;
