import React from "react";

import {
  Animated,
  Easing
} from "react-native";
import Svg, { Circle } from "react-native-svg";

import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

import ProgressText, { TextSize } from "./ProgressText";

type TextPosition = "above" | "center" | "below";

interface ProgressCircleProps {
  progress?: number;
  widthHeight?: number;
  stokeWidth?: number;
  strokeColor?: keyof Theme["colors"];
  animationDurationMs?: number;
  textPosition?: TextPosition;
  textSize?: TextSize;
}

interface ProgressCircleStyledProps {
  widthHeight: number;
}

interface AnimatedCircleStyledProps {
  strokeColor: keyof Theme["colors"];
}

interface ProgressTextStyledProps {
  textSize: TextSize;
  textPosition?: TextPosition;
}

/**
 * Process circle component
 */
const ProgressCircle: React.FC<ProgressCircleProps> = props => {
  const {
    progress = 0,
    widthHeight = 100,
    stokeWidth = 8,
    strokeColor = "white",
    animationDurationMs = 1000,
    textPosition,
    textSize
  } = props;

  const cxCy = widthHeight / 2;
  const radius = cxCy - stokeWidth / 2;
  const circumference = Math.PI * (radius * 2);

  const [ circumferenceOffset ] = React.useState<Animated.Value>(
    new Animated.Value(circumference * (1 - progress / 100))
  );

  const parsedTextSize = React.useMemo<TextSize>(() => {
    // If no textSize is given, return a size relative to the widthHeight
    if (!textSize) {
      return widthHeight * 0.2;
    }

    return textSize;
  }, [ textSize, widthHeight ]);

  React.useEffect(() => {
    Animated.timing(circumferenceOffset, {
      toValue: circumference * (1 - progress / 100),
      duration: animationDurationMs,
      easing: Easing.inOut(Easing.linear),
      useNativeDriver: false
    }).start();
  }, [
    animationDurationMs,
    circumference,
    circumferenceOffset,
    progress,
    widthHeight
  ]);

  return (
    <ProgressCircleStyled>
      {textPosition &&
        (textPosition === "above" || textPosition === "center") &&
          <ProgressTextStyled
            textSize={parsedTextSize}
            textPosition={textPosition}
            progress={progress}
          />
      }

      <ProgressSvgStyled widthHeight={widthHeight}>
        {/* <Svg Removed until we can find out why Svg causes an invariant violation
          width={widthHeight}
          height={widthHeight}
          viewBox={`0 0 ${widthHeight} ${widthHeight}`}
        >
          <AnimatedCircleStyled
            cx={cxCy}
            cy={cxCy}
            r={radius}
            fill="transparent"
            strokeWidth={stokeWidth}
            strokeColor={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray={circumference}
            strokeDashoffset={circumferenceOffset}
          />
        </Svg> */}
      </ProgressSvgStyled>

      {textPosition && textPosition === "below" && (
        <ProgressTextStyled
          textSize={parsedTextSize}
          textPosition={textPosition}
          progress={progress}
        />
      )}
    </ProgressCircleStyled>
  );
};

export default ProgressCircle;

/**
 * Changes the progress text padding depending on the given textPosition
 */
const ProgressTextPositionCss = (
  theme: Theme,
  textSize: TextSize,
  textPosition?: TextPosition
) => {
  switch (textPosition) {
  case "above":
    return css`
        padding-bottom: ${typeof textSize === "number"
    ? `${textSize}px`
    : theme.text[ textSize ]};
      `;

  case "center":
    return css`
        position: absolute;
      `;

  case "below":
    return css`
        padding-top: ${typeof textSize === "number"
    ? `${textSize}px`
    : theme.text[ textSize ]};
      `;

  default:
    return css``;
  }
};

const ProgressCircleStyled = styled.View`
  position: relative;
  justify-content: center;
  align-items: center;
`;

const ProgressSvgStyled = styled(Animated.View)<ProgressCircleStyledProps>`
  ${({ widthHeight }) => css`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${widthHeight}px;
    height: ${widthHeight}px;
    transform: rotate(-90deg);
  `}
`;

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const AnimatedCircleStyled = styled(AnimatedCircle).attrs<AnimatedCircleStyledProps>(({ theme, strokeColor }) => ({
  stroke: theme.colors[ strokeColor ]
}))<AnimatedCircleStyledProps>``;

const ProgressTextStyled = styled(ProgressText)<ProgressTextStyledProps>`
  ${({ theme, textSize, textPosition }) => css`
    flex-direction: row;
    ${ProgressTextPositionCss(theme, textSize, textPosition)}
  `}
`;
