import { FormConfig } from "src/component-lib/src/components/form";

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePasswordFormConfig: FormConfig<ChangePasswordFormData> = {
  oldPassword: {
    validation: {
      required: {
        value: true,
        message: "Current password is required"
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        message: "8 characters, 1 uppercase letter and 1 number required"
      }
    }
  },
  newPassword: {
    validation: {
      required: {
        value: true,
        message: "New password is required"
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        message: "8 characters, 1 uppercase letter and 1 number required"
      }
    }
  },
  newPasswordConfirm: {
    validation: {
      required: {
        value: true,
        message: "New password confirmation is required"
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        message: "8 characters, 1 uppercase letter and 1 number required"
      }
    }
  }
};

export default changePasswordFormConfig;
