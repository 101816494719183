import React from "react";

import { ImagePickerResult } from "expo-image-picker";
import { View } from "react-native";

import {
  ButtonLink,
  IconButton
} from "src/component-lib/src/components/button";
import { Card } from "src/component-lib/src/components/card";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import styled from "src/component-lib/src/utils/styled-components";

import CameraGreen from "assets/images/camera-green.png";
import SFCamera from "assets/images/sf-camera.png";

import Divider from "./Divider";
import ImagePickerModal from "./ImagePickerModal";

interface CreateItemViewProps {
  goBack: () => void;
  title: string;
  description: string;
  pickerModalVisible: boolean;
  setPickerModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onImagePick: (result: ImagePickerResult) => void;
  onImagePickError?: (error: any) => void;
  onAddImageLater: () => void;
}

const CreateItemView: React.FC<CreateItemViewProps> = ({
  goBack,
  title,
  description,
  pickerModalVisible,
  setPickerModalVisible,
  onImagePick,
  onImagePickError,
  onAddImageLater
}) => (
  <FullScreenLayout
    title="Protect Item"
    HeaderBackgroundComponent={PublicGradientBackgroundView}
    HeaderLeft={() => (
      <IconButton
        icon="ios-close"
        color="white"
        size={42}
        onPress={goBack}
      />
    )}
    HeaderForegroundComponent={() => (
      <HeaderContent
        title={title}
        description={description}
      />
    )}
  >
    <ContentWrapper>
      <Card onPress={() => setPickerModalVisible(true)}>
        <View style={{ alignItems: "center" }}>
          <CameraImage source={CameraGreen} defaultSource={CameraGreen} />
          <Text type="h3">Start by adding a photo of the item</Text>
        </View>
      </Card>
      <Divider />
      <ButtonLink
        label="Add a photo later"
        underline
        onPress={onAddImageLater}
      />
    </ContentWrapper>
    <SFImageWrapper pointerEvents="none">
      <SFImage source={SFCamera} defaultSource={SFCamera} />
    </SFImageWrapper>
    <ImagePickerModal
      tip="Try to include the item's brand and model in the image!"
      isVisible={pickerModalVisible}
      onClose={() => setPickerModalVisible(false)}
      onImagePickSuccess={onImagePick}
      onImagePickError={onImagePickError}
    />
  </FullScreenLayout>
);

export default CreateItemView;

const PublicGradientBackgroundView = styled(BackgroundView).attrs({
  gradient: "business"
})``;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);

const ContentWrapper = styled.View`
  padding-top: 48px;
  align-items: center;
`;

const CameraImage = styled(Image)`
  height: 32px;
  width: 32px;
  margin-bottom: 4px;
`;

const SFImageWrapper = styled.View`
  position: absolute;
  align-self: flex-end;
  height: 120px;
  width: 120px;
  top: 0px;
  right: 0px;
`;

const SFImage = styled(Image)`
  margin-top: 12px;
  margin-right: -6px;
`;
