export const geolocationErrorToHuman = (errorCode: string | number): string => {
  switch (errorCode) {
  case "E_LOCATION_SERVICES_DISABLED":
    return "Please turn on location services in your device's settings.";

  case "E_NO_PERMISSIONS":
    return "Please enable location services in your devices settings for this app.";

  default:
    return "Your location could not be found. Please try again.";
  }
};
