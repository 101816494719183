import {
  AllItemResponse,
  BasePaginatedQuery,
  ItemInsuranceRequest,
  FileResource,
  FoundItemResponse,
  FoundItemUpdateRequest,
  Insurance,
  Item,
  ItemCategoryEnum,
  ItemDTO,
  ItemStatusUpdate,
  LostItemRequest,
  LostItemUpdateRequest,
  MarkLostItemRequest,
  PaginatedCollectionResponseItemDTO,
  PickParams,
  PreRegisteredItemResponse,
  ResponseId,
  StringResponse
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const ItemRoutes = {
  /** Replace insurance details for current item with details in POST body */
  "ITEMS:setInsuranceDetails": {
    path: "/items/:itemUuid/insurance",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<Insurance, ItemInsuranceRequest, PickParams<"itemUuid">>,

  /** Partial update to existing insurance details for current item */
  "ITEMS:updateInsuranceDetails": {
    path: "/items/:itemUuid/insurance",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<Insurance, ItemInsuranceRequest, PickParams<"itemUuid">>,

  /** Allows a business user to view the items of all users belonging to their business */
  "ITEMS:getItemsByBusiness": {
    path: "/:userUuid/business/item",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    PickParams<"userUuid">,
    BusinessItemsQuery
  >,

  /** Get all items. Filters can be provided to return only items matching specific criteria. */
  "ITEMS:getAllItemsByFilter": {
    path: "/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    null,
    AllItemsByFilterQuery
  >,

  /** Delete list of items */
  "ITEMS:deleteItems": {
    path: "/items",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, null, DeleteItemsQuery>,

  /** Get an item */
  "ITEMS:getItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Item, null, PickParams<"itemUuid">, AllItemsQuery>,

  /** Update Item */
  "ITEMS:updateItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<Item, Item, PickParams<"itemUuid">>,

  /** Delete Item */
  "ITEMS:deleteItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"itemUuid">>,

  /** Save item image*/
  "ITEMS:saveItemImage": {
    path: "/items/:itemUuid/images",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<
    ItemDTO,
    FormData,
    PickParams<"itemUuid">
  >,

  "ITEMS:deleteItemImage": {
    path: "/item/:itemUuid/images/:fileUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid" | "fileUuid">
  >,

  /** Get an item */
  "ITEMS:getAllItems": {
    path: "/items/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, null, AllItemsQuery>,

  /** Register new found item : PENDING REGISTER USER */
  "ITEMS:registerFoundItemPendingUser": {
    path: "/items/found",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<FileResource, FormData>,

  // TODO: Check response type!
  /** Edit existing found item */
  "ITEMS:updateFoundItem": {
    path: "/items/found/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FoundItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as returned */
  "ITEMS:markItemAsReturned": {
    path: "/items/found/:itemUuid/returned",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid">
  >,

  /** Register new found item : EXISTING USER */
  "ITEMS:registerFoundItem": {
    path: "/items/found/:userUuid",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<
    FoundItemResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Register new lost item:PENDING REGISTER USER */
  "ITEMS:registerLostItemPendingUser": {
    path: "/items/lost/:userUuid",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<
    ResponseId,
    LostItemRequest,
    PickParams<"userUuid">
  >,

  /** Edit existing lost item */
  "ITEMS:updateLostItem": {
    path: "/items/lost/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    LostItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as found */
  "ITEMS:markAsFound": {
    path: "/items/lost/:itemUuid/found",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid">
  >,

  /** Register new lost item:EXISTING USER */
  "ITEMS:registerLostItem": {
    path: "/items/lost/:userUuid",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    ResponseId,
    LostItemRequest,
    PickParams<"userUuid">
  >,

  /** Register new preregistered item : PENDING USER */
  "ITEMS:preregisterItemPendingUser": {
    path: "/items/preregistered",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<FoundItemResponse, FormData>,

  /** Edit existing preregistered item */
  "ITEMS:updatePreregisteredItem": {
    path: "/items/preregistered/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FoundItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as lost */
  "ITEMS:markAsLost": {
    path: "/items/preregistered/:itemUuid/lost",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    MarkLostItemRequest,
    PickParams<"itemUuid">
  >,

  /** Register new preregistered item : EXISTING USER */
  "ITEMS:preregisterItem": {
    path: "/items/preregistered/:userUuid",
    authenticate: true,
    method: "POST",
    headers: {
      ContentType: "multipart/form-data"
    }
  } as ApiRouteDefinition<
    FoundItemResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Update status for list of items */
  "ITEMS:updateItemStatus": {
    path: "/items/status",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, ItemStatusUpdate>,

  /** Fetch all user items */
  "ITEMS:getItemsByUser": {
    path: "/users/:userUuid/items/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    PickParams<"userUuid">,
    UserItemsQuery
  >,

  /** Fetch user preregistered items */
  "ITEMS:getUserPreregisteredItems": {
    path: "/users/preregistered/:userUuid/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PreRegisteredItemResponse,
    null,
    PickParams<"userUuid">,
    AllItemsQuery
  >,

  /** Fetch user lost/found items */
  "ITEMS:getUserItemsLostFound": {
    path: "/users/users/:userUuid/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, PickParams<"userUuid">>,

  /** Fetch user returned items */
  "ITEMS:getReturnedItems": {
    path: "/users/users/preregistered/:userUuid/returned",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, PickParams<"userUuid">>
};

export default ItemRoutes;

export interface DeleteItemsQuery {
  ids: string[];
}

export interface UserItemsQuery extends BasePaginatedQuery {
  // Return only items matching this filter. The filter supports the operators ':’, '>’, or ‘<’ for 'equals’, ‘greater than’ or 'less than’. A filter can contain more than one field. For example ‘brand:MyBrand,range:ExampleRange’ finds all items branded MyBrand with range ExampleRange.
  query?: string;
}

export interface BusinessItemsQuery extends BasePaginatedQuery {
  query?: string;
  search?: string;
}

export interface AllItemsByFilterQuery extends BasePaginatedQuery {
  query?: string;
  userQuery?: string;
}

export interface AllItemsQuery extends BasePaginatedQuery {
  page: number;
  pageSize: number;
  defaultCategory?: ItemCategoryEnum;
}
