import * as React from "react";

import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "react-native";
import {
  initialWindowMetrics,
  SafeAreaProvider
} from "react-native-safe-area-context";

import {
  AppTypeEnum,
  AuthenticationProvider
} from "src/component-lib/src/hoc/authentication";
import {
  ItemCategoriesProvider
} from "src/component-lib/src/hoc/dynamic-category-provider";
import { SnackbarProvider } from "src/component-lib/src/hoc/snackbar";
import ThemeWrapper from "src/component-lib/src/hoc/theme-wrapper";
import Theme from "src/component-lib/src/utils/theme";

import SplashNavigator from "src/navigation/shared/SplashNavigator";
import { initOneSignalClient } from "src/integrations/one-signal";
import { UserInfoProvider } from "src/hoc/UserInfoProviderContext";

initOneSignalClient();

const App: React.FC = () => {
  React.useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  return (
    <ThemeWrapper theme={Theme}>
      <StatusBar barStyle="light-content" translucent />
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <SnackbarProvider>
          <AuthenticationProvider>
            <UserInfoProvider>
              <ItemCategoriesProvider appType={AppTypeEnum.BUSINESS}>
                <SplashNavigator />
              </ItemCategoriesProvider>
            </UserInfoProvider>
          </AuthenticationProvider>
        </SnackbarProvider>
      </SafeAreaProvider>
    </ThemeWrapper>
  );
};

export default App;
