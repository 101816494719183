import * as React from "react";

import * as Location from "expo-location";
import {
  Keyboard,
  LayoutChangeEvent,
  View
} from "react-native";
import RNMapView, { Region } from "react-native-maps";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { MapView } from "src/component-lib/src/components/map-view";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { LocationDTO } from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import DeleteItemModal from "src/components/DeleteItemModal";
import FloatingMapSearch from "src/components/FloatingMapSearch";

import useGeolocation from "src/hooks/useGeolocation";
import usePrevious from "src/hooks/usePrevious";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

import { isExistingItem } from "src/types/type-guards";

type FoundItemLocationScreenProps = FoundItemStackScreenProps<"FoundItemLocationScreen">;

const FoundItemLocationScreen: React.FC<FoundItemLocationScreenProps> = ({ navigation, route }) => {
  /************ MAP LOGIC ************/
  // TODO: can we move this stuff out to a hook or something?
  // seems pretty generic on each screen with a map view
  const mapViewRef = React.useRef<RNMapView>(null);

  const [ mapViewHeight, setMapViewHeight ] = React.useState<number>(0);

  const geoCoords = useGeolocation();

  const [ region, setRegion ] = React.useState<Region | undefined>({
    latitude: 53.9733,
    longitude: -2.5311,
    latitudeDelta: 10.0,
    longitudeDelta: 10.0
  });

  const prevRegion = usePrevious(region);

  const regionsAreDifferent = (oldRegion?: Region, newRegion?: Region) => (
    !!oldRegion
    && !!newRegion
    && (
      newRegion.latitude !== oldRegion.latitude
      || newRegion.latitudeDelta !== oldRegion.latitudeDelta
      || newRegion.longitude !== oldRegion.longitude
      || newRegion.longitudeDelta !== oldRegion.longitudeDelta
    )
  );

  // On geoCoords change
  React.useEffect(() => {
    // If we have the device's location coordinates set the region
    if (geoCoords) {
      setRegion({
        latitude: geoCoords.latitude,
        longitude: geoCoords.longitude,
        latitudeDelta: 0.0075,
        longitudeDelta: 0.0075
      });
    }
  }, [ geoCoords ]);

  // On region change
  React.useEffect(() => {
    // Once we have the device's location coordinates set the region, animate
    // to them
    if (region && mapViewRef.current) {
      // Initially set the region and zoom. Otherwise
      if (regionsAreDifferent(prevRegion, region)) {
        mapViewRef.current.animateToRegion(region);
      }
    }
  }, [ prevRegion, region ]);

  /************ UPDATE LOCATION LOGIC ************/

  const { item } = route.params;

  const snackbar = useSnackbarContext();

  const updateItemLocation = async () => {
    Keyboard.dismiss();

    if (region?.latitude && region?.longitude) {
      try {
        const addresses = await Location.reverseGeocodeAsync({
          longitude: region.longitude,
          latitude: region.latitude
        });

        if (addresses.length) {
          const { name: address1, city, postalCode: postCode } = addresses[ 0 ];

          const pinLocation: LocationDTO = {
            address1: address1 || undefined,
            city: city || undefined,
            postCode: postCode || undefined,
            latitude: region.latitude,
            longitude: region.longitude
          };

          navigation.navigate("AdditionalDetailsScreen", { item, pinLocation });
        } else {
          navigation.navigate("AdditionalDetailsScreen", {
            item,
            pinLocation: {
              latitude: region.latitude,
              longitude: region.longitude
            }
          });
        }
      } catch (e) {
        navigation.navigate("AdditionalDetailsScreen", {
          item,
          pinLocation: {
            latitude: region.latitude,
            longitude: region.longitude
          }
        });
      }
    }
  };

  const [ deleteItemModalVisible, setDeleteItemModalVisible ] = React.useState(false);

  const onClose = () => {
    if (isExistingItem(item)) {
      navigation.navigate("MainTabNavigator");
    } else {
      setDeleteItemModalVisible(true);
    }
  };

  const { top, bottom } = useSafeAreaInsets();

  return (
    <>
      <FullScreenLayout
        noPadding
        title="Found Item Location"
        BackgroundComponent={BusinessGradientBackgroundView}
        HeaderLeft={() => (
          <IconButton
            style={{ height: 42, marginLeft: 15 }}
            icon="ios-arrow-back"
            color="white"
            size={28}
            onPress={() => navigation.goBack()}
          />
        )}
        HeaderRight={() => (
          <Button
            type="tertiary"
            onPress={updateItemLocation}
            label="Save"
            style={{ marginRight: 12 }}
          />
        )}
        HeaderForegroundComponent={() => (
          <View>
            <Text type="h1" color="white">
              Found Item Location
            </Text>
            <Text style={{ marginTop: 12, marginBottom: 48 }} color="white">
              Search or move the pin to where you found the item
            </Text>
          </View>
        )}
      >
        <RelativeViewWrapper padBottom={bottom}>
          <MapView
            ref={mapViewRef}
            initialRegion={region}
            showsUserLocation={true}
            showsMyLocationButton={true}
            onRegionChangeComplete={newRegion => setRegion(newRegion)}
            onLayout={(event: LayoutChangeEvent) => {
              const { height } = event.nativeEvent.layout;
              // Set the mapViewHeight
              if (height !== mapViewHeight) {
                setMapViewHeight(height);
              }
            }}
          />
          <MarkerOverlay>
            <MapMarker />
          </MarkerOverlay>
        </RelativeViewWrapper>

        <DeleteItemModal
          title="Oops, we still need a few more details to register this item!"
          isVisible={deleteItemModalVisible}
          onClose={() => setDeleteItemModalVisible(false)}
          itemId={item.id}
          onDelete={() => navigation.navigate("MainTabNavigator")}
          cancelCta="Continue"
          deleteCta="Abandon"
          showSuccessSnack={false}
        />
      </FullScreenLayout>

      {/* floating absolutely positioned view to allow search results to sit above map view */}
      {/* workaround b/c RN clips subviews and ignores overflow, z-index etc */}
      <FloatingMapSearch setRegion={setRegion} offset={top + 88} />
    </>
  );
};

export default FoundItemLocationScreen;

const BusinessGradientBackgroundView = styled(BackgroundView).attrs({
  gradient: "business"
})``;

const RelativeViewWrapper = styled.View<{ padBottom: number }>`
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: -${({ padBottom }) => padBottom}px;
  height: 40vh;
`;

const MarkerOverlay = styled.View`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  justify-content: center;
  /* offset marker height so bottom aligns with map centre */
  padding-bottom: 36px;
`;

const MapMarker = styled.Image.attrs({
  source: require("assets/images/map-marker-found-unselected.png")
})`
  width: 36px;
  height: 36px;
`;
