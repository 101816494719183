import React from "react";

import { TextInput } from "src/component-lib/src/components/form";
import { Icon } from "src/component-lib/src/components/icon";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  LocationDTO,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import { toTitleCase } from "src/utils/strings/title-case";

interface PostcodeSearchProps {
  setSearchResults: React.Dispatch<React.SetStateAction<LocationDTO[]>>;
  setSearchResultsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostcodeSearch: React.FC<PostcodeSearchProps> = ({ setSearchResults, setSearchResultsVisible }) => {
  const snackbar = useSnackbarContext();

  const [ searchValue, setSearchValue ] = React.useState("");

  const [ searchByPostcodeResponse, searchByPostcodeRequest ] = useApiRequest("LOCATIONS:getAddress");

  const searchByPostcode = () => {
    if (searchValue) {
      searchByPostcodeRequest({
        data: { postcode: searchValue }
      });
    }
  };

  const sortAddresses = (a: LocationDTO, b: LocationDTO) => {
    if (!a?.address1 || !b?.address1) {
      return 0;
    }
    // get house name / number
    const houseA = a.address1.split(" ")[ 0 ];
    const houseB = b.address1.split(" ")[ 0 ];

    const nonNumericCharacters = /[^0-9]/g;

    // extract only numerical part and convert to integer for comparison
    const numericA = parseInt(houseA.replace(nonNumericCharacters, ""), 10);
    const numericB = parseInt(houseB.replace(nonNumericCharacters, ""), 10);

    // if no digits present or same number, do unicode string comparison
    if (
      (isNaN(numericA) && isNaN(numericB)) // e.g. parseInt("Farm") => NaN
      || numericA === numericB
    ) {
      const nonAlphabeticalCharacters = /[^a-zA-Z]/g;

      // extract alphabetical part of house number / name and convert to uppercase for comparison
      const alphabeticalA = houseA.replace(nonAlphabeticalCharacters, "").toUpperCase();
      const alphabeticalB = houseB.replace(nonAlphabeticalCharacters, "").toUpperCase();

      return alphabeticalA === alphabeticalB ? 0 : alphabeticalA > alphabeticalB ? 1 : -1;
    }
    // return non-numeric house names before numbers
    if (isNaN(numericA)) {
      return -1;
    }
    if (isNaN(numericB)) {
      return 1;
    }

    return numericA > numericB ? 1 : -1;
  };

  React.useEffect(() => {
    if (searchByPostcodeResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: searchByPostcodeResponse.errorMessage,
        duration: 5000
      });
    } else if (searchByPostcodeResponse.data && searchByPostcodeResponse.data.address) {
      const results = searchByPostcodeResponse.data.address;
      if (results.length === 0) {
        snackbar.show({
          type: "error",
          text: "No locations found. Please try a different postcode!",
          duration: 5000
        });
      } else {
        // show first 25 results from search
        setSearchResults(results.sort(sortAddresses).slice(0, 25));
        setSearchResultsVisible(true);
      }
    }
  }, [ searchByPostcodeResponse, setSearchResults, setSearchResultsVisible, snackbar ]);

  return (
    <SearchBarWrapper>
      <TextInput
        value={searchValue}
        onChangeText={val => {
          setSearchValue(val);
          if (!val) {
            setSearchResults([]);
          }
        }}
        clearButtonMode="always"
        placeholder="Search by postcode"
        returnKeyType="search"
        onSubmitEditing={searchByPostcode}
        onFocus={() => setSearchResultsVisible(true)}
        onBlur={() => setSearchResultsVisible(false)}
      />
      {!searchValue && (
        <SearchIcon />
      )}
    </SearchBarWrapper>
  );
};

export default PostcodeSearch;

const DismissKeyboardWrapper = styled.ScrollView.attrs({
  keyboardShouldPersistTaps: "handled",
  pointerEvents: "box-none",
  scrollEnabled: false
})`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 10;
`;

const SearchBarWrapper = styled.View`
  margin-top: 18px;
  margin-left: -2px;
  margin-right: -2px;
`;

const SearchIcon = styled(Icon).attrs({
  icon: "magnifying-glass",
  size: 18
})`
  position: absolute;
  top: 10px;
  right: 9px;
`;

const Results = styled.ScrollView`
  margin-top: 4px;
  height: 250px;
  background-color: white;
  border-radius: 2px;
`;

interface LocationSearchResultProps {
  location: LocationDTO;
  onPress: () => void;
}

const LocationSearchResult: React.FC<LocationSearchResultProps> = ({ location, onPress }) => (
  location.address1 && location.city ? (
    <ResultWrapper onPress={onPress}>
      <Text type="h3" ellipsizeMode="tail" numberOfLines={1}>
        {[
          toTitleCase(location.address1),
          toTitleCase(location.city),
          location.postCode
        ].join(", ")}
      </Text>
    </ResultWrapper>
  ) : null
);

const ResultWrapper = styled(TouchableHighlight)`
  height: 50px;
  padding: 0 12px;
  justify-content: center;
  border-bottom-width: 0.5px;
  border-color: rgba(0,0,0,0.05);
`;
