import _ from "lodash";
import {
  Text as RNText,
  TextProps as RNTextProps
} from "react-native";
import { CSSProperties } from "styled-components";

import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

export interface TextProps extends RNTextProps {
  align?: CSSProperties["textAlign"];
  weight?: keyof Theme["font"];
  size?: keyof Theme["text"];
  color?: keyof Theme["colors"] | keyof Theme["actions"]; // allow a colour or a action colour
  type?:
    | "h1"
    | "h2"
    | "h3"
    | "small"
    | "small"
    | "small-uppercase"
    | "placeholder"
    | "apple-black"
    | "apple-white"
    | "apple-transparent";
}

const Text = styled(RNText)<TextProps>`
  ${({ theme, color, align }) => css`
    text-align: ${align || "left"};
    color: ${color && _.has(theme.colors, color)
    ? theme.colors[ color as keyof Theme["colors"] ]
    : color && _.has(theme.actions, color)
      ? theme.actions[ color as keyof Theme["actions"] ]
      : theme.colors.fg};
  `}

  ${props => {
    const { theme, size, weight, color } = props;

    // get colours
    const colorProp =
      color && _.has(theme.colors, color)
        ? theme.colors[ color as keyof Theme["colors"] ]
        : color && _.has(theme.actions, color)
          ? theme.actions[ color as keyof Theme["actions"] ]
          : null;

    switch (props.type) {
    case "h1":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.extraLarge};
          font-family: ${weight ? theme.font[ weight ] : theme.font.bold};
        `;
    case "h2":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.large};
          font-family: ${weight ? theme.font[ weight ] : theme.font.bold};
        `;
    case "h3":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.medium};
          font-family: ${weight ? theme.font[ weight ] : theme.font.bold};
        `;
    case "small":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.small};
          font-family: ${weight ? theme.font[ weight ] : theme.font.regular};
        `;
    case "small-uppercase":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.small};
          font-family: ${weight ? theme.font[ weight ] : theme.font.bold};
          color: ${colorProp || theme.colors.neutralAccent};
          text-transform: uppercase;
        `;
    case "placeholder":
      return css`
          font-size: ${size ? theme.text[ size ] : theme.text.medium};
          font-family: ${weight ? theme.font[ weight ] : theme.font.regular};
          color: ${colorProp || theme.colors.neutralAccent};
        `;
    case "apple-black":
    case "apple-white":
    case "apple-transparent":
      return css`
        font-size: 19px;
        color: ${props.type === "apple-white" ? "black" : "white"};
        font-family: "System";
      `;
    default:
      return css`
          font-family: ${weight ? theme.font[ weight ] : theme.font.regular};
          font-size: ${size ? theme.text[ size ] : theme.text.medium};
        `;
    }
  }}
`;

export default Text;
