import { FormConfig } from "src/component-lib/src/components/form";

export interface UserDetailsFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptMarketing: boolean;
}

export const userDetailsFormConfig: FormConfig<UserDetailsFormData> = {
  name: {
    validation: {
      required: {
        value: true,
        message: "Name is required"
      }
    }
  },
  email: {
    validation: {
      required: {
        value: true,
        message: "Email is required"
      },
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email Format"
      }
    }
  },
  password: {
    validation: {
      required: {
        value: true,
        message: "Password is required"
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        message: "8 characters, 1 uppercase letter and 1 number required"
      }
    }
  },
  confirmPassword: {
    validation: {
      required: {
        value: true,
        message: "Password confirmation is required"
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        message: "8 characters, 1 uppercase letter and 1 number required"
      }
    }
  },
  acceptMarketing: {
    controlled: true
  }
};
