import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import { ItemDTO } from "src/component-lib/src/utils/api";

import ItemDetailsScreen from "src/screens/authorized/item-details/ItemDetails";
import ReturnItemConfirmationScreen
  from "src/screens/authorized/item-details/ReturnItemConfirmation";

import { CompositeNavigation } from "src/types/navigation";

import {
  AuthorizedStackCompositeNavigationProps,
  AuthorizedStackParamList
} from "./AuthorizedNavigator";

export type ItemDetailsStackParamList = {
  ItemDetailsScreen: { item: ItemDTO };
  // shows if found item returned
  ReturnItemConfirmationScreen: {
    item: ItemDTO;
  };
};

export type ItemDetailsStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<ItemDetailsStackParamList>,
  AuthorizedStackCompositeNavigationProps
>;

export type ItemDetailsStackScreenProps<
  T extends keyof ItemDetailsStackParamList
> = CompositeNavigation<
  RouteProp<ItemDetailsStackParamList, T>,
  ItemDetailsStackCompositeNavigationProps
>;

type ItemDetailsStackNavigationProps = CompositeNavigation<
  RouteProp<AuthorizedStackParamList, "ItemDetailsStack">,
  ItemDetailsStackCompositeNavigationProps
>;

const ItemDetailsStack = createStackNavigator<ItemDetailsStackParamList>();

const ItemDetailsStackNavigator: React.FC<ItemDetailsStackNavigationProps> = () => (
  <ItemDetailsStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>
    <ItemDetailsStack.Screen name="ItemDetailsScreen" component={ItemDetailsScreen} />
    <ItemDetailsStack.Screen name="ReturnItemConfirmationScreen" component={ReturnItemConfirmationScreen} />
  </ItemDetailsStack.Navigator>
);

export default ItemDetailsStackNavigator;
