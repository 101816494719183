import React from "react";

import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import { View } from "react-native";

import {
  Button,
  ButtonLink,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  FormConfig,
  TextInput
} from "src/component-lib/src/components/form";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { BackgroundView } from "src/component-lib/src/components/view";
import { useLogin } from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import styled from "src/component-lib/src/utils/styled-components";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

interface LoginDetailsFormData {
  email: string;
  password: string;
}

const loginFormConfig: FormConfig<LoginDetailsFormData> = {
  email: {
    validation: {
      required: {
        value: true,
        message: "Email is required"
      },
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email Format"
      }
    }
  },
  password: {
    validation: {
      required: {
        value: true,
        message: "Password is required"
      }
    }
  }
};

type LoginDetailsScreenProps = UnauthorizedScreenProps<"Login">;

const LoginDetails: React.FC<LoginDetailsScreenProps> = ({ navigation }) => {
  const snackbar = useSnackbarContext();

  const { handleSubmit, getValues, ...formControls } = useForm<LoginDetailsFormData>();

  const login = useLogin();

  /**
   * @param data Submit the form
   */
  const submitForm: SubmitHandler<LoginDetailsFormData> = React.useCallback(
    async data => {
      try {
        await login(data.email, data.password, true);
      } catch (error) {
        snackbar.show({
          type: "error",
          text: error instanceof Error ? error.message : "An unknown error occurred.",
          duration: 5000
        });
      }
    },
    [ snackbar, login ]
  );

  const goToForgotPasswordScreen = React.useCallback(() => {
    const { email } = getValues();
    navigation.navigate("ForgotPassword", { email });
  }, [ getValues, navigation ]);

  return (
    <FullScreenLayout
      title="Your details"
      HeaderLeft={() => (
        <IconButton
          color="white"
          icon="chevron-left"
          onPress={() => navigation.goBack()}
          size={35}
        />
      )}
      HeaderBackgroundComponent={props => (
        <BackgroundView {...props} gradient="business" />
      )}
      scrollable
    >
      <Form config={loginFormConfig} validateOnBlur {...formControls}>
        <TextInput
          name="email"
          label="Email"
          autoCapitalize="none"
          autoCorrect={false}
          textContentType="emailAddress"
          keyboardType="email-address"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="password"
          label="Password"
          textContentType="password"
          secureTextEntry
          onSubmitEditing={handleSubmit(submitForm)}
          returnKeyType="go"
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Log in"
          style={{ marginTop: 12 }}
        />
        <CenterView>
          <ButtonLink
            color="red"
            onPress={goToForgotPasswordScreen}
            label="Forgot Password?"
          />
        </CenterView>
      </Form>
    </FullScreenLayout>
  );
};

const CenterView = styled.View`
  flex: 1;
  align-items: center;
  margin-top: 12px;
`;

export default LoginDetails;
