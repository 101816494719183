import React from "react";

import { useNavigation } from "@react-navigation/native";

import { Icon } from "src/component-lib/src/components/icon";
import styled from "src/component-lib/src/utils/styled-components";

const RegisterItemButton: React.FC = () => {
  const navigation = useNavigation();

  const toRegisterItemScreen = () => navigation.navigate("FoundItemStack", {
    screen: "FoundItemScreen",
    params: undefined
  });

  return (
    <ButtonWrapper>
      <Button onPress={toRegisterItemScreen}>
        <ButtonContent>
          <RegisterItemIcon
            icon="ios-add"
            color="neutral"
            size={40}
          />
          <RegisterItemText>Register Item</RegisterItemText>
        </ButtonContent>
      </Button>
    </ButtonWrapper>
  );
};

export default RegisterItemButton;

const ButtonWrapper = styled.View`
  align-self: stretch;
  flex: 1;
`;

const Button = styled.TouchableWithoutFeedback`
  height: 100%;
`;

const ButtonContent = styled.View`
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex: 1;
`;

const RegisterItemText = styled.Text`
  font-size: 11px;
  color: #8e8e8f;
  margin-bottom: 1.5px;
`;

const RegisterItemIcon = styled(Icon)`
  flex: 1;
  margin-top: -1px;
`;
