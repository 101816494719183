import React from "react";

import { Text } from "../text";
import { BackgroundView } from "../view";
import styled, { css } from "../../utils/styled-components";
import {
  AppleStyle,
  NavigationBar,
  StatusBar
} from "react-native-scrollable-navigation-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface CollapsibleHeaderScrollLayoutProps {
  title?: string;
  collapsedTitle?: string;
  gradientType?: "public" | "business";
  height?: number;
  containerRef?: React.Ref<React.ComponentType<unknown>>;
  ScrollComponent?: React.ComponentType;
  HeaderForegroundComponent?: React.ComponentType;
  HeaderLeft?: React.ComponentType;
  HeaderRight?: React.ComponentType;
  noPadding?: boolean;
}

const CollapsibleHeaderScrollLayout: React.FC<CollapsibleHeaderScrollLayoutProps> = ({
  title,
  collapsedTitle,
  gradientType = "public",
  height = 200,
  containerRef,
  ScrollComponent,
  HeaderForegroundComponent,
  HeaderLeft,
  HeaderRight,
  noPadding,
  children
}) => {
  const { top } = useSafeAreaInsets();

  return (
  // <NavigationWrapper>
  //   {children}
  // </NavigationWrapper>

    // TODO: rewrite this using a non-stupid dependency
    <StyledStickyHeaderWrapper
      headerHeight={height}
      title={title && !collapsedTitle ? title : collapsedTitle}
      headerTitle={title}
      headerBackgroundColor={"transparent"}

      // NavigationBarWrapper={React.useCallback<React.FC>(({ children }) => (
      //   <NavigationWrapper safeArea={top} gradient={gradientType}>
      //     {children}
      //   </NavigationWrapper>
      // ), [ gradientType, top ])}
      HeaderForegroundComponent={
        HeaderForegroundComponent || (() => <HeaderTitleView title={title} />)
      }
      HeaderBackgroundComponent={React.useCallback<React.FC>(({ children }) => (
        <HeaderBackground safeArea={top} gradient={gradientType}>
          {children}
        </HeaderBackground>
      ), [ gradientType, top ])}
      StatusBarComponent={() => (
        <StatusBar
          // barStyle="light-content"
          backgroundColor="transparent"
        />
      )}
      leftIcons={HeaderLeft && [ <HeaderLeft /> ]}
      rightIcons={HeaderRight && [ <HeaderRight /> ]}

      // containerRef={containerRef}
      // ScrollComponent={ScrollComponent}
      keyboardShouldPersistTaps="handled"
    >
      <LayoutInner noPadding={noPadding}>{children}</LayoutInner>
    </StyledStickyHeaderWrapper>
  );
};

const LayoutInner = styled.View<
  Pick<CollapsibleHeaderScrollLayoutProps, "noPadding">
>`
  padding: ${({ noPadding }) => (noPadding ? 0 : 24)}px;
  ${({ theme }) => css`
    background-color: ${theme.colors.bg};
  `}
`;

const StyledStickyHeaderWrapper = styled(AppleStyle).attrs(
  ({ theme }) => ({
    titleStyle: {
      color: "white",
      fontFamily: theme.font.bold
    }
  })
)`
  ${({ theme }) => css`
    background-color: ${theme.colors.bg};
  `}
`;

const NavigationWrapper = styled(BackgroundView)<{ safeArea?: number }>`
  height: ${({ safeArea }) => 56 + (safeArea || 0)}px;
`;

const HeaderTitleWrapper = styled.View`
  flex: 1;
  justify-content: flex-end;
  padding-left: 20px;
  padding-bottom: 12px;
`;

const HeaderTitleView: React.FC<{ title?: string }> = ({ title }) =>
  title ? (
    <HeaderTitleWrapper>
      <Text type="h1" color="white">
        {title}
      </Text>
    </HeaderTitleWrapper>
  ) : null;

const HeaderBackground = styled(NavigationWrapper)`
  flex: 1;
  margin-top: -400px;
`;

export default CollapsibleHeaderScrollLayout;
