import React from "react";

import { View } from "react-native";
import { Region } from "react-native-maps";

import { TextInput } from "src/component-lib/src/components/form";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  LocationDTO,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import { toTitleCase } from "src/utils/strings/title-case";

interface FloatingMapSearchProps {
  setRegion: React.Dispatch<React.SetStateAction<Region | undefined>>;
  offset?: number;
}

const FloatingMapSearch: React.FC<FloatingMapSearchProps> = ({ setRegion, offset = 120 }) => {
  const snackbar = useSnackbarContext();

  const [ searchValue, setSearchValue ] = React.useState("");

  const [ searchByPostcodeResponse, searchByPostcodeRequest ] = useApiRequest("LOCATIONS:getAddress");

  const searchByPostcode = () => {
    if (searchValue) {
      searchByPostcodeRequest({
        data: { postcode: searchValue }
      });
    }
  };

  const [ searchResults, setSearchResults ] = React.useState<LocationDTO[]>([]);

  const [ searchResultsVisible, setSearchResultsVisible ] = React.useState(false);

  const setMapRegion = React.useCallback(({ latitude, longitude }: LocationDTO) => {
    if (latitude && longitude) {
      setSearchResultsVisible(false);
      setRegion({
        latitude,
        longitude,
        latitudeDelta: 0.005,
        longitudeDelta: 0.005
      });
    }
  }, [ setRegion ]);

  React.useEffect(() => {
    if (searchByPostcodeResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: searchByPostcodeResponse.errorMessage,
        duration: 5000
      });
    } else if (searchByPostcodeResponse.data && searchByPostcodeResponse.data.address) {
      const results = searchByPostcodeResponse.data.address;
      if (results.length === 0) {
        snackbar.show({
          type: "error",
          text: "No locations found. Please try a different postcode!",
          duration: 5000
        });
      } else {
        // // show first 25 results from search
        // setSearchResults(results.slice(0, 25));
        // setSearchResultsVisible(true);

        // postcode search is terrible and returns the same lat long for every result
        // until this is replaced with proper address auto complete
        // skip showing results and just set map to lat/long of first result
        setMapRegion(results[ 0 ]);
      }
    }
  }, [ searchByPostcodeResponse, setMapRegion, snackbar ]);

  return (
    <DismissKeyboardWrapper pointerEvents="box-none">
      <SearchBarWrapper offset={offset}>
        <TextInput
          value={searchValue}
          onChangeText={val => {
            setSearchValue(val);
            if (!val) {
              setSearchResults([]);
            }
          }}
          clearButtonMode="while-editing"
          placeholder="Search by postcode"
          returnKeyType="search"
          onSubmitEditing={searchByPostcode}
          onFocus={() => setSearchResultsVisible(true)}
          onBlur={() => setSearchResultsVisible(false)}
        />
        {searchResultsVisible && searchResults.length > 0 && (
          <View>
            <ResultsWrapper>
              {searchResults.map((location, i) => (
                <LocationSearchResult
                  key={i}
                  location={location}
                  onPress={() => setMapRegion(location)}
                />
              ))}
            </ResultsWrapper>
          </View>
        )}
      </SearchBarWrapper>
    </DismissKeyboardWrapper>
  );
};

export default FloatingMapSearch;

const DismissKeyboardWrapper = styled.ScrollView.attrs({
  keyboardShouldPersistTaps: "handled",
  scrollEnabled: false
})`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 10;
`;

const SearchBarWrapper = styled.View<{ offset: number }>`
  margin-top: ${({ offset }) => offset}px;
  margin-left: 24px;
  margin-right: 24px;
`;

const ResultsWrapper = styled.ScrollView`
  margin-top: 4px;
  height: 250px;
  background-color: white;
  border-radius: 2px;
`;

interface LocationSearchResultProps {
  location: LocationDTO;
  onPress: () => void;
}

const LocationSearchResult: React.FC<LocationSearchResultProps> = ({ location, onPress }) => (
  location.address1 && location.city ? (
    <ResultWrapper onPress={onPress}>
      <Text type="h3" ellipsizeMode="tail" numberOfLines={1}>
        {[
          toTitleCase(location.address1),
          toTitleCase(location.city),
          location.postCode
        ].join(", ")}
      </Text>
    </ResultWrapper>
  ) : null
);

const ResultWrapper = styled(TouchableHighlight)`
  height: 50px;
  padding: 0 12px;
  justify-content: center;
  border-bottom-width: 0.5px;
  border-color: rgba(0,0,0,0.05);
`;
