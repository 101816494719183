import React from "react";

import {
  Linking,
  Platform,
  View
} from "react-native";

import { Button } from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

import SFShapesImgSrc from "assets/images/business-sf-shapes.png";
import FoundLogoSrc from "assets/images/logo_transparent.png";

type VerificationEmailSentScreenProps = UnauthorizedScreenProps<"VerificationEmailSent">;

const VerificationEmailSent: React.FC<VerificationEmailSentScreenProps> = ({
  navigation,
  route
}) => {
  const snackbar = useSnackbarContext();

  const [ resendVerificationResponse, resendVerificationRequest ] = useApiRequest(
    "USERS:resendVerificationEmail"
  );

  React.useEffect(() => {
    if (resendVerificationResponse.data) {
      snackbar.show({
        "type": "success",duration: 4000, "text": resendVerificationResponse.data.message
      });
    }

    if (resendVerificationResponse.errorMessage) {
      snackbar.show({
        "type": "error",duration: 4000, "text": resendVerificationResponse.errorMessage
      });
    }
  }, [ resendVerificationResponse, snackbar ]);

  const resendVerificationEmail = React.useCallback((userUuid: string) => {

    resendVerificationRequest({
      pathParams: { userUuid }
    });

  }, [ resendVerificationRequest ]);

  const openEmailApp = React.useCallback(async () => {
    if (Platform.OS === "ios") {
      Linking.openURL("message:");
    } else {
      // TODO: open inbox after expo
      // import { openInbox } from 'react-native-email-link'
      // openInbox()
    }
  },[]);

  return (
    <FullScreenLayout
      HeaderRight={() => (
        <Button
          onPress={() => navigation.navigate("Login")}
          type="primary"
          label="Log in"
          darkModeAware={false}
          style={{ marginRight: 14 }}
        />
      )}
      BackgroundComponent={p => (
        <BackgroundView
          {...p}
          gradient="business"
          start={[ 0.25, 0.4 ]}
          end={[ 1.5, 0.75 ]}
        />
      )}
    >
      <FoundLogo resizeMode="contain" />
      <SirFoundalot />
      <Spacer />
      <StyledTextWrapper>
        <Text align="center" type="h2" color="white">
          Thanks for joining, we have sent you a verification email. Check your junk mail if you cannot find our message.
        </Text>
      </StyledTextWrapper>
      {Platform.OS === "ios" && (
        <ButtonWrapper>
          <Button
            label="Open Email"
            type="tertiary"
            onPress={openEmailApp}
          />
        </ButtonWrapper>
      )}
      {!!route.params && !!route.params.userUuid && (
        <ButtonWrapper>
          <Button
            label="Resend Email"
            type="tag"
            onPress={() => resendVerificationEmail(route.params.userUuid as string)}
          />
        </ButtonWrapper>
      )}
    </FullScreenLayout>
  );
};

const StyledTextWrapper = styled.View`
  width: 80%;
  align-self: center;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;
`;

const ButtonWrapper = styled.View`
  margin-bottom: 20px;
`;

const FoundLogo = styled(Image).attrs({
  source: FoundLogoSrc,
  defaultSource: FoundLogoSrc
})`
  width: 200px;
  height: 120px;
  align-self: center;
  margin-bottom: 20px;
`;

const SirFoundalot = styled(Image).attrs({
  source: SFShapesImgSrc,
  defaultSource: SFShapesImgSrc,
  resizeMode: "contain"
})`
  width: 400px;
  height: 200px;
  align-self: center;
`;

const Spacer = styled.View`
  flex: 1;
`;

export default VerificationEmailSent;
