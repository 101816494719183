import React from "react";

import { View } from "react-native";

import { Button } from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import styled from "src/component-lib/src/utils/styled-components";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

import ShapesImgSrc from "assets/images/business-shapes.png";
import FoundLogoSrc from "assets/images/logo_transparent.png";
import SFCameraImgSrc from "assets/images/sf-camera.png";
import SFDogImgSrc from "assets/images/sf-dog.png";
import SFTorchSrc from "assets/images/sf-torch.png";
import SFImgSrc from "assets/images/sir-foundalot.png";
import Swiper from "react-native-web-swiper";

type IntroScreenProps = UnauthorizedScreenProps<"Intro">;

const IntroScreen: React.FC<IntroScreenProps> = ({ navigation }) => (
  <FullScreenLayout
    BackgroundComponent={p => (
      <BackgroundView
        {...p}
        gradient="business"
        start={[ 0.25, 0.4 ]}
        end={[ 1.5, 0.75 ]}
      />
    )}
  >
    <Container>
      <FoundLogo resizeMode="contain" />
      <SwiperWrapper>
        <ShapesImage resizeMode="contain" source={ShapesImgSrc} defaultSource={ShapesImgSrc} />
        <StyledSwiper controlsProps={{
          dotsTouchable: true,
          prevTitle: "",
          nextTitle: ""
        }}>
          <SlideView>
            <SFImage source={SFImgSrc} defaultSource={SFImgSrc} />
            <StyledText color="white" align="center">
              Found is an easy to use app which saves your business time and money in managing your lost and found property.
            </StyledText>
          </SlideView>

          <SlideView>
            <SFCameraImage source={SFCameraImgSrc} defaultSource={SFCameraImgSrc} />
            <StyledText color="white" align="center">
              You can report a found item in just three clicks.
              This report is stored in our centralised database of lost property.
            </StyledText>
          </SlideView>

          <SlideView>
            <SFTorchImage source={SFTorchSrc} defaultSource={SFTorchSrc} />
            <StyledText color="white" align="center">
              When a match is made with someone who has reported a loss, our matching tech connects you with the owner.
            </StyledText>
          </SlideView>

          <SlideView>
            <SFDogImage source={SFDogImgSrc} defaultSource={SFDogImgSrc} />
            <StyledText color="white" align="center">
              We can help free up your storage space by connecting with your chosen charity and donating any unclaimed items to a good cause.
            </StyledText>
          </SlideView>
        </StyledSwiper>
      </SwiperWrapper>

      <View>
        <ButtonWrapper>
          <Button
            onPress={() => navigation.navigate("Login")}
            label="Log in"
            type="primary"
            darkModeAware={false}
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <Button
            onPress={() => navigation.navigate("UserDetails")}
            label="Sign up"
            type="primary-fill"
            darkModeAware={false}
          />
        </ButtonWrapper>
      </View>
    </Container>
  </FullScreenLayout>
);

const Container = styled.View`
  flex: 1;
  justify-content: space-between;
  padding-top: 36px;
`;

const StyledText = styled(Text)`
  width: 90%;
  align-self: center;
  text-align: center;
  margin-bottom: 48px;
`;

const ButtonWrapper = styled.View`
  margin-bottom: 20px;
`;

const FoundLogo = styled(Image).attrs({
  source: FoundLogoSrc,
  defaultSource: FoundLogoSrc
})`
  width: 200px;
  height: 72px;
  align-self: center;
  margin-top: 18px;
  margin-bottom: 18px;
`;

const SwiperWrapper = styled.View`
  margin-top: 20px;
  height: 360px;
  position: relative;
  display: grid;
  /* align-items: center; */
`;

const ShapesImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 0;
  grid-column: 1;
  grid-row: 1;
`;

const StyledSwiper = styled(Swiper)`
  grid-column: 1;
  grid-row: 1;
`;

const SFImage = styled(Image)`
  width: 200px;
  height: 200px;
  align-self: center;
  margin: 6px 0;
`;

const SFCameraImage = styled(Image)`
  width: 190px;
  height: 190px;
  align-self: center;
  margin-top: 12px;
  margin-bottom: 10px;
`;

const SFDogImage = styled(Image)`
  width: 182px;
  height: 182px;
  align-self: center;
  margin-top: 12px;
  margin-bottom: 18px;
`;

const SFTorchImage = styled(Image)`
  width: 250px;
  height: 220px;
  align-self: center;
  margin-top: -4px;
  margin-left: 42px;
  margin-bottom: -4px;
`;

const SlideView = styled(View)`
  flex: 1;
  align-items: 'center';
  justify-content: 'center';
  width: 100%;
`;

export default IntroScreen;
