import React from "react";

import { ViewStyle } from "react-native";

import { Text } from "src/component-lib/src/components/text";
import styled, { css } from "src/component-lib/src/utils/styled-components";

export interface ItemDetailRowProps {
  label: string;
  value: string | null;
  valueColor?: "red" | "blue" | "green";
}

export const ItemDetailRow: React.FC<ItemDetailRowProps> = ({
  label,
  value,
  valueColor
}) => (
  <ItemRowWrapper>
    <ItemLabel>{label}</ItemLabel>
    <ItemValue weight="bold" color={valueColor}>{value}</ItemValue>
  </ItemRowWrapper>
);

interface ItemLongDetailRowProps {
  label: string;
  value: string | null;
  style?: ViewStyle;
}

export const ItemLongDetailRow: React.FC<ItemLongDetailRowProps> = ({ label, value, style }) => (
  <ItemDescriptionWrapper style={style}>
    <ItemLabel style={{ marginBottom: 12 }}>{label}</ItemLabel>
    <Text weight="bold">{value}</Text>
  </ItemDescriptionWrapper>
);

interface ItemCustomDetailRowProps {
  label: string;
  style?: ViewStyle;
}

export const ItemCustomDetailRow: React.FC<ItemCustomDetailRowProps> = ({ label, style, children }) => (
  <ItemDescriptionWrapper style={style}>
    <ItemLabel style={{ marginBottom: 12 }}>{label}</ItemLabel>
    { children }
  </ItemDescriptionWrapper>
);

const ItemRowWrapper = styled.View`
  ${({ theme }) => css`
    flex-direction: row;
    align-content: space-between;
    padding: 20px;
    margin-top: 20px;
    background-color: ${theme.colors.bgAccent};
  `}
`;

const ItemLabel = styled(Text)`
  margin-right: auto;
  text-transform: capitalize;
`;

const ItemValue = styled(Text)`
  margin-left: auto;
  text-align: right;
`;

const ItemDescriptionWrapper = styled(ItemRowWrapper)`
  flex-direction: column;
`;
