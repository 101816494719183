import { FormConfig } from "src/component-lib/src/components/form";

export enum BusinessTypeEnum {
  AIRBNB = "Airbnb",
  AIRPORT = "Airport",
  GALLERY = "Art Gallery",
  BAR = "Bar",
  BUS = "Bus",
  CAFE = "Café",
  EVENT = "Event Space",
  FERRY = "Ferry",
  GARDEN = "Garden Centre",
  GOVERNMENT = "Government Venue",
  HOTEL = "Hotel",
  LIBRARY = "Library",
  MUSEUM = "Museum",
  NIGHTCLUB = "Nightclub",
  PUBLIC = "Public Venue",
  RESTAURANT = "Restaurant",
  SCHOOL = "School",
  SPORTS = "Sports Centre",
  STADIUM = "Sports Stadium",
  TAXI = "Taxi",
  TRAIN = "Train",
  UNIVERSITY = "University",
  OTHER = "Other"
}

interface BusinessTypeSelectOption {
  label: BusinessTypeEnum;
  value: BusinessTypeEnum;
}

export const businessTypeOptions: BusinessTypeSelectOption[] = Object.values(BusinessTypeEnum).map(businessType => ({
  label: businessType,
  value: businessType
}));

export interface BusinessDetailsFormData {
  businessName: string;
  website: string;
  businessType: BusinessTypeSelectOption;
}

export const businessDetailsFormConfig: FormConfig<BusinessDetailsFormData> = {
  businessName: {
    validation: {
      required: {
        value: true,
        message: "Business name is required"
      }
    }
  },
  website: {
    validation: {
      required: {
        value: true,
        message: "Website is required"
      },
      pattern: {
        value: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        message: "Invalid URL"
      }
    }
  },
  businessType: {
    controlled: true,
    validation: {
      required: {
        value: true,
        message: "Business type is required"
      }
    }
  }
};
