import { DefaultTheme } from "styled-components/native";

// mostly copied from FFX, need to populate with actual styles
interface ActionPalette {
  info: ColorPalette | string;
  success: ColorPalette | string;
  warning: ColorPalette | string;
  error: ColorPalette | string;
}

export interface Theme extends DefaultTheme {
  isDark?: boolean;
  font: {
    regular: string;
    bold: string;
  };
  text: {
    small: string;
    medium: string;
    large: string;
    extraLarge: string;
  };
  colors: {
    // color - Color in the palette (e.g. bg, fg)
    // colorAccent - Darker/Lighter/Saturated version of the color (e.g. bgAccent, fgAccent)
    bg: string;
    bgAccent: string;
    fg: string;
    fgAccent: string;
    neutral: string;
    neutralAccent: string;
    green: string;
    blue: string;
    orange: string;
    red: string;
    pink: string;
    purple: string;
    white: string;
    black: string;
  };
  gradients: {
    public: string[];
    business: string[];
  };
  actions: ActionPalette;
  borderRadius: {
    default: string;
    medium: string;
    large: string;
    extraLarge: string;
  };
  shadows: {
    default: string;
  };
}

enum FontSizes {
  small = "12px",
  medium = "16px",
  large = "18px",
  extraLarge = "26px"
}

enum ColorPalette {
  black = "#000000",
  offBlack = "#2f2f2f",
  darkGrey = "#4a4a4a",
  midGrey = "#9b9b9b",
  lightGrey = "#a5a5a5",
  offWhite = "#f4f4f4",
  white = "#ffffff",
  blue = "#349dc7",
  green = "#2aaf9e",
  purple = "#7800be",
  pink = "#be005d",
  red = "#e74e68",
  orange = "#ff8901"
}

enum BorderRadius {
  default = "10px",
  medium = "14px",
  large = "18px",
  extraLarge = "24px"
}

const baseTheme = {
  font: {
    regular: "nunito-regular",
    bold: "nunito-bold"
  },
  text: {
    small: FontSizes.small,
    medium: FontSizes.medium,
    large: FontSizes.large,
    extraLarge: FontSizes.extraLarge
  },
  actions: {
    error: ColorPalette.red,
    warning: ColorPalette.orange,
    info: ColorPalette.blue,
    success: ColorPalette.green
  },
  colors: {
    green: ColorPalette.green,
    blue: ColorPalette.blue,
    orange: ColorPalette.orange,
    red: ColorPalette.red,
    pink: ColorPalette.pink,
    purple: ColorPalette.purple,
    white: ColorPalette.white,
    black: ColorPalette.black,
    light: ColorPalette.offWhite,
    dark: ColorPalette.darkGrey,
    neutral: ColorPalette.midGrey,
    neutralAccent: ColorPalette.lightGrey
  },
  gradients: {
    public: [ ColorPalette.purple, ColorPalette.blue ],
    business: [ ColorPalette.purple, ColorPalette.pink ]
  },
  borderRadius: {
    default: BorderRadius.default,
    medium: BorderRadius.medium,
    large: BorderRadius.large,
    extraLarge: BorderRadius.extraLarge
  }
};

const darkTheme: Theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    bg: ColorPalette.darkGrey,
    bgAccent: ColorPalette.offBlack,
    fg: ColorPalette.offWhite,
    fgAccent: ColorPalette.white
  },
  shadows: {
    default: "rgba(0, 0, 0, 0.4)"
  },
  isDark: true
};

const lightTheme: Theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    bg: ColorPalette.offWhite,
    bgAccent: ColorPalette.white,
    fg: ColorPalette.darkGrey,
    fgAccent: ColorPalette.offBlack
  },
  shadows: {
    default: "rgba(0, 0, 0, 0.1)"
  },
  isDark: false
};

export interface DynamicTheme {
  light: Theme;
  dark: Theme;
}

const theme: DynamicTheme = {
  light: lightTheme,
  dark: darkTheme
};

export default theme;
