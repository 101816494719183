import React, { useState, useEffect, ReactPropTypes } from "react";

import { StyleProp, ViewStyle } from "react-native";
// import DatePicker from "react-datepicker";
import styled, { css } from "src/component-lib/src/utils/styled-components";
// import { Text } from "../../text";
import dayjs from "dayjs";
// import { Label } from "../label";

// import "./react-datepicker/style.css";
// import Select from "../select/Select.web";
// import Datetime from "react-datetime";
import { Picker } from "@react-native-picker/picker";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(localeData);

//TODO: write this component properly
interface DateTimeInputProps {
  date: Date | null;
  onChange: (date: Date) => void;
  label?: string;
  minimumDate?: Date;
  maximumDate?: Date;
  mode?: "date" | "datetime" | "time";
  style?: StyleProp<ViewStyle>;
}

const monthsInYear = [...Array(12).keys()];
const hours = [12].concat([...Array(11).keys()].map(n => ++n));
const minutes = [...Array(12).keys()].map(n => n * 5);

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  date,
  onChange,
  label = "Select",
  minimumDate,
  maximumDate = new Date(),
  mode = "datetime",
  style
}) => {

  // const minMaxFormats: { [key in typeof mode]: string } = {
  //   date: "YYYY-MM-DD",
  //   datetime: "YYYY-MM-DDTHH:mm",
  //   time: "HH:mm"
  // };

  // const min = minimumDate && dayjs(minimumDate).format(minMaxFormats[mode]);
  // const max = dayjs(maximumDate).format(minMaxFormats[mode]);

  // const [now, setNow] = React.useState(new Date());

  const years = [...Array(50).keys()].map(n => maximumDate.getFullYear() - n);

  const dayjsDate = dayjs(date || maximumDate);
  const [selectedDate, setSelectedDate] = useState(dayjsDate);
  const [daysInMonth, setDaysInMonth] = useState<number[]>([]);
  const [hour, setHour] = useState<number>(dayjsDate.get("hour") - (dayjsDate.get("hour") > 12 ? 12 : 0));
  const [amPmOffset, setAmPmOffset] = useState<12 | 0>(dayjsDate.get("hour") > 12 ? 12 : 0);

  const onDatetimeChange = (unit: dayjs.UnitType, value: number) => {
    const newDate = selectedDate.set(unit, value);
    setSelectedDate(newDate);
  }

  useEffect(() => {
    /*
      is hour < 12?
      yes
      for AM: +0
      for PM: +12

      is hour < 12?
      no (it is 12)
      for AM: -12
      for PM: +0
    */
    const new24Hour = hour < 12
      ? hour + amPmOffset
      : hour + amPmOffset -12;

    onDatetimeChange("hour", new24Hour);
  }, [amPmOffset, hour]);

  useEffect(() => {
    const days = [...Array(selectedDate.daysInMonth()).keys()].map(d => ++d);
    setDaysInMonth(days);
    onChange(selectedDate.toDate());
  }, [selectedDate]);

  // useEffect(() => {
  //   setSelectedDate(dayjs(date));
  // }, [date])

  // const dayItems = daysInMonth.map(d => ({label: d.toString(), value: d.toString()}));

  // const isValidDate = (currentDate) => {
  //   const now = dayjs();
  //   dayjs(currentDate).isBefore(now);
  // }

  const [
    atMaxYear,
    atMaxMonth,
    atMaxDay,
    atMaxHour,
  ] = [
    selectedDate.year() === maximumDate.getFullYear(),
    selectedDate.month() === maximumDate.getMonth(),
    selectedDate.date() === maximumDate.getDate(),
    selectedDate.hour() === maximumDate.getHours(), // needs AM/PM thing
  ];

  return (<Wrapper style={style}>
    {/* <Label text={label}/> */}
    {/* <Datetime isValidDate={isValidDate} />; */}
    <StyledPicker
      onValueChange={(value) => onDatetimeChange("date", value as number)}
      selectedValue={dayjsDate.get("date")}
    >
      {daysInMonth
      .slice(0, (atMaxYear && atMaxMonth)
        ? maximumDate.getDate()
        : daysInMonth.length)
      .map((d) => (<Picker.Item
        key={`day${d}`}
        label={d.toString()}
        value={d}
      />))}
    </StyledPicker>
    <StyledPicker
      onValueChange={(value) => onDatetimeChange("month", value as number)}
      selectedValue={dayjsDate.get("month")}
    >
      {monthsInYear
      .slice(0, atMaxYear ? maximumDate.getMonth() + 1 : 12)
      .map((m) => (<Picker.Item
        key={`month${m}`}
        label={dayjs.months()[m]}
        value={m}
      />))}
    </StyledPicker>
    <StyledPicker
      onValueChange={(value) => onDatetimeChange("year", value as number)}
      selectedValue={dayjsDate.get("year")}
    >
      {years.map((y) => (<Picker.Item
        key={`year${y}`}
        label={y.toString()}
        value={y}
      />))}
    </StyledPicker>
    <DateTimeSpacer/>
    <StyledPicker
      onValueChange={(value) => setHour(Number.parseInt(`${value}`, 10))}
      selectedValue={hour}
    >
      {hours
      .slice(0, (atMaxYear && atMaxMonth && atMaxDay)
        ? (maximumDate.getHours() - amPmOffset) + 1
        : hours.length)
      .map((h) => (<Picker.Item
        key={`hour${h}`}
        label={h.toString().padStart(2, "0")}
        value={h}
      />))}
    </StyledPicker>
    <StyledPicker
      onValueChange={(value) => onDatetimeChange("minute", value as number)}
      selectedValue={Math.floor(dayjsDate.get("minute") / 5) * 5}
    >
      {minutes
      .slice(0, (atMaxYear && atMaxMonth && atMaxDay && atMaxHour)
        ? (maximumDate.getMinutes() / 5) + 1
        : 12)
      .map((min) => (<Picker.Item
        key={`minute${min}`}
        label={min.toString().padStart(2, "0")}
        value={min}
      />))}
    </StyledPicker>
    <StyledPicker
      onValueChange={(value) => {
        const newOffset = Number.parseInt(`${value}`, 10);
        newOffset === 0 || newOffset === 12 ? setAmPmOffset(newOffset) : undefined
      }}
      selectedValue={amPmOffset}
    >
      <Picker.Item
        key="AM"
        label={"AM"}
        value={0}
      />
      <Picker.Item
        key="PM"
        label={"PM"}
        value={12}
      />
    </StyledPicker>
    {/* <Select
      selectedValue={dayItems[selectedDate.date() - 1]}
      onValueChange={({value}) => onDatetimeChange("day", value)}
      label={"Day"}
      items={dayItems} /> */}
    {/* <Select onValueChange={({value}) => onDatetimeChange("month", value)} label={"Month"} items={[{label: "1", value: "1"}]} /> */}
    {/* <Select onValueChange={({value}) => onDatetimeChange("year", value)} label={"Year"} items={[{label: "1", value: "1"}]} /> */}
    {/* <input
      type={mode === "datetime" ? "datetime-local" : mode}
      max={max}
      onChange={(e) => onChange(dayjs(e.target.value).toDate())}
    /> */}
    {/* <DatePicker
      showTimeSelect
      // showTimeInput={mode === "datetime"}
      selected={date}
      onChange={onChange}
      timeInputLabel="Time:"
      inline
      // maxDate={maximumDate}
      // minDate={minimumDate}
      minTime={minimumDate}
      maxTime={maximumDate}
    /> */}
  </Wrapper>);
}

const Wrapper = styled.View`
  ${({ theme }) => css`
    font-family: ${theme.font.regular};
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border-width: 1px;
    border-color: ${theme.colors.neutralAccent};
    overflow: hidden;
  `}
`;

const StyledPicker = styled(Picker)`
  ${({ theme }) => css`
    padding: 10px;
    font-family: ${theme.font.regular};
    font-size: ${theme.text.medium};
    background-color: ${theme.colors.bgAccent};
    border: none;
    flex: 1;
  `}
`;

const CustomisableInput: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
  return (<input className={props.className}></input>);
}

const TimeInput = styled(CustomisableInput).attrs(({ theme }) => ({
  style: {
    fontFamily: theme.font.regular,
    backgroundColour: "seagreen"
  }
}))``;

const DateTimeSpacer = styled.View`
  ${({ theme }) => css`
    flex: 0.5;
    background-color: ${theme.colors.bgAccent};
  `}
`;

export default DateTimeInput;

declare global {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  let _dateTimeInput: typeof DateTimeInput;
}
