import { AvailableParams } from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const FileRoutes = {
  "FILES:getFile": {
    path: "/images/file-uuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    { file: string },
    null,
    Pick<AvailableParams, "fileUuid">
  >
};

export type FileRouteType = typeof FileRoutes;

export default FileRoutes;
