import * as React from "react";

import * as Location from "expo-location";

import { LocationDTO } from "src/component-lib/src/utils/api";

import { toTitleCase } from "src/utils/strings/title-case";

const useFormattedAddressString = (location?: LocationDTO): string => {
  const [ formattedAddress, setFormattedAddress ] = React.useState<string>("Loading...");

  React.useEffect(() => {
    console.log("in effect");
    console.log(location);
    if (location) {
      console.log("location defined");
      if (location.address1 && location.city && location.postCode) {
        console.log("address1 city postCode defined");
        setFormattedAddress(`${toTitleCase(location.address1)}, ${toTitleCase(location.city)}, ${location.postCode}`);
      } else {
        console.log("address1 city postCode undefined");
        if (location.latitude && location.longitude) {
          console.log("address1 latitude longitude defined");
          Location.reverseGeocodeAsync({
            longitude: location.longitude,
            latitude: location.latitude
          }).then(addresses => {
            if (addresses.length) {
              const { name: address1, city, postalCode: postCode } = addresses[ 0 ];

              const address = [];
              address1 && address.push(toTitleCase(address1));
              city && address.push(toTitleCase(city));
              postCode && address.push(postCode);

              setFormattedAddress(address.join(", "));
            } else {
              setFormattedAddress("Dropped pin");
            }
          }).catch(() => {
            setFormattedAddress("Dropped pin");
          });
        }
      }
    } else {
      setFormattedAddress("Choose location");
    }
  }, [ location ]);

  return formattedAddress;
};

export default useFormattedAddressString;
