import React from "react";

import styled from "../../../utils/styled-components";

const FormRow: React.SFC = styled.View`
  margin-bottom: 20px;
  position: relative;
`;

export default FormRow;
