import * as React from "react";

import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import { Platform } from "react-native";

import {
  Modal,
  ModalProps
} from "src/component-lib/src/components/modal";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";

const defaultPickerOptions: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images
};

interface ImagePickerModalProps extends Partial<ModalProps> {
  onClose: ModalProps["onClose"];
  pickerOptions?: ImagePicker.ImagePickerOptions;
  onImagePickSuccess: (result: ImagePicker.ImagePickerResult) => void;
  onImagePickError?: (error: any) => void;
}

const ImagePickerModal: React.FC<ImagePickerModalProps> = ({
  title,
  tip,
  isVisible,
  onClose,
  pickerOptions = defaultPickerOptions,
  onImagePickSuccess,
  onImagePickError = e => {
    console.log(e);
  }
}) => {
  const snackbar = useSnackbarContext();

  /**
   * Ask user for camera roll permissions
   */
  const getPermissionAsync = React.useCallback(async () => {
    if (Platform.OS === "ios") {
      const { status } = await Permissions.askAsync(
        Permissions.CAMERA,
        Permissions.CAMERA_ROLL
      );

      if (status !== "granted") {
        snackbar.show({
          type: "error",
          text:
            "Sorry, we require the correct permissions to perform this action!",
          duration: 5000
        });
      }
    }
  }, [ snackbar ]);

  /**
   * Select the image to use
   */
  const pickImage = React.useCallback(
    async (cameraOrRoll?: "camera" | "roll") => {
      try {
        await getPermissionAsync();

        const result =
          cameraOrRoll === "camera"
            ? await ImagePicker.launchCameraAsync(pickerOptions)
            : await ImagePicker.launchImageLibraryAsync(pickerOptions);

        if (!result.cancelled) {
          const smallerDimension = result.height > result.width ? "width" : "height";

          // scale down if smallest dimension < 1200
          const maybeScaleDown = result[ smallerDimension ] > 1200
            ? [ { resize: { [ smallerDimension ]: 1200 } } ]
            : undefined;

          const optimisedResult = await ImageManipulator.manipulateAsync(
            result.uri,
            maybeScaleDown,
            { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
          );

          // add cancelled property to conform to ImagePickerResult type
          onImagePickSuccess({
            cancelled: false,
            ...optimisedResult
          });
        }
      } catch (e) {
        onImagePickError(e);
      }
    },
    [ getPermissionAsync, pickerOptions, onImagePickSuccess, onImagePickError ]
  );

  return (
    <Modal
      title={title || "Select image from:"}
      tip={tip}
      isVisible={isVisible}
      onClose={onClose}
      buttons={{
        confirm: {
          type: "secondary",
          label: "Camera",
          onPress: () => pickImage("camera"),
          style: { width: "100%" }
        },
        reject: {
          type: "secondary",
          label: "Camera Roll",
          onPress: () => pickImage("roll"),
          style: { width: "100%" }
        }
      }}
    />
  );
};

export default ImagePickerModal;
