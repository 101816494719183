import React from "react";

import {
  Linking,
  Platform,
  View
} from "react-native";

import { Button } from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  PasswordResetRequestAppTypeEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

type PasswordEmailSentScreenProps = UnauthorizedScreenProps<"PasswordEmailSent">;

const PasswordEmailSent: React.FC<PasswordEmailSentScreenProps> = ({
  navigation,
  route
}) => {
  const snackbar = useSnackbarContext();

  const [ resetPasswordResponse, resetPasswordRequest ] = useApiRequest("USERS:passwordResetRequest");

  const resendPasswordEmail = React.useCallback((email: string) => {
    resetPasswordRequest({ data: { email: email, appType: PasswordResetRequestAppTypeEnum.PUBLIC } });
  }, [ resetPasswordRequest ]);

  React.useEffect(() => {
    if (resetPasswordResponse.data) {
      snackbar.show({ type: "success", text: "Resent password email!" , duration: 5000 });
    } else if (resetPasswordResponse.errorMessage) {
      snackbar.show({ type: "error", text: resetPasswordResponse.errorMessage, duration: 5000 });
    }
  }, [ resetPasswordResponse, snackbar, navigation ]);

  const openEmailApp = React.useCallback(async () => {
    if (Platform.OS === "ios") {
      Linking.openURL("message:");
    } else {
      // TODO: open inbox after expo
      // import { openInbox } from 'react-native-email-link'
      // openInbox()
    }
  },[]);

  return (
    <FullScreenLayout
      HeaderRight={() => (
        <Button
          onPress={() => navigation.navigate("Login")}
          type="primary"
          label="Log in"
          darkModeAware={false}
        />
      )}
      BackgroundComponent={p => (
        <BackgroundView
          {...p}
          gradient="business"
          start={[ 0.25, 0.4 ]}
          end={[ 1.5, 0.75 ]}
        />
      )}
    >
      <View>
        <StyledSirFoundAlot />
      </View>
      <StyledTextWrapper>
        <Text align="center" size="medium" color="white">
          A password reset email has been sent to you. Simply click the link in the email to create a new password.
        </Text>
      </StyledTextWrapper>
      {Platform.OS === "ios" && (
        <ButtonWrapper>
          <Button
            label="Open Email"
            type="tag"
            onPress={openEmailApp}
          />
        </ButtonWrapper>
      )}
      {!!route.params.email && (
        <ButtonWrapper>
          <Button
            label="Resend Email"
            type="tag"
            onPress={() => resendPasswordEmail(route.params.email as string)}
          />
        </ButtonWrapper>
      )}
    </FullScreenLayout>
  );
};

const StyledTextWrapper = styled.View`
  width: 80%;
  align-self: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.View`
  margin-bottom: 20px;
`;

const StyledSirFoundAlot = styled(Image).attrs({
  source: require("../../../../assets/images/sir-foundalot.png"),
  defaultSource: require("../../../../assets/images/sir-foundalot.png")
})`
  background-color: transparent;
  width: 200px;
  height: 200px;
  align-self: center;
  margin-bottom: 20px;
`;

export default PasswordEmailSent;
