import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import ItemImageViewScreen
  from "src/screens/authorized/item-details/ItemImageView";
import WebViewModal from "src/screens/authorized/settings/WebViewModal";

import { CompositeNavigation } from "src/types/navigation";

import { SplashStackParamList } from "../shared/SplashNavigator";
import FoundItemStack, { FoundItemStackParamList } from "./FoundItemStack";
import ItemDetailsStack, {
  ItemDetailsStackParamList
} from "./ItemDetailsStack";
import MainTabNavigator, {
  MainTabParamList
} from "./MainTabNavigator/MainTabNavigator";
import SettingsStack, { SettingsStackParamList } from "./SettingsStack";

export type AuthorizedStackParamList = {
  MainTabNavigator: MainTabParamList;
  ItemDetailsStack: ItemDetailsStackParamList;
  ItemImageViewScreen: { images: string[]; selectedImage: string };
  FoundItemStack: FoundItemStackParamList;
  AddLocationScreen: undefined;
  SettingsStack: SettingsStackParamList;
  WebViewModal: { uri: string };
};

const AuthorizedStack = createStackNavigator<AuthorizedStackParamList>();

/**
 * AuthorizedNavigator
 */
const AuthorizedNavigator: React.FC<AuthorizedStackNavigationProps> = () => (
  <AuthorizedStack.Navigator screenOptions={{ headerShown: false, presentation: "modal", cardStyle: {flex: 1} }} >
    {/* main app stack, other screens are modally presented above this stack */}
    <AuthorizedStack.Screen
      name="MainTabNavigator"
      component={MainTabNavigator}
      // prevents swipe navigation
      options={{ gestureEnabled: false }}
    />

    {/* item details stack */}
    <AuthorizedStack.Screen name="ItemDetailsStack" component={ItemDetailsStack} />

    {/* view item image screen */}
    <AuthorizedStack.Screen name="ItemImageViewScreen" component={ItemImageViewScreen} />

    {/* register found item stack */}
    <AuthorizedStack.Screen name="FoundItemStack" component={FoundItemStack} />

    {/* account settings stack */}
    <AuthorizedStack.Screen
      name="SettingsStack"
      component={SettingsStack}
    />

    {/* webview for info links (contact, FAQ etc.) */}
    <AuthorizedStack.Screen
      name="WebViewModal"
      component={WebViewModal}
    />
  </AuthorizedStack.Navigator>
);

export default AuthorizedNavigator;

export type AuthorizedStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<AuthorizedStackParamList>,
  StackNavigationProp<SplashStackParamList>
>;

export type AuthorizedScreenProps<
  T extends keyof Omit<AuthorizedStackParamList, "MainTabNavigator">
> = CompositeNavigation<
  RouteProp<AuthorizedStackParamList, T>,
  AuthorizedStackCompositeNavigationProps
>;

type AuthorizedStackNavigationProps = CompositeNavigation<
  RouteProp<Pick<SplashStackParamList, "MainStack">, "MainStack">,
  AuthorizedStackCompositeNavigationProps
>;
