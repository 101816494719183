import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import { LocationDTO } from "src/component-lib/src/utils/api";

import WebViewModal from "src/screens/authorized/settings/WebViewModal";
import ForgotPasswordScreen from "src/screens/unauthorized/auth/ForgotPassword";
import IntroScreen from "src/screens/unauthorized/auth/Intro";
import LoginScreen from "src/screens/unauthorized/auth/Login";
import PasswordEmailSentScreen
  from "src/screens/unauthorized/auth/PasswordEmailSent";
import BusinessAddressScreen
  from "src/screens/unauthorized/auth/signup/BusinessAddress";
import BusinessDetailsScreen
  from "src/screens/unauthorized/auth/signup/BusinessDetails";
import {
  BusinessDetailsFormData,
  BusinessTypeEnum
} from "src/screens/unauthorized/auth/signup/businessDetailsForm";
import UserDetailsScreen
  from "src/screens/unauthorized/auth/signup/UserDetails";
import {
  UserDetailsFormData
} from "src/screens/unauthorized/auth/signup/userDetailsForm";
import VerificationEmailSentScreen
  from "src/screens/unauthorized/auth/VerificationEmailSent";

import { SplashStackParamList } from "src/navigation/shared/SplashNavigator";
import { CompositeNavigation } from "src/types/navigation";

type UserDetails = Omit<UserDetailsFormData, "confirmPassword">;

// convert business type param from select option to string value for user create request
interface BusinessDetails extends Omit<BusinessDetailsFormData, "businessType"> {
  businessType: BusinessTypeEnum;
}

export type UnauthorizedStackParamList = {
  Intro: undefined;
  Login: undefined;
  UserDetails: undefined;
  BusinessDetails: {
    userDetails: UserDetails;
  };
  BusinessAddress: {
    userDetails: UserDetails;
    businessDetails: BusinessDetails;
  };
  BusinessHours: {
    userDetails: UserDetails;
    businessDetails: BusinessDetails;
    businessAddress: LocationDTO;
  }
  VerificationEmailSent: { userUuid?: string };
  PasswordEmailSent: { email?: string };
  ForgotPassword: { email?: string };
  WebViewModal: { uri: string };
};

const UnauthorizedStack = createStackNavigator<UnauthorizedStackParamList>();

/**
 * UnauthorizedNavigator
 */
const UnauthorizedNavigator: React.FC<
  UnauthorizedStackNavigationProps
> = () => (
  <UnauthorizedStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>

    <UnauthorizedStack.Screen name="Intro" component={IntroScreen} />

    <UnauthorizedStack.Screen name="Login" component={LoginScreen} />

    <UnauthorizedStack.Screen name="UserDetails" component={UserDetailsScreen} />

    <UnauthorizedStack.Screen name="BusinessDetails" component={BusinessDetailsScreen} />

    <UnauthorizedStack.Screen name="BusinessAddress" component={BusinessAddressScreen} />

    <UnauthorizedStack.Screen name="VerificationEmailSent" component={VerificationEmailSentScreen} />

    <UnauthorizedStack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />

    <UnauthorizedStack.Screen name="PasswordEmailSent" component={PasswordEmailSentScreen} />

    <UnauthorizedStack.Screen name="WebViewModal" component={WebViewModal} />

  </UnauthorizedStack.Navigator>
);

export default UnauthorizedNavigator;

// defines navigation to all child screens and other parent screens
type UnauthorizedStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<UnauthorizedStackParamList>,
  StackNavigationProp<Omit<SplashStackParamList, "UnauthorizedStack">>
>;

// defines navigation possibilities for all child screens
// should be imported into each screen
export type UnauthorizedScreenProps<
  T extends keyof UnauthorizedStackParamList
> = CompositeNavigation<
  RouteProp<UnauthorizedStackParamList, T>,
  UnauthorizedStackCompositeNavigationProps
>;

type UnauthorizedStackNavigationProps = CompositeNavigation<
  RouteProp<SplashStackParamList, "UnauthorizedStack">,
  UnauthorizedStackCompositeNavigationProps
>;
