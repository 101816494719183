import {
  CreatePaymentResponse,
  PaymentDTO,
  PaymentRequest,
  PickParams
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const PaymentRoutes = {
  /* Get details of a payment */
  "PAYMENTS:getPayment": {
    path: "/payments/:paymentUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaymentDTO, null, PickParams<"paymentUuid">>,

  /* Request delivery after having obtained a quote and made a payment */
  "PAYMENTS:createPayment": {
    path: "/payments/",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<CreatePaymentResponse, PaymentRequest>,

  /* Request a quote for delivering an item */
  "PAYMENTS:executePayment": {
    path: "/payments/:paymentUuid/execute",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<PaymentDTO, null, PickParams<"paymentUuid">>
};

export default PaymentRoutes;
