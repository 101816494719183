import * as React from "react";

import { ImagePickerResult } from "expo-image-picker";
import { Platform, ViewStyle } from "react-native";

import { useNavigation } from "@react-navigation/native";

import { Image } from "src/component-lib/src/components/image";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  FoundItemResponse,
  ItemDTO,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled, { css } from "src/component-lib/src/utils/styled-components";
import { Theme } from "src/component-lib/src/utils/theme";

import CameraWhite from "assets/images/camera-white.png";

import ImagePickerModal from "../ImagePickerModal";
import ItemImage from "./ItemImage";

// TODO: potentially move into component lib

interface ItemImageListProps {
  editable?: boolean;
  item: ItemDTO | FoundItemResponse;
  max?: number;
  type?: "file" | "receiptFile";
  prompt?: string;
  promptColor?: keyof Theme["colors"];
  style?: ViewStyle;
}

const ItemImageList: React.FC<ItemImageListProps> = ({
  editable = false,
  item,
  max,
  type = "file",
  prompt,
  promptColor = "fgAccent",
  style
}) => {
  const snackbar = useSnackbarContext();

  const [ images, setImages ] = React.useState<string[]>(type === "file" ? item.images || [] : item.receipts || []);

  const [ addItemImageResponse, addItemImageRequest ] = useApiRequest("ITEMS:saveItemImage");

  const [ pickerModalVisible, setPickerModalVisible ] = React.useState(false);

  const onImagePick = async (result: ImagePickerResult) => {
    if (item.id && !result.cancelled) {
      try {
        // hide picker modal
        setPickerModalVisible(false);
        // use Fetch API to get file blob from local uri
        const localFile = await fetch(result.uri);
        const blob = await localFile.blob();

        const formData = new FormData();

        if (Platform.OS !== "web") {
          formData.append(type, {
            uri: result.uri,
            type: blob.type,
            name: result.uri.split("/").pop() || "image.png"
          });
        } else {
          formData.append(type, blob, "image." + blob.type.split("/").pop() || "image.png");
        }

        // update profile pic
        addItemImageRequest({
          pathParams: { itemUuid: item.id },
          data: formData
        });
      } catch (e) {
        snackbar.show({
          type: "error",
          text: "Image processing failed",
          duration: 5000
        });
      }
    }
  };

  React.useEffect(() => {
    if (addItemImageResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: addItemImageResponse.errorMessage,
        duration: 5000
      });
    } else if (addItemImageResponse.data) {
      const imageListPropName = type === "file" ? "images" : "receipts";
      const updatedImages = addItemImageResponse.data[ imageListPropName ];
      setImages(updatedImages || []);

      snackbar.show({
        text: type === "file" ? "Item image added!" : "Receipt image added!",
        duration: 4000,
        type: "success"
      });
    }
  }, [ snackbar, addItemImageResponse, type ]);

  const limitReached = max ? images.length >= max : false;

  const removeDeletedImage = (deletedUuid: string) => {
    const updatedImageList = [ ...images ].filter(imageUuid => imageUuid !== deletedUuid);
    setImages(updatedImageList);
  };

  const navigation = useNavigation();

  return (
    <ListWrapper style={style} horizontal>
      { images.map(uuid => (
        <ItemImage
          key={uuid}
          itemId={item.id}
          imageId={uuid}
          editable={editable}
          onPress={() => navigation.navigate("ItemImageViewScreen", {
            images,
            selectedImage: uuid
          })}
          onDeleted={removeDeletedImage}
        />
      ))}
      {editable && !limitReached && (
        <AddImageButton onPress={() => setPickerModalVisible(true)}>
          <CameraImageWrapper>
            <CameraImage
              source={CameraWhite}
              defaultSource={CameraWhite}
            />
          </CameraImageWrapper>
        </AddImageButton>
      )}
      {!!prompt && images.length === 0 && (
        <PromptText color={promptColor}>
          {prompt}
        </PromptText>
      )}
      <ImagePickerModal
        isVisible={pickerModalVisible}
        onClose={() => setPickerModalVisible(false)}
        onImagePickSuccess={onImagePick}
        tip={ type === "file" ? "Try to include the item's brand and model in the image!" : undefined}
      />
    </ListWrapper>
  );
};

export default ItemImageList;

const ListWrapper = styled.ScrollView`
  height: 76px;
  padding-top: 3px;
  flex-direction: row;
  padding-bottom: 12px;
`;

const AddImageButton = styled(TouchableHighlight)`
  ${({ theme }) => css`
    background-color: ${theme.colors.green};
    width: 57px;
    height: 57px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  `}
`;

const CameraImageWrapper = styled.View`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const CameraImage = styled(Image)`
  height: 32px;
  width: 32px;
`;

const PromptText = styled(Text)`
  margin-left: 18px;
  align-self: center;
`;
