import React, { useEffect, useState } from "react";
import { ItemDTO, ItemDTOStatusEnum } from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";
import { Icon } from "src/component-lib/src/components/icon";
import Fuse from "fuse.js";
import { ItemDTOWithImageSrc } from "./ProtectedItemsList";

interface ItemFilterProps {
  protectedItems: ItemDTOWithImageSrc[];
  selectedTag: string;
  onFilter: (filteredItems: ItemDTO[]) => void;
}

const filterItemsByTag = (item: ItemDTO, selectedTag: string) => {
  if (selectedTag === "lost") {
    return item.status === ItemDTOStatusEnum.LOST;
  } else if (selectedTag === "found") {
    return item.status === ItemDTOStatusEnum.FOUND;
  } else {
    return selectedTag === item.category || selectedTag === "ALL";
  }
};

const ItemFilter: React.FC<ItemFilterProps> = ({ protectedItems, selectedTag, onFilter }) => {

  const [ filterText, setFilterText ] = useState("");

  useEffect(() => {
    const filterWords = filterText
      .toLowerCase()
      .split(" ")
      .filter(notEmpty => notEmpty);

    const taggedItems = protectedItems
      .filter(i => filterItemsByTag(i, selectedTag));

    const fuse = new Fuse(taggedItems, {
      threshold: 0.3,
      keys: [ "model", "brand", "category", "color", "description", "readableId" ]
    });

    const filteredItems = filterWords.length > 0
      ? filterWords.reduce<ItemDTO[]>((items, filterWord) => {
        fuse.setCollection(items);

        const results = fuse
          .search(filterWord)
          .map(fuseResult => fuseResult.item);

        return results;
      }, taggedItems)
      : taggedItems;

    onFilter(filteredItems);
  }, [ onFilter, protectedItems.map(i => `${i.id},${i.imageSrc}`).join(" "), selectedTag, filterText ]);

  return (
    <SearchBarView>
      <SearchIcon />
      <SearchBarInput
        testID="item-filter-text-input"
        onChangeText={setFilterText}
        returnKeyType="search"
        clearButtonMode="always"
        keyboardType="web-search"
        placeholder="Search items"
      />
    </SearchBarView>
  );
};

export default ItemFilter;

const DismissKeyboardWrapper = styled.ScrollView.attrs({
  keyboardShouldPersistTaps: "handled",
  scrollEnabled: false
})`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 10;
`;

const SearchBarView = styled.View`
  background-color: ${themeProps => themeProps.theme.colors.bgAccent};
  flex-direction: row;
  align-items: center;
`;

const SearchBarInput = styled.TextInput`
  flex: 1;
  font-size: ${themeProp => themeProp.theme.text.large};
  color: ${themeProp => themeProp.theme.colors.fg};
`;

const SearchIcon = styled(Icon).attrs({
  icon: "magnifying-glass",
  size: 32
})`
  padding: 2px;
`;
