import React from "react";

import {
  Modal,
  ModalProps
} from "src/component-lib/src/components/modal";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";

interface DeleteItemModalProps extends Partial<ModalProps> {
  onClose: ModalProps["onClose"];
  itemId: string | undefined;
  onDelete: () => void;
  cancelCta?: string;
  deleteCta?: string;
  showSuccessSnack?: boolean;
}

const DeleteItemModal: React.FC<DeleteItemModalProps> = ({
  title = "Are you sure you wish to delete this item?",
  isVisible,
  onClose,
  itemId,
  onDelete,
  cancelCta = "Cancel",
  deleteCta = "Delete",
  showSuccessSnack = true
}) => {
  const snackbar = useSnackbarContext();

  const [ deleteItemResponse, deleteItemRequest ] = useApiRequest("ITEMS:deleteItem");

  const deleteItem = () => {
    if (itemId) {
      deleteItemRequest({
        pathParams: {
          itemUuid: itemId
        }
      });
    }
  };

  React.useEffect(() => {
    if (deleteItemResponse.errorMessage) {
      onDelete();
      snackbar.show({
        type: "error",
        duration: 5000,
        text: deleteItemResponse.errorMessage
      });
    } else if (deleteItemResponse.data) {
      onDelete();
      if (showSuccessSnack) {
        snackbar.show({
          type: "success",
          duration: 4000,
          text: "Item deleted!"
        });
      }
    }
  }, [ snackbar, deleteItemResponse, onDelete, showSuccessSnack, onClose ]);

  return (
    <Modal
      title={title}
      isVisible={isVisible}
      onClose={onClose}
      buttons={{
        reject: {
          type: "tertiary",
          label: cancelCta,
          onPress: onClose,
          style: { width: "100%" }
        },
        confirm: {
          type: "error",
          label: deleteCta,
          onPress: deleteItem,
          style: { width: "100%" }
        }
      }}
    />
  );
};

export default DeleteItemModal;
