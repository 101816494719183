import React from "react";

import { Hook } from "src/types/hook";

type State = boolean;
type Action = () => void;

/**
 * Interval (time) hook
 */
const useInterval = (
  cb: () => void,
  intervalMs: number
): Hook<State, Action> => {
  const intervalRef = React.useRef<NodeJS.Timeout>();
  const [ isCleared, setIsCleared ] = React.useState(false);

  const callback = React.useRef(cb);

  React.useEffect(() => {
    callback.current = cb;
  });

  /**
   * Clear the interval
   */
  const clearUseInterval = React.useCallback(() => {
    // Check that the interval ref exists before continuing
    if (intervalRef.current) {
      // Clear the interval
      clearInterval(intervalRef.current);

      // Update the isCleared state
      setIsCleared(true);
    }
  }, []);

  // On intervalMs change, reset the interval
  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      callback.current();
    }, intervalMs);
  }, [ intervalMs ]);

  return [ isCleared, clearUseInterval ];
};

export default useInterval;
