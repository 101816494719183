import {
  LostFoundMatchDTO,
  PickParams,
  ResponseId,
  StringResponse
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const MatchRoutes = {
  /* Create a lost/found match */
  "MATCHES:matches": {
    path: "/matches",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<ResponseId, null, null, CreateMatchQuery>,

  /* Retrieve a lost/found match*/
  "MATCHES:getMatch": {
    path: "/matches/:matchUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LostFoundMatchDTO, null, PickParams<"matchUuid">>,

  /* Cancel a match */
  "MATCHES:cancelMatch": {
    path: "/matches/:matchUuid/cancelled",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"matchUuid">>,

  /* Confirm a match */
  "MATCHES:confirmMatch": {
    path: "/matches/:matchUuid/confirmed",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"matchUuid">>
};

export default MatchRoutes;

export interface CreateMatchQuery {
  "found-uuid": string;
  "lost-uuid": string;
}
