import React from "react";

import {
  SubmitHandler,
  useForm,
  ErrorOption
} from "react-hook-form";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";

import changePasswordFormConfig, {
  ChangePasswordFormData
} from "./changePasswordFormConfig";

type ChangePasswordScreenProps = SettingsStackScreenProps<"ChangePasswordScreen">;

const ChangePasswordScreen: React.FC<ChangePasswordScreenProps> = ({ navigation }) => {
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ changePasswordResponse, changePasswordRequest ] = useApiRequest("USERS:changePassword");

  const { handleSubmit, setError, ...formControls } = useForm<ChangePasswordFormData>();

  /**
   * @param data Submit the form
   */
  const submitForm: SubmitHandler<ChangePasswordFormData> = ({
    oldPassword,
    newPassword,
    newPasswordConfirm
  }) => {
    if (newPassword === oldPassword) {
      setError("newPassword", {type: "same", message: "Must be different from your current password!"});
    } else if (newPassword !== newPasswordConfirm) {
      setError("newPassword", {type: "notMatch", message: "Passwords do not match!"});
      setError("newPasswordConfirm", {type: "notMatch", message: "Passwords do not match!"});
    } else {
      changePasswordRequest({
        pathParams: {
          userUuid: authTokenState?.userUUID || ""
        },
        data: {
          oldPassword,
          newPassword,
          newPasswordConfirm
        }
      });
    }
  };

  React.useEffect(() => {
    if (changePasswordResponse.errorMessage) {
      if (changePasswordResponse.errorMessage === "Old password is incorrect.") {
        setError("oldPassword", {type: "incorrect", message: "Current password is incorrect"});
      } else {
        snackbar.show({
          text: changePasswordResponse.errorMessage,
          type: "error",
          duration: 5000
        });
      }
    } else if (changePasswordResponse.data) {
      snackbar.show({
        text: "Password changed!",
        type: "success",
        duration: 4000
      });
      navigation.goBack();
    }
  }, [ changePasswordResponse, navigation, setError, snackbar ]);

  const scrollViewRef = React.useRef<any>(null);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      // height={260}
      title="Change Password"
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          icon="ios-arrow-back"
          color="white"
          size={28}
          onPress={() => navigation.goBack()}
        />
      )}
    >
      <Form
        config={changePasswordFormConfig}
        scrollViewRef={scrollViewRef}
        validateOnBlur
        {...formControls}
      >
        <TextInput
          name="oldPassword"
          label="Current Password"
          secureTextEntry
        />
        <TextInput
          name="newPassword"
          label="New Password"
          secureTextEntry
        />
        <TextInput
          name="newPasswordConfirm"
          label="Confim New Password"
          secureTextEntry
        />
        <Button
          style={{ marginTop: 36 }}
          type="tertiary"
          label="Submit"
          onPress={handleSubmit(submitForm)}
        />
      </Form>
    </CollapsibleHeaderScrollLayout>
  );
};

export default ChangePasswordScreen;

