import React from "react";

import { View } from "react-native";

import {
  useIsFocused,
  useNavigation
} from "@react-navigation/native";

import { ImageCircle } from "src/component-lib/src/components/image-circle";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

const ProfilePicMiniHeader: React.FC = () => {
  const snackbar = useSnackbarContext();

  const navigation = useNavigation();

  const { authTokenState } = useAuthenticationContext();

  const isFocused = useIsFocused();

  const [ getUserInfoResponse, getUserInfoRequest ] = useApiRequest("USERS:getUserInfo");

  // get user info on screen focus
  React.useEffect(() => {
    if (authTokenState?.userUUID && isFocused) {
      getUserInfoRequest({
        pathParams: { userUuid: authTokenState.userUUID }
      });
    }
  }, [ authTokenState, getUserInfoRequest, isFocused ]);

  const [ profilePictureUrl, setProfilePictureUrl ] = React.useState<string>();

  React.useEffect(() => {
    let didCancel = false;
    // on failure, show error snack
    if (getUserInfoResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: getUserInfoResponse.errorMessage,
        duration: 5000
      });
      // on success, grab profile pic url
    } else if (getUserInfoResponse.data && !didCancel) {
      setProfilePictureUrl(getUserInfoResponse.data.userProfilePicture);
    }

    return () => {
      didCancel = true;
    };
  }, [ getUserInfoResponse, snackbar ]);

  return (
    <ImageWrapper>
      <ImageCircle
        source={{
          uri: profilePictureUrl,
          headers: {
            Authorization: authTokenState?.token.value || ""
          }
        }}
        defaultSource={require("assets/images/icon.png")}
        onPress={() => navigation.navigate("ProfileScreen")}
      />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.View`
  width: 40px;
  margin-right: 10px;
`;

export default ProfilePicMiniHeader;
