import * as React from "react";

import { ImagePickerResult } from "expo-image-picker";

import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import useApiRequest from "src/component-lib/src/utils/api/useApiRequest";

import CreateItemView from "src/components/CreateItemView";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

type FoundItemScreenProps = FoundItemStackScreenProps<"FoundItemScreen">;

const FoundItemScreen: React.FC<FoundItemScreenProps> = ({ navigation }) => {
  const [ pickerModalVisible, setPickerModalVisible ] = React.useState(false);

  const onImagePick = (result: ImagePickerResult) => {
    if (!result.cancelled) {
      // hide picker modal
      setPickerModalVisible(false);
      // navigate to processing screen
      navigation.navigate("FoundItemProcessingScreen", { imageSrc: result.uri });
    }
  };

  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ createItemResponse, createItemRequest ] = useApiRequest("ITEMS:registerFoundItem");

  const onAddLater = () => {
    const userUuid = authTokenState?.userUUID;
    if (userUuid) {
      try {
        createItemRequest({
          pathParams: { userUuid },
          // passing undefined here fixes android empty formdata issue
          data: undefined
        });
      } catch (e) {
        snackbar.show({
          type: "error",
          text: "Creating item failed",
          duration: 5000
        });
      }
    }
  };

  // On create item response
  React.useEffect(() => {
    // on failure, show error snack - item not created
    if (createItemResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: createItemResponse.errorMessage,
        duration: 5000
      });
    // on success, pass created item to details screen
    } else if (createItemResponse.data) {
      navigation.navigate("FoundItemDetailsScreen", { item: createItemResponse.data });
    }
  }, [ createItemResponse, navigation, snackbar ]);

  return (
    <CreateItemView
      goBack={() => navigation.goBack()}
      title="Register Found Item"
      description="To help us match the item with its owner, first take a photo of the item"
      onImagePick={onImagePick}
      pickerModalVisible={pickerModalVisible}
      setPickerModalVisible={setPickerModalVisible}
      onAddImageLater={onAddLater}
    />
  );
};

export default FoundItemScreen;
