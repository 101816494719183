import * as React from "react";

import axios, { AxiosError } from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";

import {
  AppTypeEnum,
  AuthenticationRequest,
  AuthenticationResponse,
  DeviceTypeEnum,
  SocialTypeEnum
} from "../auth-api";
import { useAuthenticationContext } from "../../../hoc/authentication";

const baseUrl = Constants?.manifest?.extra?.BASE_URL;

/** Sign in  */
const useSignIn = () => {
  const { setAuthTokenState } = useAuthenticationContext();

  return React.useCallback(
    async (email: string, password: string, organization = false) => {
      const data: AuthenticationRequest = {
        email,
        password,
        organization,
        appType: organization ? AppTypeEnum.BUSINESS : AppTypeEnum.PUBLIC,
        type: SocialTypeEnum.DEFAULT,
        deviceToken: Constants.deviceId as string,
        deviceType:
          Platform.OS === "ios"
            ? DeviceTypeEnum.IOS
            : Platform.OS === "android"
              ? DeviceTypeEnum.ANDROID
              : DeviceTypeEnum.DEFAULT
      };

      try {
        const { data: newToken } = await axios.request<
        AuthenticationResponse
        >({
          method: "POST",
          url: `${baseUrl}/authentication/tokens/regular`,
          data
        });

        setAuthTokenState(newToken);

        return;
      } catch (err) {
        const error = err as AxiosError;
        if (error?.response?.data?.message) {
          throw new Error(error.response.data.message);
        } else if (error?.response?.data?.error?.message) {
          throw new Error(error.response.data.error.message);
        } else if (typeof error?.response?.data === "string") {
          throw new Error(error.response.data);
        } else {
          throw new Error("Unknown error occurred");
        }
      }
    },
    [ setAuthTokenState ]
  );
};

export default useSignIn;
