import { useAuthenticationContext } from "src/component-lib/src/hoc/authentication";
import { useApiRequest, UserDTO } from "src/component-lib/src/utils/api";
import React from "react";

export interface UserInfoContextState {
  userInfo: UserDTO | null
}

const initialContextState: UserInfoContextState = {
  userInfo: null
};

export const UserInfoContext = React.createContext<UserInfoContextState>(initialContextState);

export const UserInfoProvider: React.FC = ({ children }) => {
  const [ getUserInfoResponse, makeUserInfoRequest ] = useApiRequest("USERS:getUserInfo");
  const { authTokenState } = useAuthenticationContext();

  React.useEffect(() => {
    if (authTokenState?.userUUID){
      makeUserInfoRequest({ "pathParams": { "userUuid":  authTokenState.userUUID } });
    }
  }, [ makeUserInfoRequest, authTokenState ]);

  return (
    <UserInfoContext.Provider value={{ userInfo: getUserInfoResponse.data }}>
      {children}
    </UserInfoContext.Provider>
  );
};
