import React from "react";

import {
  Icon,
  IconType
} from "src/component-lib/src/components/icon";

interface TabIconInfo {
  icon: IconType;
  size?: number;
}

export interface RenderTabBarIconProps {
  focusedIcon: TabIconInfo;
  unfocusedIcon: TabIconInfo;
}

interface TabIconProps {
  focused: boolean;
}

type RenderTabBarIconFn = ({
  focusedIcon,
  unfocusedIcon
}: RenderTabBarIconProps) => React.FC<TabIconProps>;

const renderTabBarIcon: RenderTabBarIconFn = ({
  focusedIcon,
  unfocusedIcon
}) => ({ focused }) => {
  const { size, icon } = focused ? focusedIcon : unfocusedIcon;

  return (
    <Icon
      icon={icon}
      color={focused ? "blue" : "neutral"}
      size={size || 28}
      style={{ marginTop: 6 }}
    />
  );
};

export default renderTabBarIcon;
