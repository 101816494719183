import React, { ComponentProps } from "react";

import {
  Animated,
  Easing,
  Image
} from "react-native";
import MapView from "react-native-maps";

import FoundMapMarkerSelected
  from "assets/images/map-marker-found-selected.png";
import FoundMapMarkerUnselected
  from "assets/images/map-marker-found-unselected.png";

interface MapViewFoundMarkerProps extends ComponentProps<typeof MapView.Marker> {
  selected?: boolean;
}

/**
 * Found MapView marker
 */
const MapViewFoundMarker: React.FC<MapViewFoundMarkerProps> = ({
  selected,
  ...rest
}) => {
  const maxWidth = 46;
  const maxHeight = 46;
  // const minWidth = 36;
  const minHeight = 36;
  const unselectedScale = minHeight / maxHeight;
  const selectedScale = 1;
  const scaleAnim = React.useRef(new Animated.Value(1)).current;

  const translateAnim = React.useRef(
    new Animated.Value((maxHeight - maxHeight * unselectedScale) / 2)
  ).current;

  // On selected change
  React.useEffect(() => {
    Animated.timing(scaleAnim, {
      toValue: !selected ? unselectedScale : selectedScale,
      duration: 200,
      easing: Easing.out(Easing.ease),
      useNativeDriver: true
    }).start();

    Animated.timing(translateAnim, {
      toValue: !selected
        ? (maxHeight - maxHeight * unselectedScale) / (2 * unselectedScale)
        : (maxHeight - maxHeight * selectedScale) / (2 * selectedScale),
      duration: 200,
      easing: Easing.out(Easing.ease),
      useNativeDriver: true
    }).start();
  }, [ scaleAnim, selected, translateAnim, unselectedScale ]);

  return (
    <MapView.Marker {...rest}>
      <Animated.View
        style={{
          width: maxWidth,
          height: maxHeight
        }}
      >
        <Animated.View
          style={{
            transform: [ { scale: scaleAnim }, { translateY: translateAnim } ]
          }}
        >
          <Image
            source={
              !selected ? FoundMapMarkerUnselected : FoundMapMarkerSelected
            }
            style={{ width: maxWidth, height: maxHeight }}
          />
        </Animated.View>
      </Animated.View>
    </MapView.Marker>
  );
};

export default MapViewFoundMarker;
