import React from "react";

import { Text } from "src/component-lib/src/components/text";
import {
  FoundItemResponse,
  ItemDTO
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import ItemImageList from "./item-images/ItemImageList";

interface ManageItemImagesHeaderProps {
  title: string;
  item: ItemDTO | FoundItemResponse;
  editable?: boolean;
}

const ManageItemImagesHeader: React.FC<ManageItemImagesHeaderProps> = ({ title, item, editable = false }) => (
  <HeaderContentWrapper>
    <Text type="h1" color="white" style={{ marginBottom: 12 }}>
      {title}
    </Text>
    <ItemImageList editable={editable} item={item} prompt="Add a photo of the item" promptColor="white" />
  </HeaderContentWrapper>
);

export default ManageItemImagesHeader;

const HeaderContentWrapper = styled.View`
  margin-top: 88px;
  padding: 28px;
  flex-direction: column;
`;
