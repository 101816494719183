import React from "react";

import formatDate from "date-fns/format";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import { ImageCircle } from "src/component-lib/src/components/image-circle";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Modal } from "src/component-lib/src/components/modal";
import { Text } from "src/component-lib/src/components/text";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  useApiRequest,
  UserDTORolesEnum
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import { ItemDetailRow } from "src/components/ItemRow";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";

type MemberDetailsScreenProps = SettingsStackScreenProps<"MemberDetailsScreen">;

const MemberDetailsScreen: React.FC<MemberDetailsScreenProps> = ({ navigation, route }) => {
  const { user } = route.params;

  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ userRole, setUserRole ] = React.useState<UserDTORolesEnum | undefined>(user.roles && user.roles.length > 0 ? user.roles[ 0 ] : undefined);

  const [ updateUserRoleResponse, updateUserRoleRequest ] = useApiRequest("USERS:updateUserRole");

  const updateUserRole = (role: UserDTORolesEnum) => {
    if (user.id) {
      updateUserRoleRequest({
        pathParams: {
          userUuid: user.id
        },
        data: {
          roles: [ role ]
        }
      });
    }
  };

  React.useEffect(() => {
    if (updateUserRoleResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: updateUserRoleResponse.errorMessage,
        duration: 5000
      });
    } else if (updateUserRoleResponse.data) {
      snackbar.show({
        type: "success",
        text: "User role successfully updated!",
        duration: 5000
      });
      setUserRole(currentRole => {
        if (currentRole === UserDTORolesEnum.COMPANYADMIN) {
          return UserDTORolesEnum.COMPANY;
        }

        return UserDTORolesEnum.COMPANYADMIN;
      });
    }
  }, [ navigation, snackbar, updateUserRoleResponse ]);

  const [ deleteUserModalVisible, setDeleteUserModalVisible ] = React.useState(false);

  const [ deleteUserResponse, deleteUserRequest ] = useApiRequest("USERS:deleteUser");

  const deleteUser = () => {
    if (user.id) {
      deleteUserRequest({
        pathParams: {
          userUuid: user.id
        }
      });
    }
  };

  React.useEffect(() => {
    if (deleteUserResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: deleteUserResponse.errorMessage,
        duration: 5000
      });
    } else if (deleteUserResponse.data) {
      setDeleteUserModalVisible(false);
      snackbar.show({
        type: "success",
        text: "User successfully deleted!",
        duration: 5000
      });
      navigation.goBack();
    }
  }, [ deleteUserResponse, navigation, snackbar ]);

  const scrollViewRef = React.useRef<any>(null);

  return (
    <CollapsibleHeaderScrollLayout
      noPadding
      gradientType="business"
      height={260}
      title="Member Details"
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          style={{ height: 42, width: 34 }}
          icon="ios-arrow-back"
          color="white"
          size={28}
          onPress={() => navigation.goBack()}
        />
      )}
      HeaderForegroundComponent={React.useCallback(() => (
        <ImageHeaderWrapper>
          <ImageCircle
            source={{
              uri: user.userProfilePicture,
              headers: {
                Authorization: authTokenState?.token.value || ""
              }
            }}
            defaultSource={require("assets/images/icon.png")}
          />
          <TagWrapper>
            {user.businessName && (
              <CompanyTag>
                <Label>{user.businessName}</Label>
              </CompanyTag>
            )}
            {userRole && (
              <UserRoleTag>
                <Label>
                  {userRole === "COMPANY_ADMIN" ? "Admin" : "Staff"}
                </Label>
              </UserRoleTag>
            )}
          </TagWrapper>
        </ImageHeaderWrapper>
      ), [ user, userRole, authTokenState?.token?.value ])}
    >
      {(!!user.firstName || !!user.lastName) && (
        <ItemDetailRow
          label="Name"
          value={`${user.firstName ?? ""}${user.lastName ? " " + user.lastName : ""}`}
        />
      )}
      {!!user.emailAddress && (
        <ItemDetailRow
          label="Email"
          value={user.emailAddress}
        />
      )}
      {!!user.dateCreated && (
        <ItemDetailRow
          label="Account Created"
          value={formatDate(user.dateCreated, "dd MMM yyyy, HH:mm")}
        />
      )}
      <ItemDetailRow
        label="Last Active"
        value={user.dateLastActivity ? formatDate(user.dateLastActivity, "dd MMM yyyy, HH:mm") : "Never"}
      />
      <ItemDetailRow
        label="Items Registered"
        value={(user.itemsRegistered || 0).toString()}
      />
      <ButtonsWrapper>
        {userRole === "COMPANY_ADMIN" ? (
          <StyledButton
            type="tertiary"
            label="Demote to staff"
            onPress={() => updateUserRole(UserDTORolesEnum.COMPANY)}
          />
        ) : (
          <StyledButton
            type="tertiary"
            label="Promote to admin"
            onPress={() => updateUserRole(UserDTORolesEnum.COMPANYADMIN)}
          />
        )}
        <StyledButton
          type="error"
          label="Delete user"
          onPress={() => setDeleteUserModalVisible(true)}
        />
      </ButtonsWrapper>
      <Modal
        title="Are you sure you wish to delete this user?"
        isVisible={deleteUserModalVisible}
        onClose={() => setDeleteUserModalVisible(false)}
        buttons={{
          confirm: {
            type: "error",
            label: "Delete",
            onPress: deleteUser,
            style: { width: "100%" }
          },
          reject: {
            type: "tertiary",
            label: "Cancel",
            onPress: () => setDeleteUserModalVisible(false),
            style: { width: "100%" }
          }
        }}
      />
    </CollapsibleHeaderScrollLayout>
  );
};

export default MemberDetailsScreen;

const ImageHeaderWrapper = styled.View`
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-top: 100px;
  padding-top: 25px;
  position: relative;
  align-self: center;
`;

const TagWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 18px;
`;

const CompanyTag = styled.View`
  padding: 6px 12px;
  border-radius: 24px;
  background-color: ${ ({ theme }) => theme.colors.purple};
`;

const UserRoleTag = styled.View`
  margin-left: 6px;
  padding: 6px 12px;
  border-radius: 24px;
  background-color: ${ ({ theme }) => theme.colors.green};
`;

const Label = styled(Text).attrs({
  color: "white",
  type: "small-uppercase"
})``;

const ButtonsWrapper = styled.View`
  margin-top: 9px;
  margin-bottom: 100px;
`;

const StyledButton = styled(Button)`
  margin: 15px 18px;
  margin-bottom: 0;
`;
