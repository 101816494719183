import qs from "qs";
import { Linking } from "react-native";

import { query } from "../util";
import request from "./request";

export interface Tokens {
  consumerKey: string;
  consumerSecret: string;
  requestToken: string;
  requestTokenSecret: string;
  oauthTokenSecret: string;
  oauthToken: string;
}

function getRequestToken(tokens: Pick<Tokens, "consumerKey"| "consumerSecret">, callbackUrl: string, accessType: "read" | "write") {
  const method = "POST";
  const url = "https://api.twitter.com/oauth/request_token";
  const body = accessType ? { x_auth_access_type: accessType } : {};

  return request(tokens, url, { method, body }, { oauth_callback: callbackUrl })
    // eslint-disable-next-line no-undef
    .then((response: Response) => response.text())
    .then((text: string) => {

      const params = qs.parse(text);

      return {
        requestToken: params.oauth_token as string,
        requestTokenSecret: params.oauth_token_secret as string
      };
    });
}

function getAccessToken(
  { consumerKey, consumerSecret, requestToken, requestTokenSecret }: Pick<Tokens, "consumerKey" | "consumerSecret"| "requestToken" | "requestTokenSecret">,
  oauthVerifier: string | { accessToken: any; accessTokenSecret: any; id: any; name: any },
) {
  const method = "POST";
  const url = "https://api.twitter.com/oauth/access_token";

  return request(
    { consumerKey, consumerSecret, oauthToken: requestToken, oauthTokenSecret: requestTokenSecret },
    url,
    { method },
    { oauth_verifier: oauthVerifier },
  )
    .then(response => response.text())
    .then(text => {

      const params = qs.parse(text);

      return {
        accessToken: params.oauth_token,
        accessTokenSecret: params.oauth_token_secret,
        id: params.user_id,
        name: params.screen_name
      };
    });
}

const verifierDeferreds = new Map();

Linking.addEventListener("url", ({ url }) => {
  const params = qs.parse(url.split("?")[ 1 ]);
  if (params.oauth_token && verifierDeferreds.has(params.oauth_token)) {
    const verifierDeferred = verifierDeferreds.get(params.oauth_token);
    verifierDeferreds.delete(params.oauth_token);
    if (params.oauth_verifier) {
      verifierDeferred.resolve(params.oauth_verifier);
    } else {
      verifierDeferred.reject(new Error("denied"));
    }
  }
});

interface AuthOptions {
  accessType: "read" | "write";
  forSignIn?: boolean;
  forceLogin?: boolean;
  screenName?: string;
}

export default async function twitterAuth(
  tokens: Pick<Tokens, "consumerKey"| "consumerSecret">,
  callbackUrl: string | ReturnType<typeof getAccessToken>,
  { accessType, forSignIn = false, forceLogin = false, screenName = "" }: AuthOptions = { accessType: "read" },
) {

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const usePin = typeof callbackUrl.then === "function";

  const { requestToken, requestTokenSecret } = await getRequestToken(
    tokens,
    usePin ? "oob" : callbackUrl as string,
    accessType,
  );

  Linking.openURL(`https://api.twitter.com/oauth/${forSignIn ? "authenticate" : "authorize"}?${
    query({ oauth_token: requestToken, force_login: forceLogin, screen_name: screenName })
  }`);

  return getAccessToken(
    { ...tokens, requestToken, requestTokenSecret },
    await (
      usePin ?
        callbackUrl :
        new Promise((resolve, reject) => {verifierDeferreds.set(requestToken, { resolve, reject });})
    ),
  );
}
