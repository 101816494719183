import Constants from "expo-constants";
import OneSignal from "react-native-onesignal";

export function initOneSignalClient(): void {
  OneSignal.setLogLevel(6, 0);
  OneSignal.setAppId(Constants?.manifest?.extra?.ONE_SIGNAL_APP_ID);
  OneSignal.provideUserConsent(true);

  //Method for handling notifications received while app in foreground
  OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
    const notification = notificationReceivedEvent.getNotification();
    // Complete with null means don't show a notification.
    notificationReceivedEvent.complete(notification);
  });

  OneSignal.disablePush(false);
  OneSignal.unsubscribeWhenNotificationsAreDisabled(false);
  OneSignal.setLogLevel(6, 0);
}
