import React from "react";

import {
  TouchableHighlightProps,
  ViewStyle
} from "react-native";

import { Text } from "../text";
import {
  TouchableHighlight
} from "../touchable-highlight";
import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

export interface ButtonProps {
  onPress: () => void;
  disabled?: boolean;
  label: string;
  small?: boolean;
  textColor?: keyof Theme["colors"];
  darkModeAware?: boolean;
  style?: TouchableHighlightProps["style"];
  contentStyle?: ViewStyle;
}

export interface MainButtonProps extends ButtonProps {
  type: ButtonType;
}

export type ButtonType =
  | "primary"
  | "primary-fill"
  | "secondary"
  | "tertiary"
  | "error"
  | "success"
  | "tag"
  | "active-tag"
  | "tag-green"
  | "active-tag-green"
  | "tag-red"
  | "active-tag-red"
  | "plain"
  | "apple-black"
  | "apple-white"
  | "apple-transparent";

const Button: React.FC<MainButtonProps> = ({
  label,
  type,
  small,
  onPress,
  disabled = false,
  textColor,
  darkModeAware = true,
  contentStyle,
  ...rest
}) => {
  const buttonAccent = darkModeAware ? "bgAccent" : "white";

  const color = textColor
    ? textColor
    : type === "primary"
      ? buttonAccent
      : type === "tag" || type === "primary-fill"
        ? "blue"
        : type === "tag-green"
          ? "green"
          : type === "tag-red"
            ? "red"
            : type === "apple-white"
              ? "black"
              : "white";

  return (
    <MainButtonWrapper
      type={type}
      darkModeAware={darkModeAware}
      onPress={!disabled ? onPress : undefined}
      disabled={disabled}
      {...rest}
    >
      <MainButtonInner small={small} style={contentStyle}>
        <Text
          weight="bold"
          size={small ? "small" : "medium"}
          color={color}
        >
          {label}
        </Text>
      </MainButtonInner>
    </MainButtonWrapper>
  );
};

export default Button;

export const PrimaryButtonStyles = (theme: Theme, darkModeAware: boolean) => css`
  border: ${theme.colors[ darkModeAware ? "bgAccent" : "white" ]} 2px solid;
  background: transparent;
`;

export const PrimaryFillButtonStyles = (theme: Theme, darkModeAware: boolean) => css`
  background: ${theme.colors[ darkModeAware ? "bgAccent" : "white" ]};
  border: ${theme.colors[ darkModeAware ? "bgAccent" : "white" ]} 2px solid;
`;

export const PlainButtonStyles = () => css`
  background: transparent;
`;

export const SecondaryButtonStyles = (theme: Theme) => css`
  background: ${theme.colors.purple};
`;

export const TertiaryButtonStyles = (theme: Theme) => css`
  background: ${theme.colors.green};
`;
export const TagButtonStyles = (
  theme: Theme,
  darkModeAware: boolean,
  type: "tag" | "tag-green" | "tag-red"
) => {
  const tagModifier = type.split("-")[ 1 ];
  const color = (tagModifier as "green" | "red") || "blue";

  return css`
    background: ${theme.colors[ darkModeAware ? "bgAccent" : "white" ]};
    border: ${theme.colors[ color ]} 2px solid;
  `;
};
export const ActiveTagButtonStyles = (
  theme: Theme,
  type: "active-tag" | "active-tag-green" | "active-tag-red"
) => {
  const tagModifier = type.split("tag-")[ 1 ];
  const color = (tagModifier as "green" | "red") || "blue";

  return css`
    background: ${theme.colors[ color ]};
    border: ${theme.colors[ color ]} 2px solid;
  `;
};

export const ErrorButtonButtonStyles = (theme: Theme) => css`
  background: ${theme.actions.error};
`;

export const SuccessButtonStyles = (theme: Theme) => css`
  background: ${theme.actions.success};
`;

export const AppleAuthButtonStyles = (type: "apple-white" | "apple-black" | "apple-transparent") => {
  if (type === "apple-black") {
    return css`
      background-color: black;
      border: black 2px solid;
    `;
  } else {
    return css`
      background-color: ${type === "apple-white" ? "white" : "transparent"};
      border: white 2px solid;
    `;
  }
};

const chooseStyles = (theme: Theme, type: ButtonType, darkModeAware: boolean) => {
  switch (type) {
  default:
  case "primary":
    return PrimaryButtonStyles(theme, darkModeAware);

  case "primary-fill":
    return PrimaryFillButtonStyles(theme, darkModeAware);

  case "secondary":
    return SecondaryButtonStyles(theme);

  case "tertiary":
    return TertiaryButtonStyles(theme);

  case "tag":
  case "tag-green":
  case "tag-red":
    return TagButtonStyles(theme, darkModeAware, type);

  case "active-tag":
  case "active-tag-green":
  case "active-tag-red":
    return ActiveTagButtonStyles(theme, type);

  case "error":
    return ErrorButtonButtonStyles(theme);

  case "success":
    return SuccessButtonStyles(theme);

  case "plain":
    return PlainButtonStyles();

  case "apple-black":
  case "apple-white":
  case "apple-transparent":
    return AppleAuthButtonStyles(type);
  }
};

export const MainButtonInner = styled.View<{
  small?: boolean;
}>`
  ${({ small }) => css`
    padding: ${small ? "4px 12px" : "10px 30px"};
    align-items: center;
  `}
`;

export const MainButtonWrapper = styled(TouchableHighlight)<{
  type: MainButtonProps["type"];
  darkModeAware: boolean;
  disabled: boolean;
}>`
  ${({ theme, type, darkModeAware, disabled }) => css`
    border-radius: 100px;
    align-items: center;
    opacity: ${disabled ? 0.25 : 1};

    ${chooseStyles(theme, type, darkModeAware)};
  `}
`;
