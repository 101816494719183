import { FormConfig } from "src/component-lib/src/components/form";

export interface ProfileFormData {
  name: string;
  email: string;
  phone: string;
}

export const profileFormConfig: FormConfig<ProfileFormData> = {
  name: {
    validation: {
      required: {
        value: true,
        message: "Name is required"
      }
    }
  },
  email: {
    validation: {
      required: {
        value: true,
        message: "Email is required"
      },
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email format"
      }
    }
  },
  phone: {
    validation: {
      required: false,
      pattern: {
        value: /(\+44|0)7\d{9}/,
        message: "Invalid mobile number"
      }
    }
  }
};
