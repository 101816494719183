import React from "react";

import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { View } from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  Select,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";

import {
  businessDetailsFormConfig,
  BusinessDetailsFormData,
  BusinessTypeEnum,
  businessTypeOptions
} from "src/screens/unauthorized/auth/signup/businessDetailsForm";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";

type BusinessDetailsScreenProps = SettingsStackScreenProps<"EditBusinessDetailsScreen">;

const BusinessDetailsScreen: React.FC<BusinessDetailsScreenProps> = ({ navigation, route }) => {
  const { user } = route.params;

  /**
   * Submit the form
   */
  const submitForm: SubmitHandler<BusinessDetailsFormData> = React.useCallback(businessDetails => {
    navigation.navigate("EditBusinessAddressScreen", {
      user: {
        ...user,
        ...businessDetails,
        businessType: businessDetails.businessType.value
      }
    });
  }, [ navigation, user ]);

  const scrollViewRef = React.useRef<any>(null);

  const { handleSubmit, control, setValue, ...formControls } = useForm<BusinessDetailsFormData>();

  React.useEffect(() => {
    setValue("businessName", user.businessName || "");
    setValue("website", user.website || "");
    setValue("businessType", user.businessType ? {
        label: user.businessType as BusinessTypeEnum,
        value: user.businessType as BusinessTypeEnum
      } : {
        label: BusinessTypeEnum.OTHER,
        value: BusinessTypeEnum.OTHER
      });
  }, [ setValue, user ]);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      title="Business Details"
      height={220}
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          color="white"
          icon="chevron-left"
          onPress={() => navigation.goBack()}
          size={35}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Business Details"
          description={`Edit business details for ${user.businessName}`}
        />
      )}
    >
      <Form
        config={businessDetailsFormConfig}
        validateOnBlur
        keyboardOffset={320}
        scrollViewRef={scrollViewRef}
        setValue={setValue}
        {...formControls}
      >
        <TextInput
          name="businessName"
          label="Business Name"
          defaultValue={user.businessName}
          textContentType="organizationName"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="website"
          label="Website"
          defaultValue={user.website}
          textContentType="URL"
          keyboardType="url"
          autoCapitalize="none"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <Controller
          render={({ field: { onChange, value: fieldValue } }) => (<Select
            onValueChange={onChange}
            label="Select business type"
            items={businessTypeOptions}
            selectedValue={fieldValue}
          />)}
          control={control}
          name="businessType"
          rules={businessDetailsFormConfig.businessType?.validation as {
            required: {
              value: boolean;
              message: string;
            };
          }}
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Edit Address"
          style={{ marginTop: 12, marginBottom: 24 }}
        />
      </Form>
    </CollapsibleHeaderScrollLayout>
  );
};

export default BusinessDetailsScreen;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View style={{ marginTop: 130, paddingHorizontal: 26 }}>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);
