/**
 * @export
 * @enum {string}
 */
export enum AppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}
/**
 * @export
 * @enum {string}
 */
export enum DeviceTypeEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  DEFAULT = "DEFAULT"
}
/**
 * @export
 * @enum {string}
 */
export enum SocialTypeEnum {
  FACEBOOK = "FACEBOOK",
  APPLE = "APPLE",
  REGULAR = "REGULAR",
  DEFAULT = "DEFAULT",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  GOOGLEPLUS = "GOOGLE_PLUS"
}

/**
 * @export
 * @enum {string}
 */
export enum RoleEnum {
  USER = "USER",
  COMPANYADMIN = "COMPANY_ADMIN",
  COMPANY = "COMPANY",
  ENDUSERMANAGEMENT = "END_USER_MANAGEMENT",
  PARTNERMANAGEMENT = "PARTNER_MANAGEMENT",
  PROPERTYMANAGEMENT = "PROPERTY_MANAGEMENT",
  FOUNDSTAFFMANAGEMENT = "FOUND_STAFF_MANAGEMENT",
  TEMPORARYINDB = "TEMPORARY_IN_DB"
}

/**
 * @export
 * @enum {string}
 */
export enum WorkingHoursDayEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

/**
 *
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  appType: AppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  deviceToken: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  deviceType: DeviceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthenticationRequest
   */
  organization?: boolean;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  socialMediaSecret?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  socialMediaToken?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  type?: SocialTypeEnum;
}

/**
 *
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  role: RoleEnum;
  /**
   *
   * @type {Token}
   * @memberof AuthenticationResponse
   */
  token: Token;
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  userUUID: string;
}

/**
 *
 * @export
 * @interface SocialUserRequest
 */
export interface SocialUserRequest {
  /**
   *
   * @type {boolean}
   * @memberof SocialUserRequest
   */
  acceptMarketing: boolean;
  /**
   * Apple auth data.
   */
  socialAppleAuthData: {
    userId: string;
    givenName: string | null;
    familyName: string | null;
    authCode: string | null;
  } | null;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  appType: AppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  deviceToken: string;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  deviceType: DeviceTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof SocialUserRequest
   */
  organization?: boolean;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  socialMediaSecret?: string;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  socialMediaToken: string;
  /**
   *
   * @type {string}
   * @memberof SocialUserRequest
   */
  type: SocialTypeEnum;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof SocialUserRequest
   */
  workingHours?: Array<WorkingHours>;
}

/**
 *
 * @export
 * @interface WorkingHours
 */
export interface WorkingHours {
  /**
   *
   * @type {string}
   * @memberof WorkingHours
   */
  closingTime: number;
  /**
   *
   * @type {string}
   * @memberof WorkingHours
   */
  day: WorkingHoursDayEnum;
  /**
   *
   * @type {string}
   * @memberof WorkingHours
   */
  openingTime: number;
}

/**
 *
 * @export
 * @interface Token
 */
export interface Token {

    /**
   *
   * @type {string}
   * @memberof Token
   */
  expirationTime: number;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  value: string;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  createdBy?: string;

  /**
   *
   * @type {number}
   * @memberof TokenDTO
   */
  timeToLive?: number;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  deletedBy?: string;

  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  id?: ObjectId;
  /**
   *
   * @type {boolean}
   * @memberof Token
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  updatedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  userID?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  uuid?: string;

  /**
   *
   * @type {number}
   * @memberof Token
   */
  version?: number;
}

/**
 *
 * @export
 * @interface ObjectId
 */
export interface ObjectId {
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  counter?: number;
  /**
   *
   * @type {string}
   * @memberof ObjectId
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  machineIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  processIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  time?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timeSecond?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timestamp?: number;
}

