import React from "react";

import { TouchableHighlight } from "react-native";

import {
  Image,
  ImageProps
} from "../image";
import styled from "../../utils/styled-components";

interface ImageCircleProps extends ImageProps {
  loading?: boolean;
  onPress?: () => void;
}

const ImageCircle: React.FC<ImageCircleProps> = ({ source, defaultSource, onPress }) => (
  <ImageCircleWrapper onPress={onPress}>
    <ImageStyled
      source={source || {}}
      defaultSource={defaultSource}
    />
  </ImageCircleWrapper>
);

export default ImageCircle;

const ImageCircleWrapper = styled(TouchableHighlight)`
  aspect-ratio: 1;
  border-radius: 1000px;
  overflow: hidden;
`;

const ImageStyled = styled.Image`
  width: 100%;
  height: 100%;
  min-width: 60px;
  min-height: 60px;
`;
