import React from "react";

import formatDate from "date-fns/format";
import * as Location from "expo-location";
import * as Permissions from "expo-permissions";

import {
  Button,
  ButtonLink,
  IconButton
} from "src/component-lib/src/components/button";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { MapView } from "src/component-lib/src/components/map-view";
import { Text } from "src/component-lib/src/components/text";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  ItemDTOStatusEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled, { css } from "src/component-lib/src/utils/styled-components";

import DeleteItemModal from "src/components/DeleteItemModal";
import ItemImagesHeader from "src/components/ItemImagesHeader";
import {
  ItemDetailRow,
  ItemDetailRowProps,
  ItemLongDetailRow
} from "src/components/ItemRow";
import MapViewFoundMarker from "src/components/MapViewFoundMarker";

import { sentenceCase } from "src/utils/strings/sentence-case";

import {
  ItemDetailsStackScreenProps
} from "src/navigation/authorized/ItemDetailsStack";
import { View } from "react-native";
import { useScrollToTop } from "@react-navigation/native";
import { Marker } from "react-google-maps";
import { readableId } from "src/utils/strings/readable-id";

const ItemDetails: React.FC<ItemDetailsStackScreenProps<"ItemDetailsScreen">> = ({ navigation, route }) => {
  const { item } = route.params;

  const snackbar = React.useRef(useSnackbarContext());

  // allowed keys for standard key value display items
  const standardKeys: Array<
    keyof Pick<typeof item, "brand" | "category" | "color" | "model">
  > = [ "brand", "category", "color", "model" ];

  // Reverse Geocode the address from the long/lat
  const [ address, setAddress ] = React.useState<Location.LocationGeocodedAddress | null>(null);

  React.useEffect(() => {
    (async function getAddress() {
      const { status } = await Permissions.askAsync(Permissions.LOCATION);

      if (status !== "granted") {
        snackbar.current.show({
          type: "error",
          duration: 5000,
          text:
            "We need your location to display the correct location information"
        });

        return;
      }

      if (item.longitude && item.latitude) {
        try {
          const add = await Location.reverseGeocodeAsync({
            longitude: item.longitude,
            latitude: item.latitude
          });

          setAddress(add[ 0 ]);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [ item.latitude, item.longitude ]);

  const onEditPress = () => {
    navigation.navigate("FoundItemStack", {
      screen: "FoundItemDetailsScreen",
      params: { item }
    });
  };

  const getItemStatusProps = (status: ItemDTOStatusEnum): ItemDetailRowProps => {
    const label = "Status";

    if (status === ItemDTOStatusEnum.RETURNED) {
      return {
        label,
        value: "RETURNED",
        valueColor: "green"
      };
    }

    return {
      label,
      value: "FOUND",
      valueColor: "blue"
    };
  };

  const [ markAsReturnedResponse, markAsReturnedRequest ] = useApiRequest("ITEMS:markItemAsReturned");

  const markAsReturned = () => {
    if (item.id) {
      markAsReturnedRequest({
        pathParams: { itemUuid: item.id }
      });
    }
  };

  React.useEffect(() => {
    if (markAsReturnedResponse.errorMessage) {
      snackbar.current.show({
        type: "error",
        duration: 5000,
        text: markAsReturnedResponse.errorMessage
      });
    } else if (markAsReturnedResponse.data) {
      snackbar.current.show({
        type: "success",
        duration: 4000,
        text: "Item marked as returned!"
      });
      navigation.navigate("ReturnItemConfirmationScreen", { item });
    }
  }, [ item, markAsReturnedResponse, navigation ]);

  const [ deleteItemModalVisible, setDeleteItemModalVisible ] = React.useState(false);

  const Header = React.useCallback(() => (
    <ItemImagesHeader
      title="Item Details"
      item={item}
    />
  ), [ item ]);

  const containerRef = React.useRef<any>(null);

  // scroll to top on tab bar icon press
  useScrollToTop(containerRef);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      height={item.images.length > 0 ? 240 : undefined}
      title="Item Details"
      noPadding
      containerRef={containerRef}
      HeaderLeft={() => (
        <IconButton
          icon="ios-close"
          color="white"
          size={42}
          onPress={() => navigation.navigate("MainTabNavigator")}
          style={{ marginLeft: 8 }}
        />
      )}
      HeaderRight={() => (
        <ButtonLink
          label="Edit"
          onPress={onEditPress}
          color="white"
          bold
          style={{ marginRight: -14 }}
        />
      )}
      HeaderForegroundComponent={item.images.length > 0 ? Header : undefined}
    >
      <View>
      {!!item.id && <ItemDetailRow label="ID" value={readableId(item.id)} />}

      <ItemDetailRow {...getItemStatusProps(item.status)} />

      {standardKeys.map((key, i) =>
        item[ key ] ? (
          <ItemDetailRow
            key={`item-row-${i}`}
            label={key}
            value={
              key === "category"
                // Transform category to proper case
                ? sentenceCase(item[ key ].replace("_", " "))
                : item[ key ]
            }
          />
        ) : null
      )}

      {!!item.description && <ItemLongDetailRow label="Description" value={item.description} />}

      {!!item.value && (
        <ItemDetailRow
          label="Value"
          value={`£ ${item.value}`}
        />
      )}

      {item.status === ItemDTOStatusEnum.FOUND && !!item.dateFound && (
        <ItemDetailRow
          label="Date found"
          value={formatDate(item.dateFound, "dd MMM yyyy, HH:mm")}
        />
      )}

      {!!item.name && (
        <ItemDetailRow
          label="Finder name"
          value={item.name}
        />
      )}

      {!!item.mail && (
        <ItemDetailRow
          label="Finder email"
          value={item.mail}
        />
      )}

      {!!item.latitude && !!item.longitude && (
        <LocationWrapper>
          <MapViewWrapper>
            <MapView
              toolbarEnabled={true}
              zoomControlEnabled
              zoomEnabled
              region={{
                latitude: item.latitude,
                longitude: item.longitude,
                latitudeDelta: 0.01,
                longitudeDelta: 0.01
              }}
            >
              <MapViewFoundMarker
                identifier={item.id}
                coordinate={{
                  latitude: item.latitude,
                  longitude: item.longitude
                }}
              />
            </MapView>
          </MapViewWrapper>
          {!!address && (
            <AddressWrapper>
              <Text size="small">
                {`${address.name}, ${address.city}, ${address.country}, ${address.postalCode}`}
              </Text>
            </AddressWrapper>
          )}
        </LocationWrapper>
      )}

      {item.status === ItemDTOStatusEnum.FOUND && (
        <StyledButton
          type="tertiary"
          label="Mark as returned"
          onPress={markAsReturned}
        />
      )}

      <StyledButton
        type="error"
        label="Delete item"
        // style={{
        //   marginTop: item.status === ItemDTOStatusEnum.RETURNED ? 36 : 24,
        //   marginBottom: 48
        // }}
        onPress={() => setDeleteItemModalVisible(true)}
      />

      <DeleteItemModal
        isVisible={deleteItemModalVisible}
        onClose={() => setDeleteItemModalVisible(false)}
        itemId={item.id}
        onDelete={() => navigation.goBack()}
      />
      </View>
    </CollapsibleHeaderScrollLayout>
  );
};

export default ItemDetails;

const LocationWrapper = styled.View`
  margin-top: 20px;
`;

const MapViewWrapper = styled.View`
  height: 32em;
`;

const AddressWrapper = styled.View`
  ${({ theme }) => css`
    flex-direction: row;
    padding: 20px;
    background-color: ${theme.colors.bgAccent};
  `}
`;

const StyledButton = styled(Button)`
  /* margin: 36px; */
  margin-bottom: 0;
`;

const MapMarker = styled.Image.attrs({
  source: require("assets/images/map-marker-found-unselected.png")
})`
  width: 36px;
  height: 36px;
`;
