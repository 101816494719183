import React from "react";

import ConfirmationView from "src/components/ConfirmationView";

import {
  ItemDetailsStackScreenProps
} from "src/navigation/authorized/ItemDetailsStack";

type ReturntItemConfirmationScreenProps = ItemDetailsStackScreenProps<"ReturnItemConfirmationScreen">;

/**
 * Image processing screen
 */
const ReturntItemConfirmationScreen: React.FC<ReturntItemConfirmationScreenProps> = ({ navigation, route }) => {
  const { item } = route.params;

  return (
    <ConfirmationView
      title="Thanks for returning this item."
      subtitle=""
      item={item}
      ctaTitle="Back to my items"
      onCtaPress={() => navigation.navigate("MainTabNavigator")}
    />
  );
};

export default ReturntItemConfirmationScreen;
