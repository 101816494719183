import * as React from "react";

import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";

import ImageProcessingView from "src/components/ImageProcessingView";

import useProgress from "src/hooks/useProgress";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

type FoundItemProcessingScreenProps = FoundItemStackScreenProps<"FoundItemProcessingScreen">;

/**
 * Image processing screen
 */
const FoundItemProcessingScreen: React.FC<FoundItemProcessingScreenProps> = ({ navigation, route }) => {
  const { imageSrc } = route.params;

  const [ step, progress, completed ] = useProgress(160, 4000);

  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ createItemResponse, createItemRequest ] = useApiRequest("ITEMS:registerFoundItem");

  // trigger request on mount
  React.useEffect(() => {
    (async () => {
      // Constants.debugMode = true;
      const userUuid = authTokenState?.userUUID;
      if (userUuid) {
        try {
          // use Fetch API to get file blob from local uri
          const localFile = await fetch(imageSrc);
          const blob = await localFile.blob();

          const formData = new FormData();

          formData.append("file", {
            uri: imageSrc,
            type: blob.type,
            name: imageSrc.split("/").pop() || "image.png"
          });

          createItemRequest({
            pathParams: { userUuid },
            data: formData
          });
        } catch (e) {
          snackbar.show({
            type: "error",
            text: "Image processing failed",
            duration: 5000
          });
        }
      }
    })();
  }, [ imageSrc, createItemRequest, authTokenState, snackbar ]);

  // On progress animation completion
  React.useEffect(() => {
    // on failure, show error snack immediately and go back - item not created
    if (createItemResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: createItemResponse.errorMessage,
        duration: 5000
      });
      navigation.goBack();
    } else if (completed && createItemResponse.data) {
      navigation.navigate("FoundItemDetailsScreen", { item: createItemResponse.data });
    }
  }, [ completed, createItemResponse, navigation, snackbar ]);

  return (
    <ImageProcessingView step={step} progress={progress} />
  );
};

export default FoundItemProcessingScreen;
