import * as React from "react";

import {
  BottomTabNavigationProp,
  createBottomTabNavigator
} from "@react-navigation/bottom-tabs";
import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";

import styled from "src/component-lib/src/utils/styled-components";

import HomeScreen from "src/screens/authorized/home/Home";
import ProfileScreen from "src/screens/authorized/profile/Profile";

import RegisterItemButton from "src/components/RegisterItemButton";
import renderTabBarIcon, {
  RenderTabBarIconProps
} from "src/components/TabBarIcon";

import {
  AuthorizedStackCompositeNavigationProps,
  AuthorizedStackParamList
} from "src/navigation/authorized/AuthorizedNavigator";
import { CompositeNavigation } from "src/types/navigation";

import NotificationStack from "./NotificationStack";

export type MainTabParamList = {
  HomeScreen: undefined;
  RegisterItem: undefined;
  NotificationsStack: undefined;
  ProfileScreen: undefined;
};

const tabBarIcons: TabBarIconList = {
  home: {
    focusedIcon: { icon: "home" },
    unfocusedIcon: {
      icon: "home-outline",
      size: 26
    }
  },
  register: {
    focusedIcon: { icon: "ios-add", size: 42 },
    unfocusedIcon: { icon: "ios-add", size: 42 }
  },
  notifications: {
    focusedIcon: { icon: "bell", size: 26 },
    unfocusedIcon: { icon: "bell-outline" }
  },
  profile: {
    focusedIcon: { icon: "cog" },
    unfocusedIcon: { icon: "cog-outline" }
  }
};

const EmptyComponent = () => null;

const Tab = createBottomTabNavigator<MainTabParamList>();

const MainTabNavigator: React.FC<MainTabNavigationProps> = ({ navigation }) => (
  <StyledTabNavigator screenOptions={{ headerShown: false }}>
    <Tab.Screen
      name="HomeScreen"
      component={HomeScreen}
      options={{
        tabBarLabel: "Home",
        tabBarIcon: renderTabBarIcon(tabBarIcons.home)
      }}
    />
    <Tab.Screen
      name="RegisterItem"
      component={EmptyComponent}
      options={{
        tabBarButton: RegisterItemButton
      }}
    />
    <Tab.Screen
      name="NotificationsStack"
      component={NotificationStack}
      options={{
        tabBarLabel: "Notifications",
        tabBarIcon: renderTabBarIcon(tabBarIcons.notifications)
      }}
    />
    <Tab.Screen
      name="ProfileScreen"
      component={ProfileScreen}
      options={{
        tabBarLabel: "Settings",
        tabBarIcon: renderTabBarIcon(tabBarIcons.profile)
      }}
    />
  </StyledTabNavigator>
);

export default MainTabNavigator;

const StyledTabNavigator = styled(Tab.Navigator).attrs(({ theme }) => ({
  tabBarOptions: {
    activeTintColor: theme.colors.blue,
    style: {
      backgroundColor: theme.colors.bgAccent,
      borderTopColor: theme.isDark ? "black" : "#c7c7cc"
    }
  }
}))``;

export type MainTabCompositeNavigationProps = CompositeNavigationProp<
  BottomTabNavigationProp<MainTabParamList>,
  AuthorizedStackCompositeNavigationProps
>;

export type MainTabScreenProps<
  T extends keyof Omit<MainTabParamList, "NotificationsStack">
> = CompositeNavigation<
  RouteProp<MainTabParamList, T>,
  MainTabCompositeNavigationProps
>;

type MainTabNavigationProps = CompositeNavigation<
  RouteProp<AuthorizedStackParamList, "MainTabNavigator">,
  MainTabCompositeNavigationProps
>;

type TabBarIconList = Record<
  "home" | "register" | "notifications" | "profile",
  RenderTabBarIconProps
>;
