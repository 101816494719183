import React from "react";

import {
  Animated,
  Easing
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Snackbar from "react-native-snackbar-component";
import * as Font from "expo-font";
import styled from "../../utils/styled-components";
import mainTheme, { Theme } from "../../utils/theme";

interface ActionState {
  text: string;
  onPress: () => void;
}

type SnackbarType = keyof Theme["actions"] | "neutral";

interface SnackbarState {
  show: boolean;
  text: string;
  action: ActionState;
  distance?: number;
  type: SnackbarType;
}

interface SnackbarShowParams {
  text: string;
  duration: number;
  action?: ActionState;
  type: SnackbarType;
}

interface SnackbarActions {
  show: (params: SnackbarShowParams) => void;
  dismiss: () => void;
}

export const SnackbarContext = React.createContext<SnackbarActions>({
  show: () => null,
  dismiss: () => null
});

const initialState: SnackbarState = {
  show: false,
  text: "",
  distance: 0,
  type: "neutral",
  action: { text: "Dismiss", onPress: () => null }
};

const easingValues = {
  entry: Easing.bezier(0.0, 0.0, 0.2, 1),
  exit: Easing.bezier(0.4, 0.0, 1, 1)
};

const durationValues = {
  entry: 100,
  exit: 195
};

/**
 * Snackbar provide
 * Wrap app in provider and use the useSnackbarContext hook whereever you like
 */
export const SnackbarProvider: React.FunctionComponent = ({ children }) => {

  // eslint-disable-next-line no-undef
  const timer = React.useRef<NodeJS.Timeout>();
  const [ fadeAnim ] = React.useState(new Animated.Value(0)); // Initial value for opacity: 0
  const safeAreaInsets =  useSafeAreaInsets();

  const [ snackbarState, setSnackbarState ] = React.useState<SnackbarState>(
    initialState
  );

  /** dismiss the snackbar */
  const dismissSnackbar = React.useCallback(() => {
    setSnackbarState(currentState => ({ ...currentState, show: false }));

    setTimeout(() => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: durationValues.exit,
        easing: easingValues.exit,
        useNativeDriver: false
      }).start();
    }, 180);

    // clear old timer for any old notifications
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [ fadeAnim ]);

  /** Set the snack bar */
  const showSnackbar = React.useCallback(
    (params: SnackbarShowParams) => {
      setSnackbarState({
        ...initialState,
        text: params.text,
        type: params.type,
        action: params.action || { text: "Dismiss", onPress: dismissSnackbar }
      });

      setTimeout(() => {
        setSnackbarState({
          ...initialState,
          show: true,
          text: params.text,
          type: params.type,
          action: params.action || {
            text: "Dismiss",
            onPress: dismissSnackbar
          }
        });
      }, durationValues.entry);

      Animated.timing(fadeAnim, {
        toValue: safeAreaInsets.top,
        duration: durationValues.entry,
        easing: easingValues.entry,
        useNativeDriver: false
      }).start();

      // clear old timer for any old notifications
      if (timer.current) {
        clearTimeout(timer.current);
      }

      if (params.duration) {
        // reset after duration
        timer.current = setTimeout(() => {
          dismissSnackbar();
        }, params.duration || 3000);
      }
    },
    [ dismissSnackbar, fadeAnim, safeAreaInsets ]
  );

  const snackBarValue = React.useMemo(
    () => ({
      show: showSnackbar,
      dismiss: dismissSnackbar
    }),
    [ showSnackbar, dismissSnackbar ]
  );

  return (
    <SnackbarContext.Provider value={snackBarValue}>
      <Animated.View
        pointerEvents="none"
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          height: fadeAnim,
          width: "100%",
          zIndex: 10000,
          position: "absolute",
          top: 0,
          backgroundColor:
            snackbarState.type === "neutral"
              ? mainTheme.light.colors.neutral
              : mainTheme.light.actions[ snackbarState.type ]
        }}
      />
      <StyledSnackbar
        type={snackbarState.type}
        visible={snackbarState.show}
        position="top"
        // @ts-ignore - outdated missing typings
        top={safeAreaInsets.top}
        safeAreaHeight={safeAreaInsets.top}
        textMessage={snackbarState.text}
        messageColor="white"
        actionText={snackbarState.action ? snackbarState.action.text : "dismiss"}
        actionHandler={snackbarState.action?.onPress}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

/** put out colours on to the snackbar using the theme */
const StyledSnackbar = styled(Snackbar).attrs<{
  type: SnackbarType;
}>(({ theme, type }) => ({
  backgroundColor: `${
    type === "neutral" ? theme.colors.neutralAccent : theme.actions[ type ]
  }`,
  accentColor: `${
    type === "neutral" ? theme.colors.white : theme.colors.white
  }`,
  messageStyle: {
    fontFamily: Font.isLoaded(theme.font.regular) ? theme.font.regular : undefined
  },
  actionStyle: {
    fontFamily: Font.isLoaded(theme.font.bold) ? theme.font.bold : undefined,
    paddingLeft: 6
  }
}))<{
  type: SnackbarType;
}>`
  flex: 1;
`;

/**
 * useSnackbarContext hook for ease
 */
export const useSnackbarContext = () => React.useContext(SnackbarContext);
