import Constants from "expo-constants";
import { ImageURISource } from "react-native";

// Found API returns image UUIDs in place of actual urls
// to render an image, need to GET /api/v1/images/:uuid
// this helper fn builds the image url for the correct environment and returns an Image source object
// this can be passed directly into the source prop of an Image

const buildImageSource = (authToken?: string, imageUuid?: string): ImageURISource => {
  let uri;

  if (authToken && imageUuid) {
    uri = `${Constants?.manifest?.extra?.BASE_URL}/images/${imageUuid}`;

    return {
      uri,
      headers: {
        Authorization: authToken
      }
    };
  }

  return { uri };
};

export default buildImageSource;
