import React from "react";

import { ActivityIndicator } from "react-native";

import {
  TouchableHighlight
} from "../touchable-highlight";
import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

import {
  CardBaseProps,
  CardBorderType,
  CardButtonProps,
  CardProps
} from "./Card";

/**
 * DynamicCard
 */
const DynamicCard: React.FC<CardProps> = ({
  children,
  loading,
  dropShadow = false,
  borderType,
  style,
  onPress
}) => {
  const cardContent = loading ? (
    <ContentWrapper>
      <ActivityIndicator style={style} />
    </ContentWrapper>
  ) : (
    <ContentWrapper>
      {children}
    </ContentWrapper>
  );

  return onPress ? (
    <CardButtonStyled
      dropShadow={dropShadow}
      borderType={borderType}
      onPress={onPress}
    >
      {cardContent}
    </CardButtonStyled>
  ) : (
    <CardViewStyled
      dropShadow={dropShadow}
      borderType={borderType}
    >
      {cardContent}
    </CardViewStyled>
  );
};

export default DynamicCard;

const DynamicCardBaseCSS = css<CardBaseProps>`
  ${({ theme, dropShadow, borderType }) => css`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${borderType ? "0px" : "3px"};
    width: 100%;
    background-color: ${theme.colors.bgAccent};
    border: ${borderType
    ? `3px solid ${handleBorderTypeColour(theme, borderType)}`
    : "none"};
    border-radius: ${theme.borderRadius.default};
    ${dropShadow ? `box-shadow: 0px 4px 5px ${theme.shadows.default};` : null}
    ${dropShadow ? "elevation: 8;" : null}
  `}
`;

const CardViewStyled = styled.View<CardBaseProps>`
  ${DynamicCardBaseCSS}
`;

const CardButtonStyled = styled(TouchableHighlight)<CardButtonProps>`
  ${DynamicCardBaseCSS}
`;

const ContentWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  width: 100%;
  min-height: 100px;
`;

/**
 * Sets the border colour depending on the given type
 */
const handleBorderTypeColour = (theme: Theme, borderType: CardBorderType) => {
  let colour = "transparent";

  switch (borderType) {
  case "new":
    colour = theme.colors.green;
    break;

  case "info":
    colour = theme.colors.blue;
    break;

  case "error":
    colour = theme.colors.red;
    break;

  default:
    colour = "transparent";
  }

  return colour;
};
