import React from "react";

import {
  Icon,
  IconType
} from "../icon";
import { Text } from "../text";
import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

import {
  ButtonType,
  MainButtonProps,
  MainButtonWrapper
} from "./MainButton";

interface IconTextButtonProps extends MainButtonProps {
  size?: number;
  icon: IconType;
  alignIcon?: AlignIcon;
  type: ButtonType;
  iconSpacing?: number;
}

type AlignIcon = "top" | "bottom" | "left" | "right";
interface TextStyledProps {
  color?: keyof Theme["colors"];
  alignIcon: AlignIcon;
  iconSpacing: number;
  iconSize: number;
}

const IconTextButton: React.FC<IconTextButtonProps> = ({
  size = 30,
  icon,
  label,
  type,
  onPress,
  disabled = false,
  small,
  iconSpacing = 0,
  alignIcon = "top",
  darkModeAware = true,
  textColor
}) => {
  const buttonAccent = darkModeAware ? "bgAccent" : "white";

  const color = textColor
    ? textColor
    : type === "primary"
      ? buttonAccent
      : type === "tag" || type === "primary-fill"
        ? "blue"
        : type === "apple-white"
          ? "black"
          : "white";

  // apple buttons need system font
  // pass these button types through to style text differently
  const textType = (
    type === "apple-black"
    || type === "apple-white"
    || type === "apple-transparent"
  ) ? type : "h3";

  return (
    <ButtonWrapper
      onPress={!disabled ? onPress : undefined}
      disabled={disabled}
      type={type}
      darkModeAware={darkModeAware}
      style={{ height: 46 }}
    >
      <ContentWrapper alignIcon={alignIcon} small={small}>
        {[ "top", "left" ].includes(alignIcon) ? (
          <>
            <Icon
              icon={icon}
              size={size}
              color={color}
              style={icon === "apple" ? { marginTop: -3 } : undefined}
            />
            <TextStyled
              align="center"
              alignIcon={alignIcon}
              iconSpacing={iconSpacing}
              iconSize={size}
              type={textType}
              color={color}
            >
              {label}
            </TextStyled>
          </>
        ) : (
          <>
            <TextStyled
              align="center"
              alignIcon={alignIcon}
              iconSpacing={iconSpacing}
              iconSize={size}
              type="h3"
              color={color}
            >
              {label}
            </TextStyled>
            <Icon icon={icon} size={size} color={color} />
          </>
        )}
      </ContentWrapper>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled(MainButtonWrapper)`
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const ContentWrapper = styled.View<{
  alignIcon: AlignIcon;
  small?: boolean;
}>`
  ${({ alignIcon, small }) => css`
    flex-direction: ${[ "top", "bottom" ].includes(alignIcon) ? "column" : "row"};
    align-items: center;
    ${alignIcon === "left" && "align-self: flex-start"}
    padding-left: ${small ? 12 : 30}px;
    padding-right: ${small ? 12 : 30}px;
    padding-top: ${small ? 4 : 10}px;
    padding-bottom: ${small ? 4 : 10}px;
  `}
`;

const TextStyled = styled(Text)<TextStyledProps>`
  ${({ theme, color, alignIcon, iconSpacing, iconSize }) => css`
    color: ${color ? `${theme.colors[ color ]}` : "inherit"};
    ${alignIcon === "left" || alignIcon === "right" ? css`
      margin-${alignIcon}: -${iconSize}px;
      flex: 1;
    ` : css`
      margin-${alignIcon}: -${iconSpacing}px;
    `}
  `}
`;

export default IconTextButton;
