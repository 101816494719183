import React from "react";

import { AppearanceProvider } from "react-native-appearance";

import { DynamicTheme } from "../../utils/theme";
import DynamicThemeProvider from "../dynamic-theme-provider";

interface ThemeProps {
  theme: DynamicTheme;
}

export const ThemeWrapper: React.FC<ThemeProps> = ({ theme, children }) => (
  <AppearanceProvider>
    <DynamicThemeProvider theme={theme}>{children}</DynamicThemeProvider>
  </AppearanceProvider>
);
