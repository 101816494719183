import * as React from "react";
import { Platform } from "react-native";

import { Card } from "src/component-lib/src/components/card";
import { Icon } from "src/component-lib/src/components/icon";
import { ImageCircle } from "src/component-lib/src/components/image-circle";
import { Text } from "src/component-lib/src/components/text";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import {
  ItemDTO,
  ItemDTOStatusEnum
} from "src/component-lib/src/utils/api";
import useItemImages from "src/component-lib/src/utils/api/useItemImage";
import styled from "src/component-lib/src/utils/styled-components";

import { buildImageSource } from "src/utils/images";
import { readableId } from "src/utils/strings/readable-id";
import ItemFilter from "./ItemFilter";

interface ProtectedItemsProps {
  protectedItems: ItemDTO[];
  selectedTag: string;
  onItemSelect: (item: ItemDTO) => void;
}

export interface ItemDTOWithImageSrc extends ItemDTO {
  imageSrc?: string;
  readableId?: string;
}

const cardBorderStyles: Partial<Record<ItemDTOStatusEnum, "new" | "info" | "error">> = {
  [ ItemDTOStatusEnum.LOST ]: "error",
  [ ItemDTOStatusEnum.FOUND ]: "info",
  [ ItemDTOStatusEnum.RETURNED ]: "new"
};

const ProtectedItemsList: React.FC<ProtectedItemsProps> = props => {
  const { authTokenState } = useAuthenticationContext();
  const [ displayedItems, setDisplayedItems ] = React.useState<ItemDTOWithImageSrc[]>([]);

  const imageSrcs = useItemImages(authTokenState, props.protectedItems.map(item => item.images.length ? item.images[0] : ""));
  const itemsWithImages: ItemDTOWithImageSrc[] = props.protectedItems.map((item, index) => ({
    ...item,
    imageSrc: imageSrcs[index],
    readableId: readableId(item.id)
  }));

  return (
    <>
      <ItemFilter protectedItems={itemsWithImages} selectedTag={props.selectedTag} onFilter={setDisplayedItems} />
      {props.children}
      {displayedItems
        .sort((a, b) => b.date - a.date)
        .sort((a, b) => a.status - b.status)
        .map((item) => (
          <CardWrapper key={item.id}>
            <Card
              onPress={() => props.onItemSelect(item)}
              dropShadow={true}
              borderType={cardBorderStyles[ item.status ]}
            >
              <GridWrapper>
                <ImageWrapper pointerEvents="none">
                  <ImageCircle
                    source={Platform.OS === "web" ? item.imageSrc : buildImageSource(authTokenState?.token.value, item.images[ 0 ])}
                    defaultSource={require("assets/images/icon.png")}
                  />
                </ImageWrapper>
                <ItemWrapper>
                  <Text weight="bold">{item.model}</Text>
                  <Text>
                    {item.brand}
                  </Text>
                  <Text color="neutral" type="small">
                    ID: {item.readableId}
                  </Text>
                </ItemWrapper>
                {item.status === ItemDTOStatusEnum.LOST && (
                  <LostAlert>
                    <Icon icon="alert-circle-outline" color="red" />
                    <Text color="red" weight="bold" type="small">
                      Lost
                    </Text>
                  </LostAlert>
                )}
                {item.status === ItemDTOStatusEnum.FOUND && (
                  <LostAlert>
                    <Icon icon="alert-circle-outline" color="blue" />
                    <Text color="blue" weight="bold" type="small">
                      Found
                    </Text>
                  </LostAlert>
                )}
                {item.status === ItemDTOStatusEnum.RETURNED && (
                  <LostAlert>
                    <Icon icon="check-circle-outline" color="green" />
                    <Text color="green" weight="bold" type="small">
                      Returned
                    </Text>
                  </LostAlert>
                )}
              </GridWrapper>
            </Card>
          </CardWrapper>
        ))}
    </>
  );
};

const LostAlert = styled.View`
  margin-left: auto;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.View`
  width: 60px;
`;

const CardWrapper = styled.View`
  margin: 5px 0;
`;

const ItemWrapper = styled.View`
  margin-left: 20px;
  width: 80%;
`;

const GridWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export default ProtectedItemsList;
