import React from "react";

import {
  StyleProp,
  ViewStyle
} from "react-native";

import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";
import {
  Entypo,
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";

export type IconType =
  | "chevron-down"
  | "chevron-up"
  | "chevron-left"
  | "chevron-right"
  | "clock-outline"
  | "globe"
  | "md-settings"
  | "info-outline"
  | "ios-arrow-back"
  | "ios-close"
  | "ios-images"
  | "ios-more"
  | "ios-add"
  | "ios-checkbox-outline"
  | "ios-checkmark-circle-outline"
  | "ios-refresh"
  | "ios-remove"
  | "ios-remove-circle-outline"
  | "person"
  | "person-outline"
  | "home"
  | "home-outline"
  | "lock"
  | "magnifying-glass"
  | "map-marker"
  | "map-marker-outline"
  | "flash-on"
  | "flash-auto"
  | "flash-off"
  | "bell"
  | "bell-outline"
  | "alert-circle-outline"
  | "check-circle-outline"
  | "at"
  | "facebook"
  | "apple"
  | "twitter"
  | "share"
  | "shield-alert-outline"
  | "shield-check-outline"
  | "cog"
  | "cog-outline";

export interface IconProps {
  size?: number;
  icon: IconType;
  color?: keyof Theme["colors"];
  style?: StyleProp<ViewStyle>;
}

interface IconComponentProps {
  name: string;
  color: keyof Theme["colors"];
  size: number;
  style?: StyleProp<ViewStyle>;
}

type IconSetProps =
  | "Entypo"
  | "Feather"
  | "Ionicons"
  | "MaterialIcons"
  | "MaterialCommunityIcons";

const IconSets = {
  Entypo,
  Feather,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons
};

export const Icon: React.FC<IconProps> = props => {
  const { size, icon, color, style } = props;

  let iconSet: IconSetProps = "Ionicons";

  switch (icon) {

  case "home":
  case "home-outline":
  case "map-marker":
  case "map-marker-outline":
  case "bell":
  case "bell-outline":
  case "alert-circle-outline":
  case "check-circle-outline":
  case "clock-outline":
  case "facebook":
  case "apple":
  case "twitter":
  case "at":
  case "shield-alert-outline":
  case "shield-check-outline":
  case "cog":
  case "cog-outline":
    iconSet = "MaterialCommunityIcons";
    break;

  case "share":
    iconSet = "Feather";
    break;

  case "info-outline":
  case "lock":
  case "person":
  case "person-outline":
  case "flash-on":
  case "flash-auto":
  case "flash-off":
    iconSet = "MaterialIcons";
    break;

  case "md-settings":
  case "ios-arrow-back":
  case "ios-close":
  case "ios-images":
  case "ios-more":
  case "ios-add":
  case "ios-checkbox-outline":
  case "ios-checkmark-circle-outline":
  case "ios-refresh":
  case "ios-remove":
  case "ios-remove-circle-outline":
    iconSet = "Ionicons";
    break;

  case "chevron-down":
  case "chevron-up":
  case "chevron-left":
  case "chevron-right":
  case "globe":
  case "magnifying-glass":
    iconSet = "Entypo";
    break;

  default:
    iconSet = "Entypo";
    break;
  }

  let componentProps: IconComponentProps | null = null;

  // If no icon is given, return null
  if (Object.keys(icon).length === 0 || !Object.keys(icon)[ 0 ]) {
    return null;
  }

  // Set the componentProps
  componentProps = {
    name: icon,
    color: color || "fg",
    size: size || 30,
    style
  };

  // Return the Icon based on the given iconSet
  return IconSets[ iconSet ] ? (
    <IconStyled as={IconSets[ iconSet ]} {...componentProps} />
  ) : null;
};

const IconStyled = styled(Entypo)<IconComponentProps>`
  ${({ theme, color }) => css`
    color: ${theme.colors[ color ]};
  `}
`;
