import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  StorageKey,
  useAuthenticationContext
} from "../../../hoc/authentication";

/** Sign out  */
const useSignOut = () => {
  const { setAuthTokenState } = useAuthenticationContext();

  return async () => {
    try {
      // TODO: May need to revoke tokens but this doesnt work currently
      // REVOKE TOKEN?
      // if (authTokenState?.value) {
      //   await axios.request<AuthenticationResponse>({
      //     method: "DELETE",
      //     url: `${baseUrl}/authentication/tokens/${authTokenState.value}`,
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: authTokenState.value
      //     }
      //   });
      // }
      // Clear the token cache
      await AsyncStorage.removeItem(StorageKey);
      // Set to null so the app know we logged out
      setAuthTokenState(null);

      return;
    } catch (e) {
      throw new Error("Unknown error occurred");
    }
  };
};

export default useSignOut;
