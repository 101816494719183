import React from "react";

import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import {
  Keyboard,
  View
} from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  FormConfig,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";

interface InviteMemberFormData {
  email: string;
}

const inviteMemberFormConfig: FormConfig<InviteMemberFormData> = {
  email: {
    validation: {
      required: {
        value: true,
        message: "Email is required"
      },
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email format"
      }
    }
  }
};

type InviteMemberScreenProps = SettingsStackScreenProps<"InviteMemberScreen">;

const InviteMemberScreen: React.FC<InviteMemberScreenProps> = ({ navigation }) => {
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const { handleSubmit, ...formControls } = useForm<InviteMemberFormData>();

  const [ inviteMemberResponse, inviteMemberRequest ] = useApiRequest("USERS:inviteUserToBusiness");

  const submitForm: SubmitHandler<InviteMemberFormData> = data => {
    Keyboard.dismiss();

    if (authTokenState?.userUUID) {
      inviteMemberRequest({
        pathParams: {
          userUuid: authTokenState.userUUID
        },
        data
      });
    }
  };

  React.useEffect(() => {
    if (inviteMemberResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: inviteMemberResponse.errorMessage,
        duration: 5000
      });
    } else if (inviteMemberResponse.data) {
      snackbar.show({
        type: "success",
        text: inviteMemberResponse.data.message,
        duration: 5000
      });
    }
  }, [ inviteMemberResponse, snackbar ]);

  const scrollViewRef = React.useRef<any>(null);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      height={260}
      title="Invite Member"
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          style={{ height: 42, width: 38 }}
          icon="ios-arrow-back"
          color="white"
          size={28}
          onPress={() => navigation.goBack()}
        />
      )}
      HeaderRight={() => (
        <Button
          label="Account Members"
          onPress={() => navigation.navigate("AccountMembersScreen")}
          type="plain"
          darkModeAware={false}
          style={{ marginRight: -20 }}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Invite Member"
          description="Members can add items to the list of managed items but do not have access to edit or update existing items"
        />
      )}
    >
      <Form
        scrollViewRef={scrollViewRef}
        keyboardOffset={340}
        config={inviteMemberFormConfig}
        validateOnBlur
        {...formControls}
      >
        <TextInput
          name="email"
          label="Email"
          placeholder="Enter an email address to invite"
          autoCapitalize="none"
          autoCorrect={false}
          textContentType="emailAddress"
          keyboardType="email-address"
          clearButtonMode="always"
          returnKeyType="done"
          blurOnSubmit={false}
        />
        <Button
          onPress={handleSubmit(submitForm)}
          label={inviteMemberResponse.loading ? "Inviting..." : "Invite Member"}
          type="tertiary"
          disabled={inviteMemberResponse.loading}
          style={{ marginTop: 24 }}
        />
      </Form>
    </CollapsibleHeaderScrollLayout>
  );
};

export default InviteMemberScreen;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View style={{ marginTop: 120, paddingHorizontal: 26 }}>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);
