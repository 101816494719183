import React, { ComponentProps } from "react";

import { LinearGradient } from "expo-linear-gradient/build/LinearGradient";
import { Animated, StyleProp, View, ViewStyle } from "react-native";

import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

interface ImagePosition {
  justify?: "flex-start" | "center" | "flex-end";
  align?: "flex-start" | "center" | "flex-end";
}

interface BackgroundViewProps extends ComponentProps<typeof View> {
  Image?: React.ComponentType;
  imagePosition?: ImagePosition;
  gradient?: keyof Theme["gradients"];
  gradientDirection?: "horizontal" | "vertical";
  colors?: string[];
  locations?: number[];
  start?: [ number, number ];
  end?: [ number, number ];
  style?: NonNullable<StyleProp<ViewStyle>>;
}

const BackgroundView: React.FC<BackgroundViewProps> = ({
  Image,
  imagePosition,
  colors = [],
  gradient = "public",
  gradientDirection = "horizontal",
  children,
  style,
  ...props
}) => {
  const BackgroundImage = Image ? (
    <BackgroundFill imagePosition={imagePosition}>
      <Image />
    </BackgroundFill>
  ) : null;

  const Content = (
    <ContentWrapper>
      {BackgroundImage}
      {children}
    </ContentWrapper>
  );

  return gradient ? (
    <StyledGradient
      gradientType={gradient}
      colors={colors}
      start={[ 0, 0 ]}
      end={gradientDirection === "horizontal" ? [ 1, 0 ] : [ 0, 1 ]}
      style={style}
      {...props}
    >
      {Content}
    </StyledGradient>
  ) : (
    <Animated.View {...props}>{Content}</Animated.View>
  );
};

const ContentWrapper = styled.View`
  flex-shrink: 0;
  flex-grow: 1;
`;

const BackgroundFill = styled.View<{ imagePosition?: ImagePosition }>`
  position: absolute;
  ${({ imagePosition }) => css`
    justify-content: ${imagePosition?.justify || "center"};
    align-items: ${imagePosition?.align || "center"};
  `}
  width: 100%;
`;

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

interface StyledGradientProps {
  gradientType: keyof Theme["gradients"];
}

const StyledGradient = styled(AnimatedLinearGradient).attrs<
  StyledGradientProps
>(({ gradientType, theme }) => ({
  colors: theme.gradients[ gradientType ]
}))<StyledGradientProps>``;

export default BackgroundView;
export const _LinearGradient = LinearGradient;
