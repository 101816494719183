import React, { FC } from "react";
import styled from "src/component-lib/src/utils/styled-components";
import { Card } from "src/component-lib/src/components/card";
import { Icon } from "src/component-lib/src/components/icon";
import { Text } from "src/component-lib/src/components/text";

interface AddFoundItemProps {
  onPress: () => void;
}

const AddFoundItem: FC<AddFoundItemProps> = ({ onPress }) => (
  <CardWrapper>
    <Card onPress={onPress}>
      <ProtectItemWrapper>
        <Icon icon="ios-add" size={40} color="blue" />
        <Text weight="bold" color="blue">
          Register an Item
        </Text>
      </ProtectItemWrapper>
    </Card>
  </CardWrapper>
);

const CardWrapper = styled.View`
  margin: 15px 0;
`;

const ProtectItemWrapper = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default AddFoundItem;
