import React from "react";

import styled from "../../../utils/styled-components";
import { Text } from "../../text";

interface LabelProps {
  text: string;
}

const Label: React.SFC<LabelProps> = props => {
  return (
    <LabelWrapper>
      <Text type="small">{props.text}</Text>
    </LabelWrapper>
  );
};

export default Label;

const LabelWrapper = styled.View`
  margin-bottom: 5px;
`;
