import React from "react";

import useInterval from "src/hooks/useInterval";

type UseProgressType = (intervalMs: number, limitMs: number) => [ number, number, boolean ];

const useProgress: UseProgressType = (intervalMs, limitMs) => {
  const [ currentMs, setCurrentMs ] = React.useState(0);

  const [ completed, setCompleted ] = React.useState(false);

  const [ isCleared, clearUseInterval ] = useInterval(() => {
    if (currentMs < limitMs) {
      setCurrentMs(val => val + intervalMs);
    } else {
      setCompleted(true);
    }
  }, intervalMs);

  React.useEffect(() => {
    if (completed) {
      clearUseInterval();
    }
  }, [ completed, clearUseInterval ]);

  // On mount
  React.useEffect(() => {
    // On unmount destroy the interval timer
    return () => {
      if (!isCleared) {
        clearUseInterval();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ intervalMs, (currentMs / limitMs) * 100 , completed ];
};

export default useProgress;
