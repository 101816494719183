import React, { ComponentProps } from "react";
import { ActivityIndicatorProps } from "react-native";

import styled, { css } from "../../utils/styled-components";

import DynamicCard from "./DynamicCard";

interface CardWrapper {
  dropShadow?: boolean;
}

export type CardBorderType = "new" | "info" | "error";

export interface CardBaseProps extends CardWrapper {
  borderType?: CardBorderType;
}

export interface CardButtonProps extends CardBaseProps {
  onPress?: () => void;
}

export interface CardProps extends CardButtonProps {
  children: React.ReactNode;
  loading?: boolean;
  style?: ActivityIndicatorProps["style"]
}

/**
 * Card
 */
const Card: React.FC<CardProps> = ({
  dropShadow,
  borderType,
  onPress,
  children,
  loading
}) => (
  <CardWrapper dropShadow={dropShadow}>
    <DynamicCard
      loading={loading}
      dropShadow={dropShadow}
      borderType={borderType}
      onPress={onPress}
    >
      {children}
    </DynamicCard>
  </CardWrapper>
);

export default Card;

const CardWrapper = styled.View<CardWrapper>`
  ${({ dropShadow }) => css`
    flex-direction: row;
    padding-bottom: ${dropShadow ? "12px" : "0px"};
  `}
`;
