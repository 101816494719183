import * as React from "react";

import * as _ from "lodash";

import {
  AppTypeEnum,
  useAuthenticationContext
} from "../../hoc/authentication";
import {
  ItemCategoryDTO,
  ItemCategoryEnum,
  useApiRequest
} from "../../utils/api";

type ItemCategoriesType = ItemCategoryDTO[] | null;
type LoadingType = boolean;
type ErrorType = string | null;
type LabelsType = ItemCategoryEnum[] | null;

export interface ItemCategoriesContextState {
  itemCategories: ItemCategoriesType;
  loading: LoadingType;
  error: ErrorType;
  categoryLabels: LabelsType;
  usedLabels: LabelsType;
  loadUsedCategoryLabels: () => void;
  loadItemCategories: () => void;
}

const initialContext: ItemCategoriesContextState = {
  categoryLabels: null,
  itemCategories: null,
  usedLabels: null,
  loading: false,
  error: null,
  loadUsedCategoryLabels: () =>
    console.error("You cannot use this hook outside the ItemCategoriesContext Provider"),
  loadItemCategories: () =>
    console.error("You cannot use this hook outside the ItemCategoriesContext Provider")
};

export const ItemCategoriesContext = React.createContext<
  ItemCategoriesContextState
>(initialContext);

interface ItemCategoriesProviderProps {
  appType?: AppTypeEnum;
}

export const ItemCategoriesProvider: React.FC<ItemCategoriesProviderProps> = ({
  appType = AppTypeEnum.PUBLIC,
  children
}) => {
  const { authTokenState } = useAuthenticationContext();
  // Set it to a high value to save requests.
  const pageSize = 100;
  // The API pagination starts with 1.
  const initialItemsPage = 1;

  // Used labels
  const [ usedLabels, setUsedLabels ] = React.useState<LabelsType>(
    initialContext.usedLabels
  );

  // Existing labels.
  const [ categoryLabels, setCategoryLabels ] = React.useState<LabelsType>(
    initialContext.usedLabels
  );

  // Item categories.
  const [ itemCategories, setItemCategories ] = React.useState<ItemCategoriesType>(
    initialContext.itemCategories
  );

  // Set when the component is refresing / loading the categories.
  const [ loading, setLoading ] = React.useState<LoadingType>(
    initialContext.loading
  );

  // Component Error.
  const [ error, setError ] = React.useState<ErrorType>(
    initialContext.error
  );

  // Get Category request.
  const [ getPreregisteredByCategoryResponse, getPreregisteredByCategoryRequest ] = useApiRequest(
    "USERS:getPreregisteredByCategory"
  );

  // Get User Items request. NOTE: This endpoint is needed because getPreregisteredByCategory
  // only returns protected items but not lost or found.
  const [ getUserItemsResponse, getUserItemsRequest ] = useApiRequest(
    appType === AppTypeEnum.PUBLIC ? "ITEMS:getItemsByUser" : "ITEMS:getItemsByBusiness"
  );

  // Current request items page.
  const [ currentItemsPage ] = React.useState<number>(initialItemsPage);

  /**
   * Load the item categories by requesting the information via API.
   */
  const loadItemCategories = React.useCallback(() => {
    if (authTokenState?.userUUID) {
      // Get existing user item categories.
      getPreregisteredByCategoryRequest({
        pathParams: { userUuid: authTokenState.userUUID }
      });
      setLoading(true);
      setError(null);
    } else {
      setError("Authentication failed");
    }
  }, [ authTokenState, getPreregisteredByCategoryRequest ]);

  /**
   * Load the item categories by requesting the information via API.
   */
  const loadCategoryLabels = React.useCallback(() => {
    if (authTokenState?.userUUID) {
      // Get existing user item categories.
      // TODO: This solution will retrieve the categories of the first 100 items.
      // TODO: It looks like a reasonable solution taking into account the current
      // TODO: limitations of the platform to prevent users from adding more than
      // TODO: 12 protected items but this might change in the future. At that point,
      // TODO: I'd expect to have a proper endpoint, similar to getPreregisteredByCategory
      // TODO: including all existing items, not only protected ones.
      getUserItemsRequest({
        pathParams: { userUuid: authTokenState.userUUID },
        params: {
          page: currentItemsPage,
          pageSize
        }
      });
      setLoading(true);
      setError(null);
    } else {
      setError("Authentication failed");
    }
  }, [ authTokenState, getUserItemsRequest, currentItemsPage ]);

  // On load, initalise the item categories.
  React.useEffect(() => {
    loadItemCategories();
  }, [ loadItemCategories ]);

  React.useEffect(() => {
    loadCategoryLabels();
  }, [ loadCategoryLabels ]);

  // Save the used item category labels and existing
  // category labels from the getPreregisteredByCategory
  // API response.
  React.useEffect(() => {
    if (getPreregisteredByCategoryResponse.data) {
      const newCategories = getPreregisteredByCategoryResponse.data;
      const newItemTags: ItemCategoryEnum[] = [];

      if (newCategories && newCategories.length) {
        newCategories.map(itemCategory => {
          newItemTags.push(itemCategory.category);
        });
      }

      setItemCategories(newCategories);
      setCategoryLabels(newItemTags);
      setError(null);
    } else if (getPreregisteredByCategoryResponse.errorMessage) {
      setError(getPreregisteredByCategoryResponse.errorMessage);
    }
    setLoading(false);
  }, [ getPreregisteredByCategoryResponse ]);

  // Save the used category labels from the
  // getItemsByUser API response.
  React.useEffect(() => {
    if (getUserItemsResponse.data && getUserItemsResponse.data.items) {
      const {
        items
      } = getUserItemsResponse.data;

      const newUsedItemTags: ItemCategoryEnum[] = _.uniq(items.map(item => item.category));

      setUsedLabels(newUsedItemTags);
      setError(null);
    } else if (getUserItemsResponse.errorMessage) {
      setError(getUserItemsResponse.errorMessage);
    }

    setLoading(false);
  }, [ getUserItemsResponse ]);

  return (
    <ItemCategoriesContext.Provider
      value={{ loading, loadItemCategories, loadUsedCategoryLabels: loadCategoryLabels, itemCategories, error, usedLabels, categoryLabels }}
    >
      {children}
    </ItemCategoriesContext.Provider>
  );
};

/**
 * useItemCategoriesContext hook for ease
 */
export const useItemCategoriesContext = () => React.useContext(ItemCategoriesContext);
