import React from "react";

import { TouchableHighlightProps } from "react-native";

import { Text } from "../text";
import {
  TouchableHighlight
} from "../touchable-highlight";
import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

import { ButtonProps } from "./MainButton";

interface ButtonLinkProps extends ButtonProps {
  color?: keyof Theme["colors"];
  bold?: boolean;
  underline?: boolean;
  style?: TouchableHighlightProps["style"];
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  label,
  onPress,
  color,
  bold,
  underline,
  style
}) => (
  <ButtonLinkWrapper onPress={onPress} style={style}>
    <ButtonLinkUnderline color={color} underline={underline}>
      <ButtonLinkText color={color} weight={bold ? "bold" : "regular"}>{label}</ButtonLinkText>
    </ButtonLinkUnderline>
  </ButtonLinkWrapper>
);

export default ButtonLink;

const ButtonLinkText = styled(Text)<Pick<ButtonLinkProps, "color">>`
  ${({ theme, color }) => css`
    padding: 10px 30px;
    font-size: ${theme.text.medium};
    color: ${color ? theme.colors[ color ] : theme.colors.fg};
    text-align: left;
    line-height: 30px;
  `}
`;

const ButtonLinkUnderline = styled.View<
  Pick<ButtonLinkProps, "color" | "underline">
>`
  ${({ theme, color, underline }) => css`
    border-bottom-width: ${underline ? 1 : 0}px;
    border-bottom-color: ${color ? theme.colors[ color ] : theme.colors.fg};
  `}
`;

const ButtonLinkWrapper = styled(TouchableHighlight)`
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
