import {
  InsuranceProvider,
  PickParams
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const InsuranceRoutes = {
  /* Fetch insurance providers information */
  "INSURANCE:getProviders": {
    path: "/insurance/providers",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<InsuranceProvider[], null>,

  /* Get insurance provider by id */
  "INSURANCE:getProvider": {
    path: "/insurance/providers/:providerUuid",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<InsuranceProvider, null, PickParams<"providerUuid">>
};

export default InsuranceRoutes;
