import * as React from "react";

import axios from "axios";
import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  AuthenticationResponse,
  Token
} from "./auth-api";

const baseUrl = Constants?.manifest?.extra?.BASE_URL;

export interface AuthenticationContextState {
  authTokenState: AuthenticationResponse | null;
  authenticating: boolean;
  setAuthTokenState: (isAuthenticated: AuthenticationResponse | null) => void;
}

const initialContext: AuthenticationContextState = {
  authTokenState: null,
  authenticating: false,
  setAuthTokenState: () =>
    console.error(
      "You cannot use this hook outside the AuthenticationContext Provider"
    )
};

// Key used to cache auth tokens
export const StorageKey = "AuthToken";

export const AuthenticationContext = React.createContext<
  AuthenticationContextState
>(initialContext);

export const AuthenticationProvider: React.FunctionComponent = ({
  children
}) => {
  // token state management
  const [ authTokenState, setAuthTokenState ] = React.useState<AuthenticationResponse | null>(
    initialContext.authTokenState
  );

  // Loading
  const [ authenticating, setIsAuthenticating ] = React.useState<boolean>(
    initialContext.authenticating
  );

  // get the token state from the cach if its there on first load
  React.useEffect(() => {
    (async () => {
      // initial load
      if (!authTokenState) {
        // check for any cached tokens and set if we have them
        const cachedAuth = await getCachedAuthAsync();
        if (cachedAuth) {
          setAuthTokenState(cachedAuth);
        } else {
          // not logged in
          setIsAuthenticating(false);
        }

        // any changes to the token store back to the cache
      } else {
        await cacheAuthAsync(authTokenState);
        setIsAuthenticating(false);
      }
    })();
  }, [ authTokenState ]);

  return (
    <AuthenticationContext.Provider
      value={{ authTokenState, setAuthTokenState, authenticating }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

/**
 * Store the auth state into the cache
 * so we dont have to login on every app load
 **/
async function cacheAuthAsync(authTokenState: AuthenticationResponse) {
  return await AsyncStorage.setItem(StorageKey, JSON.stringify(authTokenState));
}

/**
 * Retrive the app storage cached auth values
 */
export async function getCachedAuthAsync(): Promise<AuthenticationResponse | null> {
  const value = await AsyncStorage.getItem(StorageKey);
  if (value) {
    const authTokenState: AuthenticationResponse = JSON.parse(value);

    if (authTokenState) {
      if (checkIfTokenExpired(authTokenState.token.expirationTime)) {
        // refresh the auth toke if required
        return refreshAuthAsync(authTokenState);
      } else {
        return authTokenState;
      }
    }
  }

  return null;
}

/** Check the current Auth expiry time */
export function checkIfTokenExpired(accessTokenExpirationDate: number) {
  return new Date(accessTokenExpirationDate) < new Date();
}

export async function refreshAuthAsync(
  authTokenState: AuthenticationResponse
): Promise<AuthenticationResponse | null> {
  try {
    const { data: refreshedAuthState } = await axios.request<Token>({
      url: `${baseUrl}/authentication/tokens`,
      data: authTokenState
    });

    return { ...authTokenState, token: refreshedAuthState };
  } catch (error) {
    return null;
  }
}

/**
 * useAuthenticationContext hook for ease
 */
export const useAuthenticationContext = () => React.useContext(AuthenticationContext);
