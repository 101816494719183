import * as React from "react";

import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import {
  Keyboard,
  View
} from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  DateTimeInput,
  Form,
  FormConfig,
  TextInput
} from "src/component-lib/src/components/form";
import { Image } from "src/component-lib/src/components/image";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  LocationDTO,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import DeleteItemModal from "src/components/DeleteItemModal";

import useFormattedAddressString from "src/hooks/useFormattedAddressString";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

import SFTorchImgSrc from "assets/images/sf-torch.png";

import { isExistingItem } from "src/types/type-guards";

interface AdditionalDetailsFormData {
  dateFound: Date;
  name: string;
  mail: string;
}

const additionalDetailsFormConfig: FormConfig<AdditionalDetailsFormData> = {
  dateFound: {
    controlled: true
  },
  name: null,
  mail: null
};

type AdditionalDetailsScreenProps = FoundItemStackScreenProps<"AdditionalDetailsScreen">;

const AdditionalDetailsScreen: React.FC<AdditionalDetailsScreenProps> = ({ navigation, route }) => {
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const { item, pinLocation } = route.params;

  const { handleSubmit, setValue, control, ...formControls } = useForm<AdditionalDetailsFormData>();

  const [ defaultValues, setDefaultValues ] = React.useState<{ name?: string; mail?: string; }>({});

  // on mount, set form values
  React.useEffect(() => {
    const { dateFound, date, name, mail } = item;

    const foundDate = isExistingItem(item) ? dateFound : date;

    setDefaultValues({
      name,
      mail
    });

    setValue("dateFound", foundDate ? new Date(foundDate) : new Date());
    setValue("name", name || "");
    setValue("mail", mail || "");

  }, [ item, setValue ]);

  const [ businessLocation, setBusinessLocation ] = React.useState<LocationDTO>();

  const [ getUserInfoResponse, getUserInfoRequest ] = useApiRequest("USERS:getUserInfo");

  // get user info on screen focus
  React.useEffect(() => {
    if (authTokenState?.userUUID) {
      getUserInfoRequest({
        pathParams: { userUuid: authTokenState.userUUID }
      });
    }
  }, [ authTokenState, getUserInfoRequest ]);

  React.useEffect(() => {
    let didCancel = false;
    // on failure, show error snack
    if (getUserInfoResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: getUserInfoResponse.errorMessage,
        duration: 5000
      });
      // on success, grab profile pic url
    } else if (getUserInfoResponse.data && !didCancel) {
      setBusinessLocation(getUserInfoResponse.data.locationDTO);
    }

    return () => {
      didCancel = true;
    };
  }, [ getUserInfoResponse, snackbar ]);

  const [ updateItemResponse, updateItemRequest ] = useApiRequest("ITEMS:updateFoundItem");

  const submitForm: SubmitHandler<AdditionalDetailsFormData> = data => {
    Keyboard.dismiss();

    const { dateFound, name, mail } = data;

    const { latitude, longitude } = pinLocation
      ? pinLocation
      : isExistingItem(item)
        ? item
        : businessLocation || {};

    if (item.id && dateFound) {
      updateItemRequest({
        pathParams: { itemUuid: item.id },
        data: {
          latitude,
          longitude,
          date: dateFound.getTime(),
          name,
          mail
        }
      });
    }
  };

  const [ deleteItemModalVisible, setDeleteItemModalVisible ] = React.useState(false);

  const onClose = () => {
    if (isExistingItem(item)) {
      navigation.navigate("MainTabNavigator");
    } else {
      setDeleteItemModalVisible(true);
    }
  };

  React.useEffect(() => {
    if (updateItemResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: updateItemResponse.errorMessage,
        duration: 5000
      });
    } else if (updateItemResponse.data) {
      navigation.navigate("FoundItemConfirmationScreen", { item });
    }
  }, [ item, navigation, snackbar, updateItemResponse ]);

  const scrollViewRef = React.useRef<any>(null);

  // scroll to specific y position
  const scrollTo = (distance: number) => {
    scrollViewRef.current &&
      scrollViewRef.current.getNode().scrollTo({ y: distance, animated: true });
  };

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      height={260}
      title="Additional Details"
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          icon="ios-close"
          color="white"
          size={42}
          onPress={onClose}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Additional Details"
          description="Please provide any additional details here of when or where the item was found and if it was handed in by a member of the public."
        />
      )}
    >
      <Form
        scrollViewRef={scrollViewRef}
        keyboardOffset={340}
        config={additionalDetailsFormConfig}
        validateOnBlur
        setValue={setValue}
        {...formControls}
      >
        <LocationPicker
          location={pinLocation
            ? pinLocation
            : isExistingItem(item)
              ? { latitude: item.latitude, longitude: item.longitude }
              : businessLocation
          }
          onPress={() => navigation.navigate("FoundItemLocationScreen", { item })}
        />
        <FormLabel>
          When found
        </FormLabel>
        <Controller
          render={({ field: { onChange, value: fieldValue } }) => (<DateTimeInput
            date={fieldValue}
            onChange={onChange}
          />)}
          control={control}
          name="dateFound"
        />
        <TextInput
          name="name"
          defaultValue={defaultValues.name}
          label="Finder name (optional)"
          autoCapitalize="words"
          textContentType="name"
          returnKeyType="next"
          blurOnSubmit={false}
          onFocus={() => {
            setTimeout(() => {
              scrollTo(600);
            }, 400);
          }}
          onBlur={() => {
            scrollTo(0);
          }}
        />
        <TextInput
          name="mail"
          defaultValue={defaultValues.mail}
          label="Finder email (optional)"
          autoCapitalize="none"
          textContentType="emailAddress"
          keyboardType="email-address"
          returnKeyType="done"
          blurOnSubmit={false}
          onFocus={() => {
            setTimeout(() => {
              scrollTo(600);
            }, 400);
          }}
          onBlur={() => {
            scrollTo(0);
          }}
        />
        <ImageWrapper pointerEvents="none">
          <Image
            source={SFTorchImgSrc}
            defaultSource={SFTorchImgSrc}
            resizeMode="contain"
          />
        </ImageWrapper>
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label={isExistingItem(item) ? "Save Details" : "Register Item"}
          // style={{ marginTop: 64 }}
        />
      </Form>
      <DeleteItemModal
        title="Oops, we still need a few more details to register this item!"
        isVisible={deleteItemModalVisible}
        onClose={() => setDeleteItemModalVisible(false)}
        itemId={item.id}
        onDelete={() => navigation.navigate("MainTabNavigator")}
        cancelCta="Continue"
        deleteCta="Abandon"
        showSuccessSnack={false}
      />
    </CollapsibleHeaderScrollLayout>
  );
};

export default AdditionalDetailsScreen;

const HeaderContent: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => (
  <HeaderWrapper>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </HeaderWrapper>
);

const HeaderWrapper = styled.View`
  justify-content: center;
  margin-top: 120px;
  position: relative;
  align-self: center;
  margin-left: 24px;
  margin-right: 24px;
`;

interface LocationPickerProps {
  location: LocationDTO | undefined;
  onPress: () => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({ location, onPress }) => {
  const humanReadableLocation = useFormattedAddressString(location);

  return (
    <View style={{ marginBottom: 34 }}>
      <FormLabel>
        Found item location
      </FormLabel>
      <TouchableHighlight onPress={onPress} style={{ borderRadius: 3 }}>
        <LocationPickerWrapper>
          <Text>{humanReadableLocation}</Text>
        </LocationPickerWrapper>
      </TouchableHighlight>
    </View>
  );
};

const FormLabel = styled(Text).attrs({
  color: "dark"
})`
  font-size: 12px;
  margin-bottom: 5px;
`;

const LocationPickerWrapper = styled.View`
  background-color: white;
  padding: 9px;
  border-radius: 3px;
`;

const ImageWrapper = styled.View`
  height: 150px;
  width: 150px;
  align-self: flex-end;
  position: absolute;
  bottom: -12px;
  left: -12px;
  z-index: 1;
`;
