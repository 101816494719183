import React from "react";

import {
  ActivityIndicator,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  ImageURISource,
  StyleProp
} from "react-native";

import styled, { css } from "../../utils/styled-components";

const isURISource = (source?: ImageSourcePropType): source is ImageURISource => (
  !!source
  && typeof source === "object"
  && "uri" in (source as ImageURISource)
);

interface ImageViewProps {
  hasSource: boolean;
}

export interface ImageProps {
  source?: ImageSourcePropType;
  /** REQUIRED: static local placeholder image which shows whilst source is undefined or loading */
  defaultSource: number | ImageURISource;
  resizeMode?: ImageResizeMode;
  style?: StyleProp<ImageStyle>;
}

const Image: React.FC<ImageProps> = ({ style, source, defaultSource, resizeMode }) => {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

  const hasSource = isURISource(source) ? !!source.uri : !!source;

  return (
    <ImageWrapper style={style} hasSource={hasSource}>
      {hasSource && <ImageStyled
        style={style}
        source={source && hasSource ? source : defaultSource}
        // If a source is not provided, use a placeholder image
        defaultSource={defaultSource}
        // onLoadStart={() => setIsLoading(true)}
        onLoad={() => setIsLoading(false)}
        resizeMode={resizeMode}
      />}
      {isLoading && <StyledActivityIndicator size="large" />}
    </ImageWrapper>
  );
};

export default Image;

const ImageWrapper = styled.View<ImageViewProps>`
  ${({ theme, hasSource }) => css`
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: ${hasSource ? "transparent" : theme.colors.bgAccent};
  `}
`;

const ImageStyled = styled.Image`
  width: 100%;
  height: 100%;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  align-self: center;
  z-index: 2;
`;
