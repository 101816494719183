import React from "react";

import { Animated } from "react-native";

import { Text } from "../text";
import styled, { css } from "../../utils/styled-components";

interface TooltipProps {
  show: boolean;
  content: string;
  position?: "bottom" | "top";
}

const Tooltip: React.FC<TooltipProps> = props => {
  const [ springValue ] = React.useState(new Animated.Value(0));

  React.useEffect(() => {
    Animated.spring(springValue, {
      toValue: props.show ? 1 : 0,
      friction: 10,
      useNativeDriver: true
    }).start();
  }, [ props.show, springValue ]);

  return (
    <StyledTooltip position={props.position} style={{ opacity: springValue }}>
      <Text color="bg">{props.content}</Text>
    </StyledTooltip>
  );
};

export default Tooltip;

const StyledTooltip = styled(Animated.View)<{ position?: "bottom" | "top" }>`
  ${({ theme, position }) => css`
    position: absolute;
    display: flex;
    top: 0px;
    transform: ${position === "top" ? "translateY(-45px)" : "translateY(45px)"};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    font-size: ${theme.text.medium};
    color: ${theme.colors.bg};
    padding: 10px;
    background-color: ${theme.colors.fg};
    border-radius: 3px;
    width: 100%;
  `}
`;
