import React from "react";

import {
  Icon,
  IconType
} from "../icon";
import {
  TouchableHighlight
} from "../touchable-highlight";
import styled from "../../utils/styled-components";
import { Theme } from "../../utils/theme";

import { ButtonProps } from "./MainButton";

interface IconButtonProps extends Omit<ButtonProps, "label"> {
  size?: number;
  icon: IconType;
  color?: keyof Theme["colors"];
}

const IconButton: React.FC<IconButtonProps> = ({
  size = 30,
  icon,
  color,
  onPress,
  disabled = false,
  ...rest
}) => (
  <ButtonWrapper
    onPress={!disabled ? onPress : undefined}
    disabled={disabled}
    style={{ height: size, width: size }}
    {...rest}
  >
    <ViewWrapper>
      <Icon icon={icon} size={size} color={color} />
    </ViewWrapper>
  </ButtonWrapper>
);

const ButtonWrapper = styled(TouchableHighlight)`
  border-radius: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const ViewWrapper = styled.View`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default IconButton;
