import { WorkingHours } from "../../hoc/authentication";

export interface AvailableParams {
  userUuid: string;
  itemUuid: string;
  fileUuid: string;
  locationUuid: string;
  deliveryUuid: string;
  providerUuid: string;
  paymentUuid: string;
  matchUuid: string;
  tokenValue: string;
}

export type PickParams<P extends keyof AvailableParams> = Pick<
  AvailableParams,
  P
>;

export interface BasePaginatedQuery {
  page: number;
  pageSize: number;
}

export interface StringResponse {
  message: string;
}

/**
  * @export
  * @enum {string}
  */
export enum InsuranceTypeEnum {
  QUOTE = "QUOTE",
  POLICY = "POLICY"
}

/**
 *
 * @export
 * @interface Insurance
 */
export interface Insurance {
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  ID?: string;
  /**
   *
   * @type {boolean}
   * @memberof Insurance
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  createdBy?: string;
  /**
   *
   * @type {Date}
   * @memberof Insurance
   */
  dateCreated?: Date;
  /**
   *
   * @type {Date}
   * @memberof Insurance
   */
  dateDeleted?: Date;
  /**
   *
   * @type {Date}
   * @memberof Insurance
   */
  dateInsuranceStarted?: Date;
  /**
   *
   * @type {Date}
   * @memberof Insurance
   */
  dateUpdated?: Date;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  deletedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Insurance
   */
  id?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  itemUUID?: string;
  /**
   *
   * @type {boolean}
   * @memberof Insurance
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  policyNumber?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Insurance
   */
  providerId?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  quoteId?: string;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof Insurance
   */
  version?: number;
}

export interface ItemInsuranceRequest extends Omit<Insurance, "providerId"> {
  providerId?: string;
}

/**
 *
 * @export
 * @interface InsuranceProvider
 */
export interface InsuranceProvider {
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  ID?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  base64CallbackUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  base64LogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  base64RedirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
   base64VisitSiteUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  createdBy?: string;
  /**
   *
   * @type {Date}
   * @memberof InsuranceProvider
   */
  dateCreated?: Date;
  /**
   *
   * @type {Date}
   * @memberof InsuranceProvider
   */
  dateDeleted?: Date;
  /**
   *
   * @type {Date}
   * @memberof InsuranceProvider
   */
  dateUpdated?: Date;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  deletedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof InsuranceProvider
   */
  enabled?: boolean;
  /**
   *
   * @type {ObjectId}
   * @memberof InsuranceProvider
   */
  id?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  insuranceDetails?: string;
  /**
   *
   * @type {boolean}
   * @memberof InsuranceProvider
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  providerName?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  smallLetter?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  successDetails?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  successTitle?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof InsuranceProvider
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof InsuranceProvider
   */
  version?: number;
}

/**
 *
 * @export
 * @interface AllItemResponse
 */
export interface AllItemResponse {
  /**
   *
   * @type {Array<ItemDTO>}
   * @memberof AllItemResponse
   */
  foundItemsResponse?: Array<ItemDTO>;
  /**
   *
   * @type {Array<ItemDTO>}
   * @memberof AllItemResponse
   */
  lostItemResponse?: Array<ItemDTO>;
}

/**
 *
 * @export
 * @interface BusinessLocationDTO
 */
export interface BusinessLocationDTO {
  /**
   *
   * @type {Array<LocationDTO>}
   * @memberof BusinessLocationDTO
   */
  address?: Array<LocationDTO>;
  /**
   *
   * @type {string}
   * @memberof BusinessLocationDTO
   */
  postcode?: string;
}
/**
 *
 * @export
 * @interface BusinessLocationRequest
 */
export interface BusinessLocationRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessLocationRequest
   */
  postcode?: string;
}
/**
 * Invitation for an employee fof a company  to join Found
 * @export
 * @interface BusinessUserInvite
 */
export interface BusinessUserInvite {
  /**
   * Email of the user to invite
   * @type {string}
   * @memberof BusinessUserInvite
   */
  email?: string;
}
/**
 * Reports whether or not the user has been invited to join a business.
 * @export
 * @interface BusinessUserInvitedResponse
 */
export interface BusinessUserInvitedResponse {
  /**
   *
   * @type {boolean}
   * @memberof BusinessUserInvitedResponse
   */
  invited?: boolean;
}
/**
 *
 * @export
 * @interface FileResource
 */
export interface FileResource {
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  awsBucket?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  awsKey?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  contentName?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  deletedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof FileResource
   */
  id?: ObjectId;
  /**
   *
   * @type {boolean}
   * @memberof FileResource
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof FileResource
   */
  version?: number;
}
/**
 *
 * @export
 * @interface FileResourceDTO
 */
export interface FileResourceDTO {
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  awsBucket?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  awsKey?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  contentName?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  contentType?: string;
  /**
   *
   * @type {boolean}
   * @memberof FileResourceDTO
   */
  receipt?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface Flags
 */
export interface Flags {
  /**
   *
   * @type {number}
   * @memberof Flags
   */
  flags?: number;
}
/**
 *
 * @export
 * @interface FoundItemMatchDTO
 */
export interface FoundItemMatchDTO {
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  colour?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  dateFound?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FoundItemMatchDTO
   */
  images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof FoundItemMatchDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof FoundItemMatchDTO
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  model?: string;
}

/**
 *
 * @export
 * @interface FoundItemResponse
 */
export interface FoundItemResponse {
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
   color?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  dateFound?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FoundItemResponse
   */
  images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof FoundItemResponse
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof FoundItemResponse
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  mail?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FoundItemResponse
   */
  receipts?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FoundItemResponse
   */
  type?: string;
}

/**
 *
 * @export
 * @interface FoundItemUpdateRequest
 */
export interface FoundItemUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof FoundItemUpdateRequest
   */
  approximate?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof FoundItemUpdateRequest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof FoundItemUpdateRequest
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  mail?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemUpdateRequest
   */
  range?: string;
  /**
   *
   * @type {number}
   * @memberof FoundItemUpdateRequest
   */
  value?: number;
}

/**
 *
 * @export
 * @interface Geocode
 */
export interface Geocode {
  /**
   *
   * @type {number}
   * @memberof Geocode
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof Geocode
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface GrantedAuthority
 */
export interface GrantedAuthority {
  /**
   *
   * @type {string}
   * @memberof GrantedAuthority
   */
  authority?: string;
}
/**
 *
 * @export
 * @interface ImageURLResponse
 */
export interface ImageURLResponse {
  /**
   *
   * @type {string}
   * @memberof ImageURLResponse
   */
  url?: string;
}
/**
 *
 * @export
 * @interface InsuranceSummaryDTO
 */
export interface InsuranceSummaryDTO {
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  insuredPercent?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  insuredTotal?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  totalValue?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  uninsuredTotal?: number;
}
/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {string}
   * @memberof Item
   */
  adminFlags?: ItemAdminFlagsEnum;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  approximate?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  colour?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  createdBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof ItemDTO
   */
   currentInsurance?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateFound?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateLostEnd?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateLostStart?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  deletedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  description?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Item
   */
  foundItemReport?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  foundUuid?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Item
   */
  id?: ObjectId;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Item
   */
  imageFiles?: Array<ObjectId>;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  insured?: boolean;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  latitude?: number;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  longitude?: number;
  /**
   *
   * @type {ObjectId}
   * @memberof Item
   */
  lostItemReport?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  mail?: string;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  matchPending?: boolean;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  range?: string;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  receipt?: boolean;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Item
   */
  receiptFiles?: Array<ObjectId>;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  referenceName?: string;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  timestamps?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  uniqueIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof Item
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof Item
   */
  version?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ItemAdminFlagsEnum {
  REPORTED = "REPORTED",
  BANNED = "BANNED",
  OK = "OK",
  FLAGGED = "FLAGGED",
  ABUSE = "ABUSE",
  NOTACTIVATED = "NOT_ACTIVATED",
  INVITED = "INVITED"
}
/**
 * @export
 * @enum {string}
 */
export enum ItemCategoryEnum {
  ALL = "ALL",
  ANIMALS = "ANIMALS",
  ART = "ART",
  BIKE = "BIKE",
  CAMERA = "CAMERA",
  CLOTHING = "CLOTHING",
  DECOR = "DECOR",
  DISPLAY = "DISPLAY",
  DRONE = "DRONE",
  GLASSES = "GLASSES",
  HANDHELDCONSOLE = "HANDHELD_CONSOLE",
  INSTRUMENTS = "INSTRUMENTS",
  JEWELLERY = "JEWELLERY",
  LAPTOP = "LAPTOP",
  LUGGAGE = "LUGGAGE",
  PHONE = "PHONE",
  SCOOTER = "SCOOTER",
  SHOES = "SHOES",
  SPORTS = "SPORTS",
  WATCH = "WATCH",
  OTHERS = "OTHERS"
}

/**
 *
 * @export
 * @interface ItemCategoryDTO
 */
export interface ItemCategoryDTO {
  /**
   *
   * @type {string}
   * @memberof ItemCategoryDTO
   */
  category: ItemCategoryEnum;
  /**
   *
   * @type {number}
   * @memberof ItemCategoryDTO
   */
  cost: number;
  /**
   *
   * @type {number}
   * @memberof ItemCategoryDTO
   */
  count: number;
}

/**
 *
 * @export
 * @interface ItemDTO
 */
export interface ItemDTO {
  /**
   *
   * @type {number}
   * @memberof ItemDTO
   */
  approximate?: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  brand: string;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  category: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  color: string;
  /**
   *
   * @type {Insurance}
   * @memberof ItemDTO
   */
   currentInsurance?: Insurance;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  date: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  dateFound?: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  dateLostEnd?: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  dateLostStart?: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ItemDTO
   */
  images: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ItemDTO
   */
  insured?: boolean;
  /**
   *
   * @type {number}
   * @memberof ItemDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof ItemDTO
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  mail?: string;
  /**
   *
   * @type {boolean}
   * @memberof ItemDTO
   */
  matchPending?: boolean;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ItemDTO
   */
  receipt: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ItemDTO
   */
  receipts?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ItemDTO
   */
  status: ItemDTOStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ItemDTO
   */
  type?: string;
  /**
   *
   * @type {UserDTO}
   * @memberof ItemDTO
   */
  user?: UserDTO;
  /**
   *
   * @type {number}
   * @memberof ItemDTO
   */
  value?: number;
}

export enum ItemDTOStatusEnum {
  PREREGISTERED = 0,
  LOST = 1,
  FOUND = 2,
  RETURNED = 3,
  PENDING_USER_PREREGISTERED_ITEM = 10,
  PENDING_USER_LOST_ITEM = 11,
  PENDING_USER_FOUND_ITEM = 12
}

/**
 *
 * @export
 * @interface ItemStatusUpdate
 */
export interface ItemStatusUpdate {
  /**
   *
   * @type {MarkLostItemRequest}
   * @memberof ItemStatusUpdate
   */
  lostItemDetails?: MarkLostItemRequest;
  /**
   *
   * @type {number}
   * @memberof ItemStatusUpdate
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof ItemStatusUpdate
   */
  uuid?: string;
}
/**
 *
 * @export
 * @interface ItemStatusUpdateRequest
 */
export interface ItemStatusUpdateRequest {
  /**
   *
   * @type {Array<ItemStatusUpdate>}
   * @memberof ItemStatusUpdateRequest
   */
  statusUpdates?: Array<ItemStatusUpdate>;
}
/**
 *
 * @export
 * @interface JsonNode
 */
export interface JsonNode {
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  array?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  bigDecimal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  bigInteger?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  binary?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _boolean?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  containerNode?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _double?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _float?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  floatingPointNumber?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _int?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  integralNumber?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _long?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  missingNode?: boolean;
  /**
   *
   * @type {string}
   * @memberof JsonNode
   */
  nodeType?: JsonNodeNodeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _null?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  number?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  object?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  pojo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _short?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  textual?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  valueNode?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum JsonNodeNodeTypeEnum {
  ARRAY = "ARRAY",
  BINARY = "BINARY",
  BOOLEAN = "BOOLEAN",
  MISSING = "MISSING",
  Null = "null",
  NUMBER = "NUMBER",
  OBJECT = "OBJECT",
  POJO = "POJO",
  STRING = "STRING"
}

/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address1?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address3?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  adminFlags?: LocationAdminFlagsEnum;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  deletedBy?: string;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Location
   */
  foundItemsReportId?: Array<ObjectId>;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  foundUuid?: string;
  /**
   *
   * @type {Geocode}
   * @memberof Location
   */
  geocode?: Geocode;
  /**
   *
   * @type {ObjectId}
   * @memberof Location
   */
  id?: ObjectId;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Location
   */
  imageFiles?: Array<ObjectId>;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  ipAdress?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  isoCode?: string;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Location
   */
  itemsId?: Array<ObjectId>;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  latitude?: number;
  /**
   *
   * @type {object}
   * @memberof Location
   */
  locationUid?: Record<string, unknown>;
  /**
   *
   * @type {boolean}
   * @memberof Location
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  longitude?: number;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof Location
   */
  lostItemsReportId?: Array<ObjectId>;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  postCode?: string;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  referenceName?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  timestamps?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  type?: LocationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  version?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum LocationAdminFlagsEnum {
  REPORTED = "REPORTED",
  BANNED = "BANNED",
  OK = "OK",
  FLAGGED = "FLAGGED",
  ABUSE = "ABUSE",
  NOTACTIVATED = "NOT_ACTIVATED",
  INVITED = "INVITED"
}
/**
 * @export
 * @enum {string}
 */
export enum LocationTypeEnum {
  PRIMARYADDRESS = "PRIMARY_ADDRESS",
  USER = "USER",
  COMPANYADMIN = "COMPANY_ADMIN",
  ITEM = "ITEM",
  UNKNOWN = "UNKNOWN"
}

/**
 *
 * @export
 * @interface LocationDTO
 */
export interface LocationDTO {
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  address1?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  region?: string;
  /**
   *
   * @type {LocationTypeEnum}
   * @memberof LocationDTO
   */
  type?: LocationTypeEnum;
}
/**
 * New location
 * @export
 * @interface LocationRequest
 */
export interface LocationRequest {
  /**
   * First part of address
   * @type {string}
   * @memberof LocationRequest
   */
  address1: string;
  /**
   * Second part of address
   * @type {string}
   * @memberof LocationRequest
   */
  address2?: string;
  /**
   * City
   * @type {string}
   * @memberof LocationRequest
   */
  city: string;
  /**
   * Country
   * @type {string}
   * @memberof LocationRequest
   */
  country?: string;
  /**
   * Postcode
   * @type {string}
   * @memberof LocationRequest
   */
  postCode: string;
  /**
   * County
   * @type {string}
   * @memberof LocationRequest
   */
  region?: string;
}
/**
 *
 * @export
 * @interface LostFoundMatchDTO
 */
export interface LostFoundMatchDTO {
  /**
   *
   * @type {string}
   * @memberof LostFoundMatchDTO
   */
  finderType?: LostFoundMatchDTOFinderTypeEnum;
  /**
   *
   * @type {FoundItemMatchDTO}
   * @memberof LostFoundMatchDTO
   */
  foundItem?: FoundItemMatchDTO;
  /**
   *
   * @type {string}
   * @memberof LostFoundMatchDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof LostFoundMatchDTO
   */
  lostItemId?: string;
  /**
   *
   * @type {string}
   * @memberof LostFoundMatchDTO
   */
  lostUserId?: string;
  /**
   *
   * @type {PartnerDTO}
   * @memberof LostFoundMatchDTO
   */
  partnerInfo?: PartnerDTO;
  /**
   *
   * @type {PublicUserInfo}
   * @memberof LostFoundMatchDTO
   */
  userInfo?: PublicUserInfo;
}

/**
 * @export
 * @enum {string}
 */
export enum LostFoundMatchDTOFinderTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}

/**
 *
 * @export
 * @interface LostItemRequest
 */
export interface LostItemRequest {
  /**
   *
   * @type {number}
   * @memberof LostItemRequest
   */
  approximate?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  dateEnd?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  dateStart?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof LostItemRequest
   */
  insured?: boolean;
  /**
   *
   * @type {number}
   * @memberof LostItemRequest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof LostItemRequest
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  mail?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof LostItemRequest
   */
  receipt?: boolean;
  /**
   *
   * @type {string}
   * @memberof LostItemRequest
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof LostItemRequest
   */
  value?: number;
}

/**
 *
 * @export
 * @interface LostItemUpdateRequest
 */
export interface LostItemUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof LostItemUpdateRequest
   */
  approximate?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  dateEnd?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  dateStart?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof LostItemUpdateRequest
   */
  insured?: boolean;
  /**
   *
   * @type {number}
   * @memberof LostItemUpdateRequest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof LostItemUpdateRequest
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  mail?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LostItemUpdateRequest
   */
  range?: string;
  /**
   *
   * @type {boolean}
   * @memberof LostItemUpdateRequest
   */
  receipt?: boolean;
  /**
   *
   * @type {number}
   * @memberof LostItemUpdateRequest
   */
  value?: number;
}

/**
 *
 * @export
 * @interface MarkLostItemRequest
 */
export interface MarkLostItemRequest {
  /**
   *
   * @type {string}
   * @memberof MarkLostItemRequest
   */
  dateStart?: number;
  /**
   *
   * @type {string}
   * @memberof MarkLostItemRequest
   */
  dateEnd?: number;
  /**
   *
   * @type {number}
   * @memberof MarkLostItemRequest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof MarkLostItemRequest
   */
  longitude?: number;
}
/**
 *
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  empty?: boolean;
  /**
   *
   * @type {object}
   * @memberof ModelAndView
   */
  model?: Record<string, unknown>;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ModelAndView
   */
  modelMap?: { [key: string]: Record<string, unknown> };
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  reference?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  status?: ModelAndViewStatusEnum;
  /**
   *
   * @type {View}
   * @memberof ModelAndView
   */
  view?: View;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  viewName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ModelAndViewStatusEnum {
  _100 = "100",
  _101 = "101",
  _102 = "102",
  _103 = "103",
  _200 = "200",
  _201 = "201",
  _202 = "202",
  _203 = "203",
  _204 = "204",
  _205 = "205",
  _206 = "206",
  _207 = "207",
  _208 = "208",
  _226 = "226",
  _300 = "300",
  _301 = "301",
  _302 = "302",
  _303 = "303",
  _304 = "304",
  _305 = "305",
  _307 = "307",
  _308 = "308",
  _400 = "400",
  _401 = "401",
  _402 = "402",
  _403 = "403",
  _404 = "404",
  _405 = "405",
  _406 = "406",
  _407 = "407",
  _408 = "408",
  _409 = "409",
  _410 = "410",
  _411 = "411",
  _412 = "412",
  _413 = "413",
  _414 = "414",
  _415 = "415",
  _416 = "416",
  _417 = "417",
  _418 = "418",
  _419 = "419",
  _420 = "420",
  _421 = "421",
  _422 = "422",
  _423 = "423",
  _424 = "424",
  _426 = "426",
  _428 = "428",
  _429 = "429",
  _431 = "431",
  _451 = "451",
  _500 = "500",
  _501 = "501",
  _502 = "502",
  _503 = "503",
  _504 = "504",
  _505 = "505",
  _506 = "506",
  _507 = "507",
  _508 = "508",
  _509 = "509",
  _510 = "510",
  _511 = "511"
}

/**
 *
 * @export
 * @interface NorthEast
 */
export interface NorthEast {
  /**
   *
   * @type {number}
   * @memberof NorthEast
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof NorthEast
   */
  lng?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationTypeEnum {
  MATCH = "LOST_ITEM_MATCH",
  INFO = "INFO"
}

/**
 *
 * @export
 * @interface NotificationDataDTO
 */
export interface NotificationDataDTO {
  /**
   *
   * @type {NotificationTypeEnum}
   * @memberof NotificationDataDTO
   */
  type: NotificationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationDataDTO
   */
  matchID?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDataDTO
   */
  text?: string;
}

/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
  /**
   *
   * @type {NotificationDataDTO}
   * @memberof NotificationDTO
   */
  data?: NotificationDataDTO;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  userId?: string;
}
/**
 *
 * @export
 * @interface ObjectId
 */
export interface ObjectId {
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  counter?: number;
  /**
   *
   * @type {string}
   * @memberof ObjectId
   */
  date?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  machineIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  processIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  time?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timeSecond?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timestamp?: number;
}
/**
 *
 * @export
 * @interface PaginatedCollectionResponseItemDTO
 */
export interface PaginatedCollectionResponseItemDTO {
  /**
   *
   * @type {Array<ItemDTO>}
   * @memberof PaginatedCollectionResponseItemDTO
   */
  items: Array<ItemDTO>;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseItemDTO
   */
  itemsCount: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseItemDTO
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseItemDTO
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseItemDTO
   */
  total: number;
}
/**
 *
 * @export
 * @interface PaginatedCollectionResponseUserDTO
 */
export interface PaginatedCollectionResponseUserDTO {
  /**
   *
   * @type {Array<UserDTO>}
   * @memberof PaginatedCollectionResponseUserDTO
   */
  items?: Array<UserDTO>;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseUserDTO
   */
  itemsCount?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseUserDTO
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseUserDTO
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCollectionResponseUserDTO
   */
  total?: number;
}
/**
 *
 * @export
 * @interface PartnerDTO
 */
export interface PartnerDTO {
  /**
   *
   * @type {string}
   * @memberof PartnerDTO
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof PartnerDTO
   */
  addressId: string | null;
  /**
   *
   * @type {string}
   * @memberof PartnerDTO
   */
  businessName: string;
  /**
   *
   * @type {string}
   * @memberof PartnerDTO
   */
  website: string;
  /**
   *
   * @type {Geocode}
   * @memberof PartnerDTO
   */
  geocode: Geocode;
  /**
   *
   * @type {string}
   * @memberof PartnerDTO
   */
  id: string | null;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof PartnerDTO
   */
  workingHours: Array<WorkingHours>;
}
/**
 *
 * @export
 * @interface PartnersMapRequest
 */
export interface PartnersMapRequest {
  /**
   *
   * @type {NorthEast}
   * @memberof PartnersMapRequest
   */
  NorthEast?: NorthEast;
  /**
   *
   * @type {SouthWest}
   * @memberof PartnersMapRequest
   */
  SouthWest?: SouthWest;
}
/**
 *
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  newPassword?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  newPasswordConfirm?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  newPasswordConfirmMasked?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  newPasswordMasked?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  oldPassword?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChangeRequest
   */
  oldPasswordMasked?: string;
}
/**
 *
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordResetRequest
   */
  appType?: PasswordResetRequestAppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PasswordResetRequest
   */
  email?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PasswordResetRequestAppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}

/**
 *
 * @export
 * @interface PreRegisteredItemResponse
 */
export interface PreRegisteredItemResponse {
  /**
   *
   * @type {Array<ItemDTO>}
   * @memberof PreRegisteredItemResponse
   */
  preRegisteredItems?: Array<ItemDTO>;
}
/**
 *
 * @export
 * @interface PublicUserInfo
 */
export interface PublicUserInfo {
  /**
   *
   * @type {string}
   * @memberof PublicUserInfo
   */
  emailAddress?: string;
}
/**
 *
 * @export
 * @interface RegularUserRequest
 */
export interface RegularUserRequest {
  /**
   *
   * @type {boolean}
   * @memberof RegularUserRequest
   */
  acceptMarketing: boolean;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  appType: RegularUserRequestAppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  businessType?: string;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  deviceToken: string;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  deviceType: RegularUserRequestDeviceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  email: string;
  /**
   *
   * @type {LocationDTO}
   * @memberof RegularUserRequest
   */
  locationDTO?: LocationDTO;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof RegularUserRequest
   */
  organization?: boolean;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  password: string;

  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  website?: string;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof RegularUserRequest
   */
  workingHours?: Array<WorkingHours>;
  /**
   *
   * @type {string}
   * @memberof RegularUserRequest
   */
  zipcode?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RegularUserRequestAppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}
/**
 * @export
 * @enum {string}
 */
export enum RegularUserRequestDeviceTypeEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  DEFAULT = "DEFAULT"
}

/**
 *
 * @export
 * @interface ResponseId
 */
export interface ResponseId {
  /**
   *
   * @type {string}
   * @memberof ResponseId
   */
  id?: string;
}
/**
 *
 * @export
 * @interface RestAPIError
 */
export interface RestAPIError {
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  type?: RestAPIErrorTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RestAPIErrorTypeEnum {
  AUTHENTICATION = "AUTHENTICATION",
  AUTHORIZATION = "AUTHORIZATION",
  VALIDATION = "VALIDATION",
  PERSISTENCE = "PERSISTENCE",
  BUSINESS = "BUSINESS",
  GENERAL = "GENERAL"
}

/**
 *
 * @export
 * @interface RoleUpdateRequest
 */
export interface RoleUpdateRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof RoleUpdateRequest
   */
  roles?: Array<UserDTORolesEnum>;
}

/**
 *
 * @export
 * @interface SlotsRequestResponse
 */
export interface SlotsRequestResponse {
  /**
   *
   * @type {number}
   * @memberof SlotsRequestResponse
   */
  slots?: number;
}

/**
 *
 * @export
 * @interface SouthWest
 */
export interface SouthWest {
  /**
   *
   * @type {number}
   * @memberof SouthWest
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof SouthWest
   */
  lng?: number;
}
/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  /**
   *
   * @type {string}
   * @memberof Token
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  deletedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  expirationTime?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  id?: ObjectId;
  /**
   *
   * @type {boolean}
   * @memberof Token
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  updatedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  userID?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  value?: string;
  /**
   *
   * @type {number}
   * @memberof Token
   */
  version?: number;
}
/**
 *
 * @export
 * @interface TokenDTO
 */
export interface TokenDTO {
  /**
   *
   * @type {number}
   * @memberof TokenDTO
   */
  expirationTime?: number;
  /**
   *
   * @type {number}
   * @memberof TokenDTO
   */
  timeToLive?: number;
  /**
   *
   * @type {string}
   * @memberof TokenDTO
   */
  value?: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  acceptMarketing?: boolean;
  /**
   *
   * @type {Flags}
   * @memberof User
   */
  accesControlFlags?: Flags;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  accountNonExpired?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  accountNonLocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  adminFlags?: UserAdminFlagsEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  appType?: UserAppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  authProvider?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  authUuid?: string;
  /**
   *
   * @type {Array<GrantedAuthority>}
   * @memberof User
   */
  authorities?: Array<GrantedAuthority>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  awsUuid?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  businessType?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  createdBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  credentialsNonExpired?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  dateLastActive?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  deletedBy?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  deviceType?: UserDeviceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  emailAddress?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  foundUuid?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof User
   */
  id?: ObjectId;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof User
   */
  itemsId?: Array<ObjectId>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof User
   */
  locationsId?: Array<ObjectId>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  preferenceFlags?: UserPreferenceFlagsEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  roles?: Array<UserRolesEnum>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  salt?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  slots?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  socialId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  socialMediaToken?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  timestamps?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  type?: UserTypeEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  typeFlag?: UserTypeFlagEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  updatedBy?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  usedSlots?: number;
  /**
   *
   * @type {Array<ObjectId>}
   * @memberof User
   */
  userMessagesId?: Array<ObjectId>;
  /**
   *
   * @type {ObjectId}
   * @memberof User
   */
  userProfilePicture?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  website?: string;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof User
   */
  workingHours?: Array<WorkingHours>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserAdminFlagsEnum {
  REPORTED = "REPORTED",
  BANNED = "BANNED",
  OK = "OK",
  FLAGGED = "FLAGGED",
  ABUSE = "ABUSE",
  NOTACTIVATED = "NOT_ACTIVATED",
  INVITED = "INVITED"
}
/**
 * @export
 * @enum {string}
 */
export enum UserAppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}
/**
 * @export
 * @enum {string}
 */
export enum UserDeviceTypeEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  DEFAULT = "DEFAULT"
}
/**
 * @export
 * @enum {string}
 */
export enum UserPreferenceFlagsEnum {
  DEFAULT = "DEFAULT",
  MARKETING = "MARKETING",
  NOTIFICATIONS = "NOTIFICATIONS"
}
/**
 * @export
 * @enum {string}
 */
export enum UserRolesEnum {
  USER = "USER",
  COMPANYADMIN = "COMPANY_ADMIN",
  COMPANY = "COMPANY",
  ENDUSERMANAGEMENT = "END_USER_MANAGEMENT",
  PARTNERMANAGEMENT = "PARTNER_MANAGEMENT",
  PROPERTYMANAGEMENT = "PROPERTY_MANAGEMENT",
  FOUNDSTAFFMANAGEMENT = "FOUND_STAFF_MANAGEMENT",
  TEMPORARYINDB = "TEMPORARY_IN_DB"
}
/**
 * @export
 * @enum {string}
 */
export enum UserTypeEnum {
  FACEBOOK = "FACEBOOK",
  REGULAR = "REGULAR",
  DEFAULT = "DEFAULT",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  GOOGLEPLUS = "GOOGLE_PLUS"
}
/**
 * @export
 * @enum {string}
 */
export enum UserTypeFlagEnum {
  STANDARD = "STANDARD",
  API = "API",
  PARTNER = "PARTNER"
}

/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  acceptMarketing?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  appType?: UserDTOAppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  businessType?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  dateLastActivity?: number;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  itemsLost?: number;
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  itemsRegistered?: number;
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  itemsReturned?: number;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastName?: string;
  /**
   *
   * @type {LocationDTO}
   * @memberof UserDTO
   */
  locationDTO?: LocationDTO;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  oneSignalEmailIdToken?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  oneSignalExternalIdToken?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  phoneNumber?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserDTO
   */
  roles?: Array<UserDTORolesEnum>;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  type?: UserDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  userProfilePicture?: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  website?: string;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof UserDTO
   */
  workingHours?: Array<WorkingHours>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserDTOAppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}
/**
 * @export
 * @enum {string}
 */
export enum UserDTORolesEnum {
  USER = "USER",
  COMPANYADMIN = "COMPANY_ADMIN",
  COMPANY = "COMPANY",
  ENDUSERMANAGEMENT = "END_USER_MANAGEMENT",
  PARTNERMANAGEMENT = "PARTNER_MANAGEMENT",
  PROPERTYMANAGEMENT = "PROPERTY_MANAGEMENT",
  FOUNDSTAFFMANAGEMENT = "FOUND_STAFF_MANAGEMENT",
  TEMPORARYINDB = "TEMPORARY_IN_DB"
}
/**
 * @export
 * @enum {string}
 */
export enum UserDTOTypeEnum {
  FACEBOOK = "FACEBOOK",
  REGULAR = "REGULAR",
  DEFAULT = "DEFAULT",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  GOOGLEPLUS = "GOOGLE_PLUS"
}

/**
 *
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
  /**
   *
   * @type {boolean}
   * @memberof UserUpdateRequest
   */
  acceptMarketing?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  birthdate?: Date;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  businessType?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  lastName?: string;
  /**
   *
   * @type {LocationDTO}
   * @memberof UserUpdateRequest
   */
  locationDTO?: LocationDTO;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  website?: string;
  /**
   *
   * @type {Array<WorkingHours>}
   * @memberof UserUpdateRequest
   */
  workingHours?: Array<WorkingHours>;
}
/**
 *
 * @export
 * @interface View
 */
export interface View {
  /**
   *
   * @type {string}
   * @memberof View
   */
  contentType?: string;
}

/**************************************** DELIVERY TYPINGS ****************************************/

/**
 * Details about a requested delivery
 * @export
 * @interface DeliveryDTO
 */
export interface DeliveryDTO {
  /**
   * The date the delivery was created
   * @type {string}
   * @memberof DeliveryDTO
   */
  dateCreated?: string;
  /**
   * UUID for the delivery
   * @type {string}
   * @memberof DeliveryDTO
   */
  id?: string;
  /**
   * The UUID of the item being delivered
   * @type {string}
   * @memberof DeliveryDTO
   */
  itemId?: string;
  /**
   * The ID of the payment used to pay for an executed delivery.
   * @type {string}
   * @memberof DeliveryDTO
   */
  paymentId?: string;
  /**
   * The logistics provider handling the delivery
   * @type {string}
   * @memberof DeliveryDTO
   */
  providerId?: DeliveryDTOProviderIdEnum;
  /**
   * The ID of the quote that the delivery executes
   * @type {string}
   * @memberof DeliveryDTO
   */
  quoteId?: string;
  /**
   * Indicates whether or not the delivery has been booked or just quoted.
   * @type {string}
   * @memberof DeliveryDTO
   */
  status?: DeliveryDTOStatusEnum;
  /**
   * The UUID of the user requesting the delivery
   * @type {string}
   * @memberof DeliveryDTO
   */
  userId?: string;
}

/**
  * @export
  * @enum {string}
  */
export enum DeliveryDTOProviderIdEnum {
  NDS = "NDS"
}
/**
  * @export
  * @enum {string}
  */
export enum DeliveryDTOStatusEnum {
  QUOTED = "QUOTED",
  BOOKED = "BOOKED"
}

/**
*
* @export
* @interface DeliveryOptionInfo
*/
export interface DeliveryOptionInfo {
  /**
   *
   * @type {string}
   * @memberof DeliveryOptionInfo
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryOptionInfo
   */
  notes?: string;
}
/**
* Request for a delivery from a previous quote
* @export
* @interface DeliveryRequest
*/
export interface DeliveryRequest {
  /**
   * UUID of the payment used to pay for the delivery
   * @type {string}
   * @memberof DeliveryRequest
   */
  paymentId: string;
  /**
   * Quote ID returned from a previous call to /quote
   * @type {string}
   * @memberof DeliveryRequest
   */
  quoteId: string;
}
/**
*
* @export
* @interface EstimatedDateTime
*/
export interface EstimatedDateTime {
  /**
   * Latest estimated time
   * @type {string}
   * @memberof EstimatedDateTime
   */
  max?: string;
  /**
   * Earliest estimated time
   * @type {string}
   * @memberof EstimatedDateTime
   */
  min?: string;
}
/**
* Details about a quote
* @export
* @interface QuoteDTO
*/
export interface QuoteDTO {
  /**
   *
   * @type {DeliveryOptionInfo}
   * @memberof QuoteDTO
   */
  deliveryOption?: DeliveryOptionInfo;
  /**
   *
   * @type {EstimatedDateTime}
   * @memberof QuoteDTO
   */
  estimatedDeliveryTime?: EstimatedDateTime;
  /**
   * ID of the generated quote
   * @type {string}
   * @memberof QuoteDTO
   */
  id?: string;
  /**
   * The amount the user will pay for this delivery if they go ahead
   * @type {number}
   * @memberof QuoteDTO
   */
  price?: number;
}
/**
* Request for a quote for a delivery
* @export
* @interface QuoteRequest
*/
export interface QuoteRequest {
  /**
   * UUID of the address to deliver to
   * @type {string}
   * @memberof QuoteRequest
   */
  deliveryLocation: string;
  /**
   * UUID of the item being delivered
   * @type {string}
   * @memberof QuoteRequest
   */
  itemId: string;
  /**
   * Size of the package
   * @type {string}
   * @memberof QuoteRequest
   */
  packageSize: QuoteRequestPackageSizeEnum;
  /**
   * UUID of the user who has found the item
   * @type {string}
   * @memberof QuoteRequest
   */
  partnerId: string;
  /**
   * UUID of the address to pick up the item from
   * @type {string}
   * @memberof QuoteRequest
   */
  pickupLocation: string;
}

/**
  * @export
  * @enum {string}
  */
export enum QuoteRequestPackageSizeEnum {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}

/**
*
* @export
* @interface QuoteResponse
*/
export interface QuoteResponse {
  /**
   *
   * @type {Array<QuoteDTO>}
   * @memberof QuoteResponse
   */
  quotes?: Array<QuoteDTO>;
}

/**************************************** PAYMENT TYPINGS ****************************************/

/**
* Payment details
* @export
* @interface PaymentDTO
*/
export interface PaymentDTO {
  /**
   * The date the payment was created
   * @type {string}
   * @memberof PaymentDTO
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDTO
   */
  id?: string;
  /**
   * The ID assigned to the user by the payment provider.  This field will be empty until the payment has been approved
   * @type {string}
   * @memberof PaymentDTO
   */
  payerId?: string;
  /**
   * The ID assigned to the payment by the payment provider
   * @type {string}
   * @memberof PaymentDTO
   */
  paymentId?: string;
  /**
   * The payment provider handling the payment
   * @type {string}
   * @memberof PaymentDTO
   */
  provider?: PaymentDTOProviderEnum;
  /**
   * A list of timestamped status updates.  The last entry on the list, with the most recent timestamp, will indicate the current status of the payment
   * @type {Array<PaymentTransaction>}
   * @memberof PaymentDTO
   */
  transactions?: Array<PaymentTransaction>;
  /**
   * The ID of the user making the payment
   * @type {string}
   * @memberof PaymentDTO
   */
  userId?: string;
}

/**
  * @export
  * @enum {string}
  */
export enum PaymentDTOProviderEnum {
  PAYPAL = "PAYPAL"
}

/**
* Request to create a payment
* @export
* @interface PaymentRequest
*/
export interface PaymentRequest {
  /**
   * URL that the user will be returned to if the payment approval is declined or fails.  Only required if the client is using full page redirects to perform authorization
   * @type {string}
   * @memberof PaymentRequest
   */
  cancelUrl?: string;
  /**
   * 3 digit currency code of the payment
   * @type {string}
   * @memberof PaymentRequest
   */
  currency: string;
  /**
   * Description of the item
   * @type {string}
   * @memberof PaymentRequest
   */
  description?: string;
  /**
   * Provider handling the payment
   * @type {string}
   * @memberof PaymentRequest
   */
  provider: PaymentRequestProviderEnum;
  /**
   * URL that the user will be returned to once the payment has been approved.  From this point, the payment can be executed.  Only required if the client is using full page redirects to perform authorization
   * @type {string}
   * @memberof PaymentRequest
   */
  returnUrl?: string;
  /**
   * Shipping cost
   * @type {number}
   * @memberof PaymentRequest
   */
  shipping?: number;
  /**
   * Cost of item
   * @type {number}
   * @memberof PaymentRequest
   */
  subTotal: number;
  /**
   * Cost of any applicable tax
   * @type {number}
   * @memberof PaymentRequest
   */
  tax?: number;
}

/**
  * @export
  * @enum {string}
  */
export enum PaymentRequestProviderEnum {
  PAYPAL = "PAYPAL"
}

/**
 * Response to a POST call to the /payment endpoint to create a new payment
 * @export
 * @interface CreatePaymentResponse
 */
export interface CreatePaymentResponse {
  /**
   * Token used when initiating the payment approval process.  For Paypal, can be passed to Paypal\'s client-side SDK
   * @type {string}
   * @memberof CreatePaymentResponse
   */
  approvalToken?: string;
  /**
   * URL to use to approve the payment.  Once approved, it can be executed by a call to /payment/{payment-id}/execute.  Only use if returnUrl and cancelUrl were sent in the request.  Otherwise use the approvalToken
   * @type {string}
   * @memberof CreatePaymentResponse
   */
  approvalUrl?: string;
  /**
   *
   * @type {PaymentDTO}
   * @memberof CreatePaymentResponse
   */
  payment?: PaymentDTO;
}

/**
* An update to the status of a payment
* @export
* @interface PaymentTransaction
*/
export interface PaymentTransaction {
  /**
   * The time at which the status of the payment was changed
   * @type {string}
   * @memberof PaymentTransaction
   */
  dateTime?: string;
  /**
   * The status that the payment was changed to at the time of the transaction
   * @type {string}
   * @memberof PaymentTransaction
   */
  status?: PaymentTransactionStatusEnum;
}

/**
  * @export
  * @enum {string}
  */
export enum PaymentTransactionStatusEnum {
  CREATED = "CREATED",
  EXECUTED = "EXECUTED"
}

/**
* Response to a request to validate a payment
* @export
* @interface VerifyPaymentResponse
*/
export interface VerifyPaymentResponse {
  /**
   *
   * @type {PaymentDTO}
   * @memberof VerifyPaymentResponse
   */
  payment?: PaymentDTO;
  /**
   * Whether or not the payment is valid.  true indicates that the payment has been executed and the details in the Found database match those stored by the payment provider.  false indicates either the payment details could not be retrieved from the payment provider, key details do not match, or that the payment has not been executed.
   * @type {boolean}
   * @memberof VerifyPaymentResponse
   */
  verified?: boolean;
}
