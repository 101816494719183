import React from "react";

import RNModal, { ModalProps as RNModalProps } from "react-native-modal";

import {
  Button,
  ButtonProps,
  IconButton,
  MainButtonProps
} from "../button";
import { Icon } from "../icon";
import { Text } from "../text";
import styled, { css } from "../../utils/styled-components";

// allows all default react-native-modal props to be passed through
export interface ModalProps extends Partial<RNModalProps> {
  title?: string;
  onClose: () => void; // handle close
  buttons: {
    confirm: ButtonProps & { type?: MainButtonProps["type"] }; // by default will use success button
    reject?: ButtonProps & { type?: MainButtonProps["type"] }; // by default will use error button
  };
  scrollable?: boolean; // if true will render a ScrollableView for the content
  tip?: string;
}

/**
 * Modal component with some default wrapped styles
 */
const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  buttons: { confirm, reject },
  title,
  tip,
  scrollable,
  ...rest
}) => {
  const ContentInnerScrollOrNot: React.ComponentType = scrollable
    ? ScrollContentInner
    : ContentWrapper;

  return (
    <RNModal {...rest} onDismiss={onClose} onBackdropPress={onClose}>
      <ContentInner>
        <IconContainer>
          <IconButton
            size={32}
            onPress={onClose}
            icon="ios-close"
            color="white"
          />
        </IconContainer>
        {!!title && (
          <Text align="center" type="h2">
            {title}
          </Text>
        )}
        {children && (
          <ContentInnerScrollOrNot>
            {children}
          </ContentInnerScrollOrNot>
        )}
        <ButtonsWrapper buttons={{ confirm, reject }}>
          <StyledButton
            {...confirm}
            type={confirm.type || "success"}
          />
          {!!reject && (
            <StyledButton
              {...reject}
              type={reject.type || "error"}
            />
          )}
        </ButtonsWrapper>
      </ContentInner>
      {!!tip && (
        <TipWrapper>
          <Icon icon="info-outline" size={28} color="white" />
          <TipText align="left" type="h2" color="white">
            {tip}
          </TipText>
        </TipWrapper>
      )}
    </RNModal>
  );
};

export default Modal;

const ButtonsWrapper = styled.View<Pick<ModalProps, "buttons">>`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ buttons }) =>
    buttons.reject ? "space-between" : "center"};
  margin-top: 18px;
`;

const StyledButton = styled(Button)`
  padding: 6px;
  min-width: 100px;
  align-items: center;
  margin-top: 12px;
`;

const ContentWrapper = styled.View`
  margin-top: 20px;
`;

const IconContainer = styled.View`
  position: absolute;
  top: -14px;
  right: -14px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.red};
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
`;

const ContentInner = styled.View`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.bg};
    width: 90%;
    align-self: center;
    border-radius: ${theme.borderRadius.default};
    padding: 30px;
  `}
`;

const TipWrapper = styled(ContentInner)`
  margin-top: 12px;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
`;

const TipText = styled(Text)`
  margin-left: 9px;
  flex: 1;
`;

const ScrollContentInner = styled.ScrollView`
  ${({ theme }) => css`
    background: ${theme.colors.bg};
    width: 90%;
    height: 80%;
    align-self: center;
    border-radius: ${theme.borderRadius.default};
    padding: 30px;
  `}
`;
