import * as React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import useNetworkStatusAlerts from "src/component-lib/src/hooks/network-status/useNetworkStatusAlerts";

import FakeSplash from "src/screens/shared/FakeSplash";

import AuthorizedNavigator, {
  AuthorizedStackParamList
} from "src/navigation/authorized/AuthorizedNavigator";
import UnauthorizedNavigator, {
  UnauthorizedStackParamList
} from "src/navigation/unauthorized/UnauthorizedNavigator";

export type SplashStackParamList = {
  SplashScreen: undefined;
  MainStack: AuthorizedStackParamList;
  UnauthorizedStack: UnauthorizedStackParamList;
};

const SplashStack = createStackNavigator<SplashStackParamList>();

/**
 * SplashNavigator
 */
const SplashNavigator: React.FC = () => {
  const { authTokenState } = useAuthenticationContext();

  useNetworkStatusAlerts();

  return (
    <NavigationContainer>
      <SplashStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>
        <SplashStack.Screen name="SplashScreen" component={FakeSplash} />
        {authTokenState == null ? (
          <SplashStack.Screen
            name="UnauthorizedStack"
            component={UnauthorizedNavigator}
            options={{
              animationTypeForReplace: "pop",
              gestureEnabled: false,
              cardStyleInterpolator: ({ current }) => ({
                containerStyle: {
                  opacity: current.progress
                }
              })
            }}
          />
        ) : (
          <SplashStack.Screen
            name="MainStack"
            component={AuthorizedNavigator}
            options={{
              gestureEnabled: false,
              cardStyleInterpolator: ({ current }) => ({
                containerStyle: {
                  opacity: current.progress
                }
              })
            }}
          />
        )}
      </SplashStack.Navigator>
    </NavigationContainer>
  );
};

export default SplashNavigator;
