import * as React from "react";

import { useNavigation } from "@react-navigation/native";

import { IconButton } from "src/component-lib/src/components/button";
import { ImageCircle } from "src/component-lib/src/components/image-circle";
import { Modal } from "src/component-lib/src/components/modal";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";
import styled, { css } from "src/component-lib/src/utils/styled-components";

import { buildImageSource } from "src/utils/images";
import Axios from "axios";
import { Platform } from "react-native";
import useItemImages from "src/component-lib/src/utils/api/useItemImage";

// TODO: potentially move into component lib

interface ItemImageProps {
  itemId?: string;
  imageId?: string;
  editable?: boolean;
  onPress?: () => void;
  onDeleted: (imageUuid: string) => void;
}

const ItemImage: React.FC<ItemImageProps> = ({
  itemId,
  imageId,
  editable = false,
  onPress,
  onDeleted
}) => {
  const snackbar = useSnackbarContext();
  const { authTokenState } = useAuthenticationContext();

  const [ deleteImageModalVisible, setDeleteImageModalVisible ] = React.useState(false);

  const [ deleteItemImageResponse, deleteItemImageRequest ] = useApiRequest("ITEMS:deleteItemImage");

  const imageSrcs = useItemImages(authTokenState, [imageId]);
  // const [ getImageResponse, getImageRequest ] = useApiRequest("FILES:getFile");

  const onDeleteConfirm = () => {
    if (itemId && imageId) {
      deleteItemImageRequest({
        pathParams: {
          itemUuid: itemId,
          fileUuid: imageId
        }
      });
    }
  };

  const navigation = useNavigation();

  // React.useEffect(() => {
  //   if (getImageResponse.errorMessage) {
  //     snackbar.show({
  //       type: "error",
  //       text: getImageResponse.errorMessage,
  //       duration: 5000
  //     });
  //   } else if (getImageResponse.data) {
  //     console.log(getImageResponse.data);
  //     setImageSrc(getImageResponse.data.file);
  //   }
  // }, [getImageResponse]);

  React.useEffect(() => {
    if (itemId && imageId) {
      if (deleteItemImageResponse.errorMessage) {
        setDeleteImageModalVisible(false);
        snackbar.show({
          type: "error",
          text: deleteItemImageResponse.errorMessage,
          duration: 5000
        });
      } else if (deleteItemImageResponse.data) {
        setDeleteImageModalVisible(false);
        onDeleted(imageId);
      }
    }
  }, [ snackbar, deleteItemImageResponse, itemId, imageId, onDeleted ]);

  return (
    <ImageWrapper>
      <ImageCircle
        source={Platform.OS === "web" ? imageSrcs[0] : buildImageSource(authTokenState?.token.value, imageId)}
        // source={imageSrc}
        defaultSource={require("assets/images/icon.png")}
        onPress={!editable ? onPress : undefined}
      />
      {editable && (
        <>
          <IconWrapper>
            <IconButton
              onPress={() => setDeleteImageModalVisible(true)}
              icon="ios-remove"
              size={24}
              color="white"
            />
          </IconWrapper>
          <Modal
            title="Are you sure you wish to delete this image?"
            isVisible={deleteImageModalVisible}
            onClose={() => setDeleteImageModalVisible(false)}
            buttons={{
              reject: {
                type: "tertiary",
                label: "Cancel",
                onPress: () => setDeleteImageModalVisible(false),
                style: { width: "100%" }
              },
              confirm: {
                type: "error",
                label: "Delete",
                onPress: onDeleteConfirm,
                style: { width: "100%" }
              }
            }}
          />
        </>
      )}
    </ImageWrapper>
  );
};

export default ItemImage;

const ImageWrapper = styled.View`
  margin-right: 9px;
  position: relative;
  padding-top: 4px;
`;

const IconWrapper = styled.View`
  ${({ theme }) => css`
    background: ${theme.colors.red};
    width: 20px;
    height: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: -2px;
  `}
`;
