import React from "react";

type UsePrevious = <T>(value: T) => T | undefined;

const usePrevious: UsePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<typeof value>();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [ value ]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
