import React from "react";

import * as _ from "lodash";
import { KeyboardAvoidingView, Platform, View } from "react-native";

import {
  useIsFocused,
  useScrollToTop
} from "@react-navigation/native";

import { Button } from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import {
  useItemCategoriesContext
} from "src/component-lib/src/hoc/dynamic-category-provider";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  ItemCategoryEnum,
  ItemDTO,
  useApiRequest
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import ProfilePicMiniHeader from "src/components/home/ProfilePicMiniHeader";
import ProtectedItemsList from "src/components/home/ProtectedItemsList";

import { sentenceCase } from "src/utils/strings/sentence-case";

import {
  MainTabScreenProps
} from "src/navigation/authorized/MainTabNavigator/MainTabNavigator";

import ConveyerBeltImgSrc from "assets/images/conveyer-belt.png";
import AddFoundItem from "./AddFoundItem";
import { useUserInfo } from "src/hooks/useUserInfo";
import OneSignal from "react-native-onesignal";
import { useTrackingPermissions } from "expo-tracking-transparency";

const screenName = "HomeScreen";

const Home: React.FC<MainTabScreenProps<"HomeScreen">> = ({ navigation }) => {
  const { authTokenState } = useAuthenticationContext();
  const { userInfo } = useUserInfo();
  const [ trackingPermissionStatus, requestTrackingPermission ] = useTrackingPermissions();

  const {
    error: errorItemCategories,
    usedLabels: usedCategoryLabels,
    loadUsedCategoryLabels
  } = useItemCategoriesContext();

  // We'll leave the API default value (30)
  const pageSize = 30;
  const [ currentPage, setCurrentPage ] = React.useState<number>(1);
  const [ currentItems, setCurrentItems ] = React.useState<ItemDTO[]>([]);
  const [ moreItemsToDisplay, setMoreItemsToDisplay ] = React.useState<boolean>(false);
  const [ itemsLoaded, setItemsLoaded ] = React.useState<boolean>(false);

  const [ itemTags, setItemTags ] =  React.useState<ItemCategoryEnum[]>([]);
  const [ selectedTag, setSelectedTag ] = React.useState<ItemCategoryEnum | "lost" | "found">(ItemCategoryEnum.ALL);

  const snackbar = useSnackbarContext();

  const [ getUserItemsResponse, getUserItemsRequest ] = useApiRequest("ITEMS:getItemsByBusiness");

  React.useEffect(() => {
    OneSignal.addTrigger("screen", "HomeScreen");
  }, []);

  React.useEffect(() => {
    if (
      authTokenState?.userUUID
      && userInfo?.oneSignalEmailIdToken
      && userInfo?.oneSignalExternalIdToken
      && userInfo?.emailAddress) {

      OneSignal.setEmail(userInfo.emailAddress, userInfo.oneSignalEmailIdToken, () => {
        OneSignal.setExternalUserId(authTokenState.userUUID);
      });

    }
  }, [
    authTokenState?.userUUID,
    userInfo,
    userInfo?.emailAddress,
    userInfo?.oneSignalEmailIdToken,
    userInfo?.oneSignalExternalIdToken
  ]);

  React.useEffect(() => {
    const showiOSTrackingConsentWindow = async () => {
      await requestTrackingPermission();
    };

    showiOSTrackingConsentWindow();
  }, [ requestTrackingPermission ]);

  // Shows error if there is a problem loading the categories.
  React.useEffect(() => {
    if (errorItemCategories) {
      snackbar.show({
        type: "error",
        text: errorItemCategories,
        duration: 5000
      });
    }
  }, [ errorItemCategories, snackbar ]);

  // When the existing context item categories are changed,
  // ensure the tags are correct (just display tags
  // used by registered items).
  React.useEffect(() => {
    let newItemTags: ItemCategoryEnum[] = [];

    if (usedCategoryLabels && usedCategoryLabels.length) {
      // sort alphabetically and remove "ALL" category
      newItemTags = _.sortBy(usedCategoryLabels).filter(cat => cat !== ItemCategoryEnum.ALL);
    }

    setItemTags(newItemTags);
  }, [ usedCategoryLabels ]);

  const screenIsFocused = useIsFocused();
  // Refresh context item categories when screen is focused.
  React.useEffect(() => {
    if (screenIsFocused) {
      loadUsedCategoryLabels();
    }
  }, [ loadUsedCategoryLabels, screenIsFocused ]);

  // Get preregistered items
  // refreshes on screen focus
  React.useEffect(() => {
    if (
      screenIsFocused
      && authTokenState
      && authTokenState.userUUID
    ) {
      getUserItemsRequest({
        pathParams: { userUuid: authTokenState.userUUID },
        params: {
          page: currentPage,
          pageSize
        }
      });
    }
  }, [
    screenIsFocused,
    authTokenState,
    selectedTag,
    getUserItemsRequest,
    currentPage
  ]);

  React.useEffect(() => {
    if (getUserItemsResponse.data) {
      const {
        items,
        itemsCount,
        total
      } = getUserItemsResponse.data;

      if (items && items.length !== undefined) {
        setCurrentItems(items);

        if (itemsCount === total || items.length === 0) {
          setMoreItemsToDisplay(false);
        }
      }

      setItemsLoaded(true);

    } else if (getUserItemsResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: getUserItemsResponse.errorMessage,
        duration: 5000
      });
    }
  }, [ getUserItemsResponse, snackbar ]);

  const handleFound = React.useCallback(() => {
    navigation.navigate("FoundItemStack", {
      screen: "FoundItemScreen",
      params: undefined
    });
  }, [ navigation ]);

  const containerRef = React.useRef<any>(null);

  // scroll to top on tab bar icon press
  useScrollToTop(containerRef);

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      title="Registered Items"
      height={160}
      containerRef={containerRef}
      HeaderLeft={ProfilePicMiniHeader}
    >
      <View>
        {!!itemTags.length && (
          <TagsWrapper>
            <StyledTagButton
              type={selectedTag === ItemCategoryEnum.ALL ? "active-tag" : "tag"}
              label="All"
              small
              onPress={() => setSelectedTag(ItemCategoryEnum.ALL)}
            />
            {itemTags.map((tag: ItemCategoryEnum, i) => (
              <StyledTagButton
                key={i}
                type={selectedTag === tag ? "active-tag" : "tag"}
                label={sentenceCase(tag.replace("_", " "))}
                small={true}
                onPress={() => setSelectedTag(tag)}
              />
            ))}
          </TagsWrapper>
        )}

        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"}>
          <ProtectedItemsList
            protectedItems={currentItems}
            selectedTag={selectedTag}
            onItemSelect={item => navigation.navigate("ItemDetailsStack", {
              screen: "ItemDetailsScreen",
              params: { item }
            })}
          ><AddFoundItem onPress={handleFound} /></ProtectedItemsList>
        </KeyboardAvoidingView>

        {!itemTags.length && (
          <ConveyerImage source={ConveyerBeltImgSrc} defaultSource={ConveyerBeltImgSrc} />
        )}
        {moreItemsToDisplay && itemTags.length && (
          <Button
            type="secondary"
            onPress={() => setCurrentPage(currentPage + 1)}
            label="Load more"
          />
        )}
      </View>
    </CollapsibleHeaderScrollLayout>
  );
};

const StyledTagButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

const TagsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const ConveyerImage = styled.Image`
  margin-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
  height: 300px;
`;

export default Home;
