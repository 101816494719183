import React from "react";

import styled, { css } from "../../../utils/styled-components";
import { Picker } from "@react-native-picker/picker";

export interface PickerProps {
  onValueChange: (value: SelectItem) => void;
  selectedValue?: SelectItem;
  items: SelectItem[];
  label: string;
  confirmText?: string;
}

interface SelectItem {
  label: string;
  value: string;
}

const Select: React.FC<PickerProps> = props => {
  const onChange = (_value: unknown, index: number) => {
    props.onValueChange(props.items[ index ]);
  };

  return (
      <StyledPicker
        mode="dialog"
        prompt={props.label}
        selectedValue={props.selectedValue?.value || props.label}
        onValueChange={onChange}
      >
        {props.items.map((item, i) => (
          <StyledPicker.Item key={i} label={item.label} value={item.value} />
        ))}
      </StyledPicker>
  );
};

const StyledPicker = styled(Picker)`
  ${({ theme }) => css`
    border-color: ${theme.colors.neutralAccent};
    padding: 10px;
    border-width: 1px;
    font-family: ${theme.font.regular};
    font-size: ${theme.text.medium};
    background-color: ${theme.colors.bgAccent};
    border-radius: 4px;
  `}
`;

export default Select;

declare global {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  let _select: typeof Select;
}
