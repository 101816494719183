import * as React from "react";

import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  UseFormReturn
} from "react-hook-form";
import {
  Animated,
  findNodeHandle,
  Keyboard,
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
  TextInputSubmitEditingEventData,
  ViewStyle
} from "react-native";

import { FormRow } from "../form-row";
import { FormRowError } from "../form-row-error";
import { Label } from "../label";
import {
  TextInput,
  TextInputProps
} from "../text-input";

interface InputRef {
  name: string;
  el: RNTextInput;
}

/** Optional config for each field in the form */
interface FormFieldConfig {
  /** Required for components that are wrapped in a <Controller /> to avoid re-registering */
  controlled?: boolean;
  /** react-hook-form validation config */
  validation?: RegisterOptions;
}

/** Define config (or null) for each field in the form */
export type FormConfig<FormData> = Record<keyof FormData, FormFieldConfig | null>;

interface FormProps {
  style?: ViewStyle;
  config: FormConfig<Record<string, string>>;
  validateOnBlur?: boolean;
  keyboardAware?: boolean;
  keyboardOffset?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrollViewRef?: React.MutableRefObject<any>; // Animated.ScrollView is typed as any
  // react-hook-form controls
  register: UseFormReturn<any>["register"];
  setValue: UseFormReturn<any>["setValue"];
  trigger: UseFormReturn<any>["trigger"];
  formState: UseFormReturn<any>["formState"];
  focusInputOnMount?: string;
  // require >1 child (otherwise, just use controlled TextInput)
  children: React.ReactNode[];
}

const Form: React.FC<FormProps> = ({
  style,
  config,
  validateOnBlur = false,
  keyboardAware = true,
  keyboardOffset = 360,
  scrollViewRef,
  register,
  setValue,
  trigger,
  formState,
  focusInputOnMount,
  children
}) => {
  // create keyboard aware animated view
  const animatedMarginBottom = React.useRef(new Animated.Value(24));

  React.useEffect(() => {
    if (keyboardAware) {
      const addMargin = () => {
        Animated.spring(animatedMarginBottom.current, {
          toValue: keyboardOffset,
          useNativeDriver: false
        }).start();
      };

      const removeMargin = () => {
        Animated.spring(animatedMarginBottom.current, {
          toValue: 24,
          useNativeDriver: false
        }).start();
      };

      Keyboard.addListener("keyboardWillShow", addMargin);
      Keyboard.addListener("keyboardWillHide", removeMargin);

      return () => {
        Keyboard.removeListener("keyboardWillShow", addMargin);
        Keyboard.removeListener("keyboardWillHide", removeMargin);
      };
    }
  }, [ keyboardAware, keyboardOffset ]);

  // register inputs on mount
  React.useEffect(() => {
    Object.keys(config).forEach(field => {
      const options = config[ field  ];
      // controlled fields are pre-registered
      // only need to custom register uncontrolled fields
      if (!options?.controlled) {
        register(field, options?.validation);
      }
    });
  }, [ config, register ]);

  const inputRefs = React.useRef<InputRef[]>([]);

  const getInputRefIndex = (name: string) => inputRefs.current.findIndex(ref => ref.name === name);

  // inject scroll and react-hook-form functionality into uncontrolled TextInput children
  const wrapInputWithProps = React.useCallback((
    inputEl: React.ReactElement<TextInputProps>
  ) => {
    const { name = "", ...props } = inputEl.props;

    // capture native TextInput ref
    const setInputRef = (el: RNTextInput) => {
      // TextInput element ref stored alongside form field name to avoid ref duplication
      // b/c this fn is called on every render (hence new ref generated)
      const inputReference = {
        name,
        el
      };

      // check if ref for this field already exists
      const refIndex = getInputRefIndex(name);
      // if already exists, overwrite with ref of new el
      if (refIndex > -1) {
        inputRefs.current[ refIndex ] = inputReference;
      // otherwise add to ref array
      } else {
        inputRefs.current.push(inputReference);
      }
    };

    const onChangeText = (value: string) => {
      // update react-hook-form input value
      setValue(name, value);
      // trigger passed onChangeText handler if present
      if (props.onChangeText) {
        props.onChangeText(value);
      }
    };

    // scroll to specific input using ref
    const scrollToInput = (el: RNTextInput) => {
      if (scrollViewRef && scrollViewRef.current) {
        // get scroll view node
        const scrollViewNode = scrollViewRef.current.getNode();
        // get position of input relative to scroll view parent
        el.measureLayout(
          findNodeHandle(scrollViewNode) || 0,
          // pass calculated position to scroll callback
          (_x, y) => {
            // 130 offset aligns scroll position with form label
            const offsetY = y - 130;
            // scroll to input
            scrollViewNode.scrollTo({ y: offsetY, animated: true });
          },
          // on measurement fail param required
          () => {
            if (__DEV__) {
              console.warn("input measurement failed");
            }
          }
        );
      }
    };

    // scroll to input on focus
    const onFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const input = inputRefs.current[ getInputRefIndex(name) ].el;
      scrollToInput(input);
      // trigger passed onFocus handler if present
      if (props.onFocus) {
        props.onFocus(e);
      }
    };

    const onBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (validateOnBlur) {
        trigger(name);
      }
      // trigger passed onBlur handler if present
      if (props.onBlur) {
        props.onBlur(e);
      }
    };

    // focus next input on submit, blur for final input
    const onSubmitEditing = (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
      // validate on submit
      trigger(name);

      const refIndex = getInputRefIndex(name);

      const currentInput = inputRefs.current[ refIndex ];
      const nextInput = inputRefs.current[ refIndex + 1 ];

      if (nextInput) {
        nextInput.el.focus();
      } else {
        currentInput.el.blur();
      }
      // trigger passed onSubmitEditing handler if present
      if (props.onSubmitEditing) {
        props.onSubmitEditing(e);
      }
    };

    const getReturnKeyType = () => {
      if (props.returnKeyType) {
        return props.returnKeyType;
      }
      const nextInputExists = !!inputRefs.current[ getInputRefIndex(name) + 1 ];

      return nextInputExists ? "next" : "done";
    };

    // clone base input element and inject props
    return React.cloneElement(inputEl, {
      inputRef: setInputRef,
      onChangeText,
      onFocus,
      onBlur,
      onSubmitEditing,
      returnKeyType: getReturnKeyType()
    });
  }, [ scrollViewRef, setValue, validateOnBlur, trigger ]);

  React.useEffect(() => {
    if (focusInputOnMount) {
      const index = getInputRefIndex(focusInputOnMount);

      if (index > -1 && inputRefs.current.length > 0) {
        const input = inputRefs.current[ index ].el;

        input.focus();
      }
    }
  }, [ focusInputOnMount ]);

  return (
    <Animated.View style={{ ...style, marginBottom: animatedMarginBottom.current }}>
      {children.map(Child => {
        if (Child && typeof Child === "object" && "props" in Child) {
          const { name, label } = Child.props;

          // for react-hook-form Controllers, just wrap in FormRow and handle errors
          // can't check for Child.type on Controller, so look for control prop instead
          if (name && Child.props.control) {
            return (
              <FormRow key={name}>
                {Child}
                <FormRowError text={formState.errors[ name ]?.message || ""} />
              </FormRow>
            );
          }

          // only inject react-hook-form props into TextInputs if "name" prop present and config for field is specified
          if (name && name in config && Child.type === TextInput) {
            // assume "name" prop only present on TextInput children
            const Input = Child as React.ReactElement<TextInputProps>;

            // wrap input with form row, add label and error components
            return (
              <FormRow key={name}>
                {label && <Label text={label} />}
                {wrapInputWithProps(Input)}
                <FormRowError text={formState.errors[ name ]?.message || ""} />
              </FormRow>
            );
          }
        }

        // if name prop not present / not a ReactElement, just render unaltered Child
        return Child;
      })}
    </Animated.View>
  );
};

export default Form;
