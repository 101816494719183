import React from "react";

import ConfirmationView from "src/components/ConfirmationView";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

type FoundItemConfirmationScreenProps = FoundItemStackScreenProps<"FoundItemConfirmationScreen">;

/**
 * Image processing screen
 */
const FoundItemConfirmationScreen: React.FC<FoundItemConfirmationScreenProps> = ({ navigation, route }) => {
  const { item } = route.params;

  return (
    <ConfirmationView
      title="Thank you for registering the item you found!"
      subtitle="We will try to locate the owner of the item and will let you know as soon as we do so!"
      item={item}
      ctaTitle="Back to homepage"
      onCtaPress={() => navigation.navigate("MainTabNavigator", { screen: "HomeScreen", params: undefined })}
    />
  );
};

export default FoundItemConfirmationScreen;
