import { words } from "./readable-id-wordlist";

/*
NB: this function increases the chance of collision from a raw UUID greatly.
It should only be used by users to help each other communicate about an item.
It should not be used as a persisted ID.
*/
export const readableId = (uuid: string | undefined | null, numberOfWords: number = 4) => {
  if (!uuid) {
    throw new Error("An argument for uuid must be supplied");
  }

  const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);

  if (!isUUID) {
    throw new Error(`String: ${uuid} is not a valid UUID`);
  }

  const readableVersion = Number.parseInt(uuid.split("-").pop() || "", 16)
    .toString(32)
    .toUpperCase();

  // const uuidChunks = uuid
  //   .replaceAll("-", "")
  //   .split("")
  //   .reverse()
  //   .join("")
  //   .match(/.{1,3}/g);

  // const readableVersion = uuidChunks
  //   ?.slice(0, numberOfWords)
  //   .map(chunk => parseInt(chunk, 16))
  //   .map(idx => words[idx])
  //   .join("-");

  return readableVersion;
}