import React from "react";

import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import { View } from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  FormConfig,
  TextInput
} from "src/component-lib/src/components/form";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  PasswordResetRequestAppTypeEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

interface ForgotPasswordFormData {
  email: string;
}

const forgotPasswordFormConfig: FormConfig<ForgotPasswordFormData> = {
  email: {
    validation: {
      required: {
        value: true,
        message: "Email is required"
      },
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid Email Format"
      }
    }
  }
};

type ForgotPasswordScreenProps = UnauthorizedScreenProps<"ForgotPassword">;

const ForgotPassword: React.FC<ForgotPasswordScreenProps> = ({ navigation, route }) => {
  const { handleSubmit, getValues, ...formControls } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: route?.params?.email || ""
    }
  });

  const snackbar = useSnackbarContext();

  const [ resetPasswordResponse, resetPasswordRequest ] = useApiRequest("USERS:passwordResetRequest");

  /**
   * @param data Submit the form
   */
  const submitForm: SubmitHandler<ForgotPasswordFormData> = React.useCallback(data => {
    resetPasswordRequest({
      data: {
        email: data.email,
        appType: PasswordResetRequestAppTypeEnum.PUBLIC
      }
    });
  }, [ resetPasswordRequest ]);

  React.useEffect(() => {
    if (resetPasswordResponse.data) {
      const { email } = getValues();
      navigation.navigate("PasswordEmailSent", { email });
    } else if (resetPasswordResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: resetPasswordResponse.errorMessage,
        duration: 5000
      });
    }
  }, [ resetPasswordResponse, snackbar, navigation, getValues ]);

  return (
    <FullScreenLayout
      title="Your details"
      scrollable
      HeaderLeft={() => (
        <IconButton
          color="white"
          icon="chevron-left"
          onPress={() => navigation.goBack()}
          size={35}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Forgot Password"
          description="You will be sent an email with instructions to reset your password!"
        />
      )}
      HeaderBackgroundComponent={p => {
        return <BackgroundView {...p} gradient="business" />;
      }}
    >
      <Form
        config={forgotPasswordFormConfig}
        validateOnBlur
        {...formControls}
        style={{ flex: 1, justifyContent: "space-between" }}
      >
        <TextInput
          name="email"
          label="Email"
          textContentType="emailAddress"
          keyboardType="email-address"
          returnKeyType="done"
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Send Password Reset Email"
          style={{ marginTop: 12 }}
        />
      </Form>
    </FullScreenLayout>
  );
};

export default ForgotPassword;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);
