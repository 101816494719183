import React from "react";

import {
  Platform,
  Share
} from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import { BackgroundView } from "src/component-lib/src/components/view";
import {
  FoundItemResponse,
  ItemDTO
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import SFShapesImgSrc from "assets/images/business-sf-shapes.png";

interface ConfirmationViewProps {
  title: string;
  subtitle?: string;
  ImageSrc?: number;
  item?: ItemDTO | FoundItemResponse;
  ctaTitle: string;
  onCtaPress: () => void;
  onCancel?: () => void;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({
  title,
  subtitle,
  ImageSrc = SFShapesImgSrc,
  item,
  ctaTitle,
  onCtaPress,
  onCancel = onCtaPress
}) => {
  const onShare = () => {
    Share.share(
      {
        title: "Share",
        message: Platform.OS !== "ios" ? "https://found.cloud" : undefined,
        url: "https://found.cloud"
      },
      {
        subject: "Lost & Found app",
        dialogTitle: "Share",
        excludedActivityTypes: [
          "com.apple.UIKit.activity.Print",
          "com.apple.UIKit.activity.CopyToPasteboard",
          "com.apple.UIKit.activity.AssignToContact",
          "com.apple.UIKit.activity.SaveToCameraRoll",
          "com.apple.UIKit.activity.AddToReadingList",
          "com.apple.UIKit.activity.OpenInIBooks",
          "com.apple.UIKit.activity.MarkupAsPDF",
          "com.apple.reminders.RemindersEditorExtension", //Reminders
          "com.apple.mobilenotes.SharingExtension", // Notes
          "com.apple.mobileslideshow.StreamShareService" // iCloud Photo Sharing
        ]
      }
    );
  };

  return (
    <FullScreenLayout
      HeaderLeft={() => (
        <IconButton
          icon="ios-close"
          color="white"
          size={42}
          onPress={onCancel}
        />
      )}
      BackgroundComponent={p => (
        <BackgroundView
          {...p}
          gradient="business"
          start={[ 0.25, 0.4 ]}
          end={[ 1.5, 0.75 ]}
        />
      )}
    >
      <Text type="h1" color="white" align="center">
        {title}
      </Text>
      <StyledImage
        source={ImageSrc}
        defaultSource={ImageSrc}
        resizeMode="contain"
      />
      {!!subtitle && (
        <Text type="h2" color="white" align="center" style={{ marginHorizontal: 18 }}>
          {subtitle}
        </Text>
      )}
      <Spacer />
      <Button
        type={item ? "primary" : "tertiary"}
        label={ctaTitle}
        onPress={onCtaPress}
        darkModeAware={false}
        // style={{ marginBottom: 24 }}
      />
    </FullScreenLayout>
  );
};

export default ConfirmationView;

const StyledImage = styled(Image)`
  background-color: transparent;
  width: 250px;
  height: 250px;
  align-self: center;
  margin-top: 24px;
  margin-bottom: 18px;
`;

const Spacer = styled.View`
  flex: 1;
`;
