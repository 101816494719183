import * as React from "react";

import {
  CompositeNavigationProp,
  RouteProp
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp
} from "@react-navigation/stack";

import NotificationsScreen
  from "src/screens/authorized/notifications/Notifications";

import { CompositeNavigation } from "src/types/navigation";

import { MainTabCompositeNavigationProps } from "./MainTabNavigator";
export type NotificationsStackParamList = {
  NotificationScreen: undefined;
};

const NotificationsStack = createStackNavigator<NotificationsStackParamList>();

const Notifications: React.FC<NotificationsStackNavigationProps> = () => (
  <NotificationsStack.Navigator screenOptions={{ headerShown: false, cardStyle: {flex: 1} }}>
    <NotificationsStack.Screen
      name="NotificationScreen"
      component={NotificationsScreen}
    />
  </NotificationsStack.Navigator>
);

export default Notifications;

export type NotificationsStackCompositeNavigationProps = CompositeNavigationProp<
  StackNavigationProp<NotificationsStackParamList, "NotificationScreen">,
  MainTabCompositeNavigationProps
>;

export type NotificationsStackScreenProps<
  T extends keyof NotificationsStackParamList
> = CompositeNavigation<
  RouteProp<NotificationsStackParamList, T>,
  NotificationsStackCompositeNavigationProps
>;

type NotificationsStackNavigationProps = CompositeNavigation<
  RouteProp<NotificationsStackParamList, "NotificationScreen">,
  NotificationsStackCompositeNavigationProps
>;
