import React from "react";

import { StyleSheet } from "react-native";

import { useIsFocused } from "@react-navigation/native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import { TextInput } from "src/component-lib/src/components/form";
import { Icon } from "src/component-lib/src/components/icon";
import { ImageCircle } from "src/component-lib/src/components/image-circle";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  TouchableHighlight
} from "src/component-lib/src/components/touchable-highlight";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  useApiRequest,
  UserDTO
} from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";

type AccountMembersScreenProps = SettingsStackScreenProps<"AccountMembersScreen">;

const AccountMembersScreen: React.FC<AccountMembersScreenProps> = ({ navigation }) => {
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();

  const [ currentPage, setCurrentPage ] = React.useState<number>(1);

  // use default
  const pageSize = 30;

  const [ searchValue, setSearchValue ] = React.useState<string>("");

  const updateSearchValue = (value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  const screenIsFocused = useIsFocused();

  const [ accountMembersResponse, accountMembersRequest ] = useApiRequest("USERS:getUsersByBusiness");

  React.useEffect(() => {
    if (authTokenState?.userUUID && screenIsFocused) {
      accountMembersRequest({
        pathParams: {
          userUuid: authTokenState.userUUID
        },
        params: {
          page: 1,
          // keep single page and increase page size
          // allows us to replace entire result set without having to merge old with new query results
          // keeps behaviour correct and consistent between load more presses and search query changes
          pageSize: pageSize * currentPage,
          query: searchValue
        }
      });
    }
  }, [ accountMembersRequest, authTokenState?.userUUID, currentPage, searchValue, screenIsFocused ]);

  const [ accountMembers, setAccountMembers ] = React.useState<UserDTO[]>();

  React.useEffect(() => {
    if (accountMembersResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: accountMembersResponse.errorMessage,
        duration: 5000
      });
    } else if (accountMembersResponse.data) {
      const membersExcludingSelf = accountMembersResponse.data.items?.filter(
        member => member.id !== authTokenState?.userUUID
      );

      setAccountMembers(membersExcludingSelf);
    }
  }, [ accountMembersResponse, authTokenState?.userUUID, searchValue, snackbar ]);

  const canLoadMore = accountMembersResponse.data && accountMembersResponse.data.total
    ? accountMembersResponse.data.total - 1 > currentPage * pageSize
    : false;

  const scrollViewRef = React.useRef<any>(null);

  return (
    <CollapsibleHeaderScrollLayout
      noPadding
      height={300}
      gradientType="business"
      title="Account Members"
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          style={{ height: 42, width: 34 }}
          icon="ios-arrow-back"
          color="white"
          size={28}
          onPress={() => navigation.goBack()}
        />
      )}
      HeaderRight={() => (
        <Button
          label="Invite"
          onPress={() => navigation.navigate("InviteMemberScreen")}
          type="plain"
          darkModeAware={false}
          style={{ marginRight: -20 }}
        />
      )}
      HeaderForegroundComponent={React.useCallback(() => (
        <HeaderContent
          title="Account Members"
          description="Tap on a member to view and manage their account"
          updateSearchValue={updateSearchValue}
        />
      ), [])}
    >
      {accountMembers && accountMembers.length > 0
        ? accountMembers.map(user => (
          <MemberListItem
            key={user.id}
            user={user}
            onPress={() => navigation.navigate("MemberDetailsScreen", { user })}
          />
        )) : (
          <NoResultsWrapper>
            <Text type="placeholder" style={{ textAlign: "center" }}>
              {!accountMembers || accountMembersResponse.loading
                ? "Loading..."
                : accountMembersResponse.errorMessage
                  ? "Failed to fetch member list. Please try again later."
                  : "No account members found. Invite a team member to your business using the button above."
              }
            </Text>
          </NoResultsWrapper>
        )
      }
      { canLoadMore && (
        <LoadMoreButton
          label="Load More"
          onPress={() => setCurrentPage(page => page + 1)}
          type="tertiary"
        />
      )}
    </CollapsibleHeaderScrollLayout>
  );
};

export default AccountMembersScreen;

interface HeaderContentProps {
  title: string;
  description: string;
  updateSearchValue: (value: string) => void;
}

const HeaderContent: React.FC<HeaderContentProps> = ({
  title,
  description,
  updateSearchValue
}) => {
  const [ query, setQuery ] = React.useState<string>("");

  return (
    <HeaderWrapper>
      <Text type="h1" color="white">
        {title}
      </Text>
      <Text style={{ marginTop: 12 }} color="white">
        {description}
      </Text>
      <SearchBarWrapper>
        <TextInput
          value={query}
          onChangeText={val => {
            setQuery(val);
            if (!val) {
              updateSearchValue("");
            }
          }}
          clearButtonMode="always"
          autoCorrect={false}
          autoCapitalize="none"
          placeholder="Search"
          returnKeyType="search"
          onSubmitEditing={() => updateSearchValue(query)}
        />
        {!query && (
          <SearchIcon />
        )}
      </SearchBarWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.View`
  margin-top: 130px;
  padding-left: 26px;
  padding-right: 26px;
`;

const SearchBarWrapper = styled.View`
  margin-top: 18px;
  margin-left: -2px;
  margin-right: -2px;
`;

const SearchIcon = styled(Icon).attrs({
  icon: "magnifying-glass",
  size: 18
})`
  position: absolute;
  top: 10px;
  right: 9px;
`;

const NoResultsWrapper = styled.View`
  padding: 48px;
  justify-content: center;
  align-items: center;
`;

const LoadMoreButton = styled(Button)`
  margin-top: 18px;
  margin-left: 18px;
  margin-right: 18px;
`;

interface MemberListItemProps {
  user: UserDTO;
  onPress: () => void;
}

const MemberListItem: React.FC<MemberListItemProps> = ({ user, onPress }) => {
  const { authTokenState } = useAuthenticationContext();

  return (
    <TouchableHighlight onPress={onPress}>
      <ListItemWrapper>
        <MemberPictureWrapper>
          <ImageCircle
            source={{
              uri: user.userProfilePicture,
              headers: {
                Authorization: authTokenState?.token.value || ""
              }
            }}
            defaultSource={require("assets/images/icon.png")}
          />
        </MemberPictureWrapper>
        <MemberEmailLabel numberOfLines={1} ellipsizeMode="middle">
          {user.emailAddress}
        </MemberEmailLabel>
        <ChevronIcon />
      </ListItemWrapper>
    </TouchableHighlight>
  );
};

const ListItemWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  height: 72px;
  margin: 0 18px;
  padding: 18px 6px;
  border-bottom-width: ${StyleSheet.hairlineWidth}px;
  border-bottom-color: ${({ theme }) => theme.colors.fg}66;
`;

const MemberPictureWrapper = styled.View`
  align-self: stretch;
`;

const MemberEmailLabel = styled(Text)`
  margin-left: 16px;
  flex: 1;
`;

const ChevronIcon = styled(Icon).attrs({
  icon: "chevron-right",
  color: "fg",
  size: 24
})`
  margin-left: 6px;
  margin-right: -6px;
`;
