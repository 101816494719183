import * as React from "react";

import * as _ from "lodash";
import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { Keyboard } from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  Select,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";
import {
  useItemCategoriesContext
} from "src/component-lib/src/hoc/dynamic-category-provider";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import {
  ItemCategoryEnum,
  useApiRequest
} from "src/component-lib/src/utils/api";

import DeleteItemModal from "src/components/DeleteItemModal";
import ItemImagesHeader from "src/components/ItemImagesHeader";

import { sentenceCase } from "src/utils/strings/sentence-case";

import {
  FoundItemStackScreenProps
} from "src/navigation/authorized/FoundItemStack";

import {
  itemDetailsFormConfig,
  ItemDetailsFormData
} from "./foundItemDetailsFormConfig";

import { isExistingItem } from "src/types/type-guards";

type CategorySelectOption = ItemDetailsFormData["category"];

type FoundItemDetailsScreenProps = FoundItemStackScreenProps<"FoundItemDetailsScreen">;

const FoundItemDetailsScreen: React.FC<FoundItemDetailsScreenProps> = ({ navigation, route }) => {
  const scrollViewRef = React.useRef<any>(null);
  const { categoryLabels } = useItemCategoriesContext();
  const [ categories, setCategories ] = React.useState<CategorySelectOption[]>([]);

  const { handleSubmit, setValue, control, ...formControls } = useForm<ItemDetailsFormData>();

  const { item } = route.params;

  // Set the item category options
  React.useEffect(() => {
    const newCategories: CategorySelectOption[] = [];

    categoryLabels?.map(usedCategoryLabel => {
      if (usedCategoryLabel !== ItemCategoryEnum.ALL) {
        newCategories.push({
          label: sentenceCase(usedCategoryLabel.replace("_", " ")),
          value: usedCategoryLabel
        });
      }
    });

    setCategories(_.sortBy(newCategories, "label"));
  }, [ categoryLabels ]);

  // pre-fill form values
  // N.B. this does not visually update the value of the TextInputs as they are uncontrolled
  // these can instead be set by passing item.<property> into the defaultValue prop of the input
  React.useEffect(() => {
    const matchedCategory = categories.find(cat => cat.value === item.category) || categories[ 0 ];

    const color = isExistingItem(item) ? item.color : "";

    setValue("category", matchedCategory);
    setValue("brand", item.brand || "");
    setValue("model", item.model || "");
    setValue("color", color);
    setValue("description", item.description || "");
  }, [ item, setValue, categories ]);

  const snackbar = useSnackbarContext();

  const [ updateItemResponse, updateItemRequest ] = useApiRequest("ITEMS:updateFoundItem");

  const submitForm: SubmitHandler<ItemDetailsFormData> = data => {
    Keyboard.dismiss();

    if (item.id && data.category) {
      updateItemRequest({
        pathParams: { itemUuid: item.id },
        data: {
          ...data,
          category: data.category.value
        }
      });
    }
  };

  // on update response
  React.useEffect(() => {
    if (updateItemResponse.data && item.id) {
      // navigate to location chooser
      // response is just a string so need to pass item info from previous screen
      // N.B. this means the `item` param will not contain any changes made on this screen
      // this is fine, however, as subsequent screens do not rely on the item details data
      navigation.navigate("AdditionalDetailsScreen", { item, pinLocation: undefined });
    } else if (updateItemResponse.errorMessage) {
      snackbar.show({
        type: "error",
        text: updateItemResponse.errorMessage,
        duration: 5000
      });
    }
  }, [ item, navigation, snackbar, updateItemResponse ]);

  const [ deleteItemModalVisible, setDeleteItemModalVisible ] = React.useState(false);

  const onClose = () => {
    if (isExistingItem(item)) {
      navigation.navigate("MainTabNavigator");
    } else {
      setDeleteItemModalVisible(true);
    }
  };

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      height={240}
      containerRef={scrollViewRef}
      title="Found Item Details"
      HeaderLeft={() => (
        <IconButton
          icon="ios-close"
          color="white"
          size={42}
          onPress={onClose}
        />
      )}
      HeaderForegroundComponent={React.useCallback(() => (
        <ItemImagesHeader
          title="Found Item Details"
          item={item}
          editable
        />
      ), [ item ])}
    >
      <Form
        style={{ marginTop: 6 }}
        config={itemDetailsFormConfig}
        scrollViewRef={scrollViewRef}
        validateOnBlur
        setValue={setValue}
        {...formControls}
      >
        <Text style={{ marginBottom: 24 }}>
          To help us reunite the item with its owner, please add as much information as you can!
        </Text>
        <Controller
          render={({ field: { onChange, value: fieldValue } }) => (<Select
            onValueChange={onChange}
            label="Select item category"
            items={categories}
            selectedValue={fieldValue}
          />)}
          control={control}
          name="category"
          rules={itemDetailsFormConfig.category?.validation as {
            required: {
              value: boolean;
              message: string;
            };
          }}
        />
        <TextInput
          name="brand"
          label="Brand"
          defaultValue={item.brand}
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="model"
          label="Model"
          defaultValue={item.model}
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="color"
          label="Colour"
          defaultValue={isExistingItem(item) ? item.color : ""}
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="description"
          label="Description"
          defaultValue={item.description}
          placeholder="Extra details, e.g. cover, case, bumps, scratches, dings and dents"
          multiline
          numberOfLines={4}
          style={{ height: 96 }}
          blurOnSubmit={true}
          returnKeyType="done"
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Next"
        />
      </Form>

      <DeleteItemModal
        title="Oops, we still need a few more details to register this item!"
        isVisible={deleteItemModalVisible}
        onClose={() => setDeleteItemModalVisible(false)}
        itemId={item.id}
        onDelete={() => {
          setDeleteItemModalVisible(false);
          navigation.navigate("MainTabNavigator");
        }}
        cancelCta="Continue"
        deleteCta="Abandon"
        showSuccessSnack={false}
      />

    </CollapsibleHeaderScrollLayout>
  );
};

export default FoundItemDetailsScreen;
