import React, { useCallback } from "react";

import {
  Button,
  ButtonLink,
  IconButton
} from "src/component-lib/src/components/button";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { Modal } from "src/component-lib/src/components/modal";
import { BackgroundView } from "src/component-lib/src/components/view";
import {
  useAuthenticationContext,
  useLogout
} from "src/component-lib/src/hoc/authentication";
import { useSnackbarContext } from "src/component-lib/src/hoc/snackbar";
import { useApiRequest } from "src/component-lib/src/utils/api";
import styled from "src/component-lib/src/utils/styled-components";

import {
  SettingsStackScreenProps
} from "src/navigation/authorized/SettingsStack";
import OneSignal from "react-native-onesignal";
import { Platform } from "react-native";

type SettingsScreenProps = SettingsStackScreenProps<"SettingsScreen">;

const SettingsScreen: React.FC<SettingsScreenProps> = ({ navigation, route }) => {
  const { user } = route.params;

  const openWebView = (uri: string) => {
    navigation.navigate("WebViewModal", { uri });
  };

  const logout = useLogout();
  const snackbar = useSnackbarContext();

  const { authTokenState } = useAuthenticationContext();
  const [ deactivateResponse, deactivateRequest ] = useApiRequest("USERS:deleteUser");

  const deactivateAccount = React.useCallback(() => {
    if (authTokenState?.userUUID) {
      deactivateRequest({ "pathParams": { userUuid: authTokenState.userUUID } });
    }
  }, [ deactivateRequest, authTokenState?.userUUID ]);

  const [ showDeactivateModal, setShowDeactivateModal ] = React.useState(false);

  const logoutAndDisassociateOneSignal = async () => {
    try {
      console.log("TRY");
      if (Platform.OS !== "web") {
        const tagsObject = await new Promise<Record<string, unknown>>((resolve, _reject) => OneSignal
          .getTags(tags => resolve(tags as Record<string, unknown>)));

        Object.keys(tagsObject).forEach(tagName => OneSignal.deleteTag(tagName));
        OneSignal.logoutEmail(console.log);
        OneSignal.removeExternalUserId(console.log);
      }
    }
    catch (err) {
      console.log("CATCH");
      console.error(`Error when logging out OneSignal: ${JSON.stringify(err)}`);
    }
    finally {
      console.log("FINALLY");
      return logout();
    }
  };

  React.useEffect(() => {
    if (deactivateResponse.data) {
      snackbar.show({
        type: "success",
        duration: 4000,
        text: "Account deleted"
      });
      setShowDeactivateModal(false);
      logoutAndDisassociateOneSignal();
    } else if (deactivateResponse.errorMessage) {
      snackbar.show({
        type:"error",
        duration: 4000,
        text: deactivateResponse.errorMessage
      });
      setShowDeactivateModal(false);
    }
  },[ deactivateResponse, snackbar, logoutAndDisassociateOneSignal ]);

  return (
    <FullScreenLayout
      HeaderRight={() => (
        <IconButton
          color="white"
          icon="ios-close"
          onPress={() => navigation.goBack()}
          size={42}
        />
      )}
      BackgroundComponent={p => (
        <BackgroundView
          {...p}
          gradient="business"
          start={[ 0.25, 0.4 ]}
          end={[ 1.5, 0.75 ]}
        />
      )}
    >
      <ScreenTop>
        {authTokenState?.role === "COMPANY_ADMIN" && (
          <>
            <ButtonLink
              onPress={() => navigation.navigate("EditBusinessDetailsScreen", { user })}
              label="Business Details"
              color="white"
              bold
            />
            <ButtonLink
              onPress={() => navigation.navigate("AccountMembersScreen")}
              label="Account Members"
              color="white"
              bold
            />
            <ButtonLink
              onPress={() => navigation.navigate("InviteMemberScreen")}
              label="Invite Member"
              color="white"
              bold
            />
            <Divider />
          </>
        )}
        <ButtonLink
          onPress={() => navigation.navigate("ChangePasswordScreen")}
          label="Change password"
          color="white"
          bold
        />
        <Divider />
        <ButtonLink
          onPress={() => openWebView("https://found.cloud/frequently-asked-questions-faq")}
          label="Frequently asked questions"
          color="white"
          bold
        />
        <ButtonLink
          onPress={() => openWebView("https://found.cloud/business-legal")}
          label="Terms and conditions"
          color="white"
          bold
        />
        <ButtonLink
          onPress={() => openWebView("https://found.cloud/contact")}
          label="Contact"
          color="white"
          bold
        />
      </ScreenTop>

      <ScreenBottom>
        <LogoutWrapper>
          <Button
            type="secondary"
            onPress={logoutAndDisassociateOneSignal}
            label="Log out"
          />
        </LogoutWrapper>
        <ButtonLink
          onPress={() => setShowDeactivateModal(true)}
          label="Delete Account"
          color="white"
          bold
        />
      </ScreenBottom>
      <Modal
        title="Are you sure you wish to delete your account? This action is irreversible as it will permanently delete your details from our system."
        isVisible={showDeactivateModal}
        onClose={() => {
          // setShowDeactivateModal(false)
        }}
        buttons={{
          confirm: {
            type: "error",
            label: "DELETE ACCOUNT",
            onPress: deactivateAccount,
            style: { width: "100%" }
          },
          reject: {
            type: "tertiary",
            label: "Cancel",
            onPress: () => {
              setShowDeactivateModal(false)
            },
            style: { width: "100%" }
          }
        }}
      />
    </FullScreenLayout>
  );
};

const Divider = styled.View`
  border-bottom-width: 0.5px;
  margin: 24px 0;
  border-color: white;
  opacity: 0.2;
  align-self: stretch;
`;

const LogoutWrapper = styled.View`
  align-self: stretch;
  margin: 36px;
  margin-bottom: 12px;
`;

const ScreenTop = styled.View`
  align-self: flex-start;
  width: 100%;
`;

const ScreenBottom = styled.View`
  width: 100%;
  align-items: center;
  margin-top: auto;
`;

export default SettingsScreen;
