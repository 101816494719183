import React from "react";

import {
  Controller,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { View } from "react-native";

import {
  Button,
  IconButton
} from "src/component-lib/src/components/button";
import {
  Form,
  Select,
  TextInput
} from "src/component-lib/src/components/form";
import {
  CollapsibleHeaderScrollLayout
} from "src/component-lib/src/components/layout";
import { Text } from "src/component-lib/src/components/text";

import {
  UnauthorizedScreenProps
} from "src/navigation/unauthorized/UnauthorizedNavigator";

import {
  businessDetailsFormConfig,
  BusinessDetailsFormData,
  businessTypeOptions
} from "./businessDetailsForm";

type BusinessDetailsScreenProps = UnauthorizedScreenProps<"BusinessDetails">;

const BusinessDetailsScreen: React.FC<BusinessDetailsScreenProps> = ({ navigation, route }) => {
  const { userDetails } = route.params;

  /**
   * Submit the form
   */
  const submitForm: SubmitHandler<BusinessDetailsFormData> = React.useCallback(businessDetails => {
    navigation.navigate("BusinessAddress", {
      userDetails,
      businessDetails: {
        ...businessDetails,
        businessType: businessDetails.businessType.value
      }
    });
  }, [ navigation, userDetails ]);

  const scrollViewRef = React.useRef<any>(null);

  const { handleSubmit, control, ...formControls } = useForm<BusinessDetailsFormData>();

  return (
    <CollapsibleHeaderScrollLayout
      gradientType="business"
      title="Business Details"
      height={270}
      containerRef={scrollViewRef}
      HeaderLeft={() => (
        <IconButton
          color="white"
          icon="chevron-left"
          onPress={() => navigation.goBack()}
          size={35}
        />
      )}
      HeaderForegroundComponent={() => (
        <HeaderContent
          title="Business Details"
          description={`Welcome aboard, ${userDetails.name.split(" ")[ 0 ]}! Before we can register your business as a Found Partner, we'll need a few basic details.`}
        />
      )}
    >
      <Form
        config={businessDetailsFormConfig}
        validateOnBlur
        keyboardOffset={320}
        scrollViewRef={scrollViewRef}
        {...formControls}
      >
        <TextInput
          name="businessName"
          label="Business Name"
          textContentType="organizationName"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <TextInput
          name="website"
          label="Website"
          textContentType="URL"
          keyboardType="url"
          autoCapitalize="none"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        <Controller
          render={({ field: { onChange, value: fieldValue } }) => (<Select
            onValueChange={onChange}
            label="Select business type"
            items={businessTypeOptions}
            selectedValue={fieldValue}
          />)}
          control={control}
          name="businessType"
          rules={businessDetailsFormConfig.businessType?.validation as {
            required: {
              value: boolean;
              message: string;
            };
          }}
        />
        <Button
          type="tertiary"
          onPress={handleSubmit(submitForm)}
          label="Next"
          style={{ marginTop: 12, marginBottom: 24 }}
        />
      </Form>
    </CollapsibleHeaderScrollLayout>
  );
};

export default BusinessDetailsScreen;

const HeaderContent: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <View style={{ marginTop: 130, paddingHorizontal: 26 }}>
    <Text type="h1" color="white">
      {title}
    </Text>
    <Text style={{ marginTop: 12 }} color="white">
      {description}
    </Text>
  </View>
);
