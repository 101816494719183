import { FormConfig } from "src/component-lib/src/components/form";
import { ItemCategoryEnum } from "src/component-lib/src/utils/api";

export interface ItemDetailsFormData {
  category: {
    label: string;
    value: ItemCategoryEnum;
  };
  brand: string;
  model: string;
  color: string;
  description: string;
}

export const itemDetailsFormConfig: FormConfig<ItemDetailsFormData> = {
  category: {
    controlled: true,
    validation: {
      required: {
        value: true,
        message: "Category is required"
      }
    }
  },
  brand: null,
  model: null,
  color: null,
  description: null
};
