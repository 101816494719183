import * as React from "react";

import axios, { AxiosError } from "axios";
import * as AppleAuthentication from "expo-apple-authentication";
import Constants from "expo-constants";
import * as Facebook from "expo-facebook";
import * as Linking from "expo-linking";
import { Platform } from "react-native";

import { useSnackbarContext } from "../../snackbar";

import {
  AppTypeEnum,
  AuthenticationResponse,
  DeviceTypeEnum,
  SocialTypeEnum,
  SocialUserRequest
} from "../auth-api";
import { useAuthenticationContext } from "../../../hoc/authentication";
import { twitterAuth } from "../twitter";

const baseUrl = Constants?.manifest?.extra?.BASE_URL;
const facebookAppId = Constants?.manifest?.extra?.FACEBOOK_APP_ID;
const twitterConsumerKey = Constants?.manifest?.extra?.TWITTER_CONSUMER_KEY;
const twitterConsumerSecret = Constants?.manifest?.extra?.TWITTER_CONSUMER_SECRET;
const appleAuthNonce = Constants?.manifest?.extra?.APPLE_AUTH_NONCE;

const useSocialLogin = () => {
  const { setAuthTokenState } = useAuthenticationContext();
  const snackbar = useSnackbarContext();

  return  React.useCallback(async (type: SocialTypeEnum) => {
    try {
      let token;
      let secret;
      let socialAppleAuthData: SocialUserRequest["socialAppleAuthData"] = null;
      let facebookResult: Facebook.FacebookLoginResult;
      let appleIsAvailable: boolean;

      switch (type) {
      case SocialTypeEnum.FACEBOOK:
        if (!facebookAppId) {
          throw new Error("Facebook configuration required. Please set a valid app id!");
        }

        await Facebook.initializeAsync({ appId: facebookAppId, appName: "Found" });

        facebookResult = await Facebook.logInWithReadPermissionsAsync({
          permissions: [ "public_profile", "email" ]
        });

        if (facebookResult.type === "success") {
          token = facebookResult.token;
        }
        break;

      case SocialTypeEnum.TWITTER: {

        if (!twitterConsumerKey || !twitterConsumerSecret) {
          throw new Error("Twitter configuration required. Please set a valid key and secret!");
        }

        const redirectUrl = Linking.makeUrl();
        const { accessToken, accessTokenSecret } = await twitterAuth({ consumerKey: twitterConsumerKey, consumerSecret: twitterConsumerSecret }, redirectUrl, { accessType: "read", forSignIn: true });
        token = accessToken as string;
        secret = accessTokenSecret as string;
        break;
      }

      case SocialTypeEnum.APPLE:
        appleIsAvailable = await AppleAuthentication.isAvailableAsync();
        if (appleIsAvailable) {
          const appleResult = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL
            ],
            nonce: appleAuthNonce
          });

          if (appleResult) {
            token = appleResult.identityToken;
            socialAppleAuthData = {
              authCode: appleResult.authorizationCode,
              userId: appleResult.user,
              familyName: appleResult.fullName?.familyName || null,
              givenName: appleResult.fullName?.givenName || null
            };
          }

        }
        break;

      default:
        throw new Error("Social login type not supported");
      }

      if (!token) {
        throw new Error("Social login failed");
      }

      const data: SocialUserRequest = {
        appType: AppTypeEnum.PUBLIC,
        socialMediaToken: token,
        socialMediaSecret: secret,
        socialAppleAuthData,
        acceptMarketing: false,
        type,
        deviceToken: Constants.deviceId || "",
        organization: false,
        deviceType:
        Platform.OS === "ios"
          ? DeviceTypeEnum.IOS
          : Platform.OS === "android"
            ? DeviceTypeEnum.ANDROID
            : DeviceTypeEnum.DEFAULT
      };

      const { data: signinResponse } = await axios.request<AuthenticationResponse>({
        method: "POST",
        url: `${baseUrl}/authentication/tokens/social`,
        data
      });

      if (signinResponse) {
        setAuthTokenState(signinResponse);
      }

    } catch (error) {
      const err = error as AxiosError;
      const message = err?.isAxiosError ? err?.response?.data?.message : err?.message;
      snackbar.show({
        type: "error",
        text: message || "An unknown error occurred",
        duration: 4000
      });
    }
  }, [ setAuthTokenState, snackbar ]);

};

export default useSocialLogin;
