import React from "react";

import { ImageURISource, Text } from "react-native";
// import Swiper from "react-native-swiper";
import Swiper from "react-native-web-swiper";

import { IconButton } from "src/component-lib/src/components/button";
import { Image } from "src/component-lib/src/components/image";
import { FullScreenLayout } from "src/component-lib/src/components/layout";
import { BackgroundView } from "src/component-lib/src/components/view";
import {
  useAuthenticationContext
} from "src/component-lib/src/hoc/authentication";
import styled from "src/component-lib/src/utils/styled-components";

import { buildImageSource } from "src/utils/images";

import {
  AuthorizedScreenProps
} from "src/navigation/authorized/AuthorizedNavigator";
import useItemImages from "src/component-lib/src/utils/api/useItemImage";

const ItemImageViewScreen: React.FC<AuthorizedScreenProps<"ItemImageViewScreen">> = ({ navigation, route }) => {
  const { images, selectedImage } = route.params;
  const { authTokenState } = useAuthenticationContext();

  const [ imageSources, setImageSources ] = React.useState<ImageURISource[]>([]);

  // React.useEffect(() => {
  //   const sources = images.map(imageId => buildImageSource(authTokenState?.token.value, imageId));
  //   setImageSources(sources);
  // }, [ authTokenState, images ]);

  const imageSrcs = useItemImages(authTokenState, images);

  const initialIndex = images.findIndex(image => image === selectedImage);

  const onClose = () => navigation.goBack();

  return (
    <FullScreenLayout
      HeaderRight={() => (
        <IconButton
          color="white"
          icon="ios-close"
          onPress={onClose}
          size={42}
        />
      )}
      BackgroundComponent={p => <BackgroundView {...p} />}
      noPadding
    >
      <ImageViewWrapper>
        <Swiper
          controlsProps={{
            dotsTouchable: true,
            prevTitle: "",
            nextTitle: ""
          }}
        >
          {imageSrcs.map((source, i) => (
            <ItemImage
              key={i}
              style={{ marginBottom: 31 }}
              source={source}
              resizeMode="contain"
            />
          ))}
        </Swiper>
      </ImageViewWrapper>
    </FullScreenLayout>
  );
};

export default ItemImageViewScreen;

const ImageViewWrapper = styled.View`
  justify-content: center;
  align-items: stretch;
  flex: 1;
`;

const ItemImage = styled.Image`
  width: 100%;
  height: 100%;
`;

