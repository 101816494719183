import { FormConfig } from "src/component-lib/src/components/form";

export interface BusinessAddressFormData {
  address1: string;
  address2: string;
  city: string;
  postCode: string;
}

export const businessAddressFormConfig: FormConfig<BusinessAddressFormData> = {
  address1: {
    validation: {
      required: {
        value: true,
        message: "Address line 1 is required"
      }
    }
  },
  address2: null,
  city: {
    validation: {
      required: {
        value: true,
        message: "City is required"
      }
    }
  },
  postCode: {
    validation: {
      required: {
        value: true,
        message: "Postcode is required"
      },
      pattern: {
        value: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
        message: "Invalid postcode"
      }
    }
  }
};
