import React from "react";

import { StyleSheet } from "react-native";
import {
  Edge,
  SafeAreaView
} from "react-native-safe-area-context";

import { Text } from "../text";
import styled, { css } from "../../utils/styled-components";
import { Theme } from "../../utils/theme";
import {
  StatusBar
} from "react-native-scrollable-navigation-bar";

interface FullScreenLayoutProps {
  title?: string;
  backgroundColor?: keyof Theme["colors"];
  titleColor?: keyof Theme["colors"];
  BackgroundComponent?: React.ComponentType;
  HeaderForegroundComponent?: React.ComponentType;
  HeaderBackgroundComponent?: React.ComponentType;
  HeaderLeft?: React.ComponentType;
  HeaderRight?: React.ComponentType;
  scrollable?: boolean;
  insideTab?: boolean;
  noPadding?: boolean;
}

const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  children,
  BackgroundComponent,
  title,
  titleColor,
  backgroundColor,
  HeaderLeft,
  HeaderRight,
  HeaderBackgroundComponent,
  HeaderForegroundComponent,
  scrollable,
  insideTab = false,
  noPadding
}) => {
  const Background = BackgroundComponent
    ? BackgroundComponent
    : DefaultBackground;

  const HeaderBackground = HeaderBackgroundComponent
    ? HeaderBackgroundComponent
    : Header;

  const hasHeaderTop = !!HeaderLeft || !!HeaderRight;
  const hasHeaderBottom = !!title || !!HeaderForegroundComponent;
  const hasHeader = hasHeaderTop || hasHeaderBottom;

  // inline array with conditional elements types as string[] instead of Edge[]
  // have to use explicit function to keep tsc happy
  const getSafeAreaInsets = React.useCallback(() => {
    const edges: Edge[] = [ "left", "right" ];
    if (!hasHeader) {
      edges.push("top");
    }
    if (!insideTab) {
      edges.push("bottom");
    }

    return edges;
  }, [ hasHeader, insideTab ]);

  return (
    <LayoutWrapper backgroundColor={backgroundColor}>
      {!hasHeader && (
        // TODO: rewrite using a non-stupid dependency
        <StatusBar
          // barStyle="light-content"
          backgroundColor="transparent"
        />
      )}

      <Background style={styles.background}>
        <StyledSafeArea edges={getSafeAreaInsets()}>
          {hasHeader && (
            <HeaderBackground>
              <SafeAreaView edges={[ "top", "left", "right" ]}>
                {hasHeaderTop && (
                  <HeaderTop>
                    {!!HeaderLeft && (
                      <HeaderLeftWrapper>
                        <HeaderLeft />
                      </HeaderLeftWrapper>
                    )}
                    {!!HeaderRight && (
                      <HeaderRightWrapper>
                        <HeaderRight />
                      </HeaderRightWrapper>
                    )}
                  </HeaderTop>
                )}
                {hasHeaderBottom &&
                  (<HeaderBottom>
                    {HeaderForegroundComponent ? (
                      <HeaderForegroundComponent />
                    ) : (
                      <HeaderTitleView title={title} titleColor={titleColor} />
                    )}
                  </HeaderBottom>)
                }
              </SafeAreaView>
            </HeaderBackground>
          )}

          <LayoutOuter>
            {scrollable ? (
              <ScrollableLayoutInner keyboardShouldPersistTaps="handled">
                <LayoutInner noPadding={noPadding}>{children}</LayoutInner>
              </ScrollableLayoutInner>
            ) : (
              <LayoutInner noPadding={noPadding}>{children}</LayoutInner>
            )}
          </LayoutOuter>
        </StyledSafeArea>
      </Background>
    </LayoutWrapper>
  );
};

// dynamic component so cant be wrapped in styled
const styles = StyleSheet.create({
  background: {
    flex: 1
  }
});

const StyledSafeArea = styled(SafeAreaView)`
  height: 100%;
`;

const DefaultBackground = styled.View`
  flex: 1;
`;

const LayoutOuter = styled.View`
  flex: 1;
`;

const LayoutInner = styled.View<Pick<FullScreenLayoutProps, "noPadding">>`
  padding: ${({ noPadding }) => (noPadding ? 0 : 24)}px;
  flex: 1;
`;

const ScrollableLayoutInner = styled.ScrollView`
  flex: 1;
`;

const HeaderLeftWrapper = styled.View`
  align-self: flex-start;
  margin-right: auto;
`;

const HeaderRightWrapper = styled.View`
  align-self: flex-end;
  margin-left: auto;
`;

const LayoutWrapper = styled.View<
  Pick<FullScreenLayoutProps, "backgroundColor">
>`
  ${({ theme, backgroundColor }) => css`
    background: ${backgroundColor
    ? theme.colors[ backgroundColor ]
    : theme.colors.bg};
    flex: 1;
  `};
`;

const Header = styled.View`
  width: 100%;
`;

const HeaderTop = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
  width: 100%;
`;

const HeaderTitleWrapper = styled.View`
  width: 100%;
`;

const HeaderBottom = styled.View`
  width: 100%;
  padding: 24px;
`;

const HeaderTitleView: React.FC<Pick<
  FullScreenLayoutProps,
  "title" | "titleColor"
>> = ({ title, titleColor }) =>
  title ? (
    <HeaderTitleWrapper>
      <Text type="h1" color={titleColor || "white"}>
        {title}
      </Text>
    </HeaderTitleWrapper>
  ) : null;

export default FullScreenLayout;
