import {
  BasePaginatedQuery,
  BusinessUserInvite,
  BusinessUserInvitedResponse,
  InsuranceSummaryDTO,
  ItemCategoryDTO,
  PaginatedCollectionResponseUserDTO,
  PartnerDTO,
  PartnersMapRequest,
  PasswordChangeRequest,
  PasswordResetRequest,
  PickParams,
  RegularUserRequest,
  RoleUpdateRequest,
  SlotsRequestResponse,
  StringResponse,
  UserDTO,
  UserUpdateRequest
} from "../../api";
import { ApiRouteDefinition } from "../useApiRequest";

const UserRoutes = {
  /** Update user info: UPDATE USER */
  "USERS:updateUserInfo": {
    path: "/users/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    UserUpdateRequest,
    PickParams<"userUuid">
  >,

  /** Delete User */
  "USERS:deleteUser": {
    path: "/users/:userUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"userUuid">>,

  /** Get all users in user's business */
  "USERS:getUsersByBusiness": {
    path: "/users/:userUuid/business/user",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    PickParams<"userUuid">,
    BusinessUsersQuery
  >,

  /** Allows a user with the COMPANY_ADMIN role to invite users to join their business */
  "USERS:inviteUserToBusiness": {
    path: "/users/:userUuid/business/user",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    StringResponse,
    BusinessUserInvite,
    PickParams<"userUuid">
  >,

  /** Returns a summary of the status of the user's insurance */
  "USERS:getItemInsuranceSummery": {
    path: "/users/:userUuid/items/insurance/summary",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<InsuranceSummaryDTO, null, PickParams<"userUuid">>,

  /** Update roles for a user.*/
  "USERS:updateUserRole": {
    path: "/users/:userUuid/roles",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    RoleUpdateRequest,
    PickParams<"userUuid">
  >,

  /** Send verification email to user. This will happen automatically when they sign up but this endpoint can be used to resend the email if required. */
  "USERS:resendVerificationEmail": {
    path: "/users/:userUuid/verification",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"userUuid">>,

  /**  Check to see whether the email address belongs to a user who has been invited to join a business */
  "USERS:checkEmailInBusiness": {
    path: "/users/business/invitation",
    authenticate: false,
    method: "GET"
  } as ApiRouteDefinition<
    BusinessUserInvitedResponse,
    null,
    null,
    { email: string }
  >,

  /** Update user image: UPDATE USER */
  "USERS:updateUserImage": {
    path: "/users/image/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Get partners on map by coordinates */
  "USERS:getPartners": {
    path: "/users/partners",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<PartnerDTO[], PartnersMapRequest>,

  /** Request password reset email (unauthorised) */
  "USERS:passwordResetRequest": {
    path: "/users/password",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, PasswordResetRequest>,

  /** Complete password reset (unauthorised) */
  "USERS:resetPassword": {
    path: "/users/password/:tokenValue",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<
    StringResponse,
    ChangePasswordBody,
    PickParams<"tokenValue">
  >,

  /** Change password for logged in user (authorised) */
  "USERS:changePassword": {
    path: "/users/password/:userUuid/change",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    StringResponse,
    PasswordChangeRequest,
    PickParams<"userUuid">
  >,

  /** Fetch user preregistered items by categories */
  "USERS:getPreregisteredByCategory": {
    path: "/users/preregistered/:userUuid/categories",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Array<ItemCategoryDTO>, null, PickParams<"userUuid">>,

  "USERS:signupRegular": {
    path: "/users/regular",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, RegularUserRequest, null>,

  /** Get free user slots */
  "USERS:getFreeUserSlots": {
    path: "/users/slots/:userUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<SlotsRequestResponse, null, PickParams<"userUuid">>,

  /** Update user slots number */
  "USERS:updateUserSlots": {
    path: "/users/slots/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    SlotsRequestResponse,
    SlotsRequestResponse,
    PickParams<"userUuid">
  >,

  /** Get user info */
  "USERS:getUserInfo": {
    path: "/users/users/:userUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    UserDTO,
    null,
    PickParams<"userUuid">
  >,

  /* Return all Found staff members who have full admin privileges. */
  "USERS:getAdminUsers": {
    path: "/users/admin",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BasePaginatedQuery
  >,

  /* Get all users */
  "USERS:getAllBusinessUsers": {
    path: "/users/business",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BusinessUsersQuery
  >,

  /* Get all public users */
  "USERS:getAllPublicUsers": {
    path: "/users/business",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BusinessUsersQuery
  >
};

export default UserRoutes;

export interface BusinessUsersQuery extends BasePaginatedQuery {
  query?: string;
}

export interface ChangePasswordBody {
  confirmPassword: string;
  password: string;
}
